import * as React from 'react'

import DoomCentralRoles from '../../lib/DoomCentralRoles'
import AuthorizeRoute from '../api-authorization/AuthorizeRoute'
import EditPromotions from '../editpromotions/EditPromotions'

export default () => (
    <React.Fragment>
        <AuthorizeRoute path='/editpromotions' component={EditPromotions} componentroles={[DoomCentralRoles.PromotionAdministrator, DoomCentralRoles.GlobalAdministrator]} />
    </React.Fragment>
)
