import * as React from 'react'
import { useState } from 'react'
import { Col, FormGroup, Label } from 'reactstrap'

interface EventEditorStartDateProps {
    startDate: string
    setStartDate: React.Dispatch<React.SetStateAction<string>>
}

export default (props: EventEditorStartDateProps) => {
    return (
        <React.Fragment>
            <FormGroup row>
                <Label for='startDate' sm={2}>
                    Start Date
                </Label>
                <Col sm={10}>
                    <input type='datetime-local' value={props.startDate} onChange={(e) => props.setStartDate(e.target.value)} />
                </Col>
            </FormGroup>
        </React.Fragment>
    )
}
