import * as React from 'react'
import { useContext, useState } from 'react'
import { Button, FormGroup, FormText, Input, Label } from 'reactstrap'

import { IErrorResponse } from '../../../../ErrorResponse'
import { CreateForumGroupCommand, ForumGroupsClient } from '../../../../lib/DoomCentral'
import { ErrorContext } from '../../../../state'

interface CreateNewForumGroupProps {
    setNewGroup: React.Dispatch<React.SetStateAction<number>>
}

export default (props: CreateNewForumGroupProps) => {
    const error = useContext(ErrorContext)

    const [loading, setLoading] = useState<boolean>(false)
    const [name, setName] = useState<string>('')
    const [desc, setDesc] = useState<string>('')

    const submitForumGroup = async () => {
        setLoading(true)
        try {
            let client = new ForumGroupsClient()
            let command = new CreateForumGroupCommand()
            command.name = name
            command.description = desc

            const response = await client.create(command).then((response) => response)
            props.setNewGroup(response)

            setName('')
            setDesc('')
        } catch (e) {
            error.setError(e.response as IErrorResponse)
        }
        setLoading(false)
    }

    return (
        <React.Fragment>
            <FormGroup>
                <Label for='forumgroup'>Create New Forum Group</Label>
                <Input value={name} onChange={(e) => setName(e.target.value)} />
                <FormText>Forum Group Name</FormText>
                <Input value={desc} onChange={(e) => setDesc(e.target.value)} />
                <FormText>Forum Group Description</FormText>
                <div className='d-flex'>
                    <div className='ms-auto'>
                        <Button onClick={submitForumGroup} disabled={(!name && !desc) || loading}>
                            Create New Forum Group
                        </Button>
                    </div>
                </div>
            </FormGroup>
        </React.Fragment>
    )
}
