import { Col, Container, Row } from 'reactstrap'

export const Columns = ({ leftColumn = undefined, children, rightColumn, ...rest }) => {
    return (
        <Container fluid className='g-0 d-flex justify-content-center' {...rest}>
            <Row className='w-100'>
                {leftColumn && (
                    <Col xs={12} md={3}>
                        <aside>{leftColumn}</aside>
                    </Col>
                )}
                <Col xs={12} md={9} style={{ flex: 1 }}>
                    <main>{children}</main>
                </Col>
                {rightColumn && (
                    <Col xs={12} md={3}>
                        <aside>{rightColumn}</aside>
                    </Col>
                )}
            </Row>
        </Container>
    )
}
