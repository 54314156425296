import { PostFileDto } from '@/lib/DoomCentral'
import { formatBytes } from '@/utils/helpers'
import { useHistory } from 'react-router'
import { Button, Card, CardBody, CardImg, CardImgOverlay, CardText, CardTitle } from 'reactstrap'

import { getPreviewImageForFileNameString } from '../../utils/utils'

export type PostFileCardProps = {
    postId: number | string
    postFile: PostFileDto
    maxWidth?: number
}

export const PostFileCard = ({ postId, postFile, maxWidth = 300 }: PostFileCardProps) => {
    const history = useHistory()

    const { postFileName, postFileDescription, sizeBytes } = postFile

    return (
        <Card style={{ maxWidth }}>
            <CardImg
                alt={postFileDescription || `Image preview for ${postFileName}`}
                src={getPreviewImageForFileNameString(postFileName, +postId)}
                className='rounded-0'
                style={{
                    height: 200,
                    maxHeight: 200,
                    width: '100%',
                    objectFit: 'cover',
                    objectPosition: 'top',
                }}
                width={'100%'}
            />

            {/* <CardImgOverlay>
                <CardTitle tag='h5'>{postFile.postFileName}</CardTitle>
                <CardText>
                    {formatBytes(postFile.sizeBytes)}
                    {postFile.postFileDescription}
                </CardText>
            </CardImgOverlay> */}

            <div className='p-1 d-flex flex-column' style={{ fontSize: '0.8rem' }}>
                <strong>
                    <span>{postFileName}</span>
                </strong>
                <span>{formatBytes(sizeBytes)}</span>
                {postFileDescription && <span>{postFileDescription}</span>}
            </div>

            <a
                href={getPreviewImageForFileNameString(postFileName, +postId)}
                target='_blank'
                rel='noopener noreferrer'
                className='btn btn-outline-secondary stretched-link rounded-0'
            >
                Download
            </a>
        </Card>
    )
}
