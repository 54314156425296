// TODO: use react-query for this
import { IndividualPostItemDto, ThreadPostsClient } from '@/lib/DoomCentral'
import { useEffect, useState } from 'react'

export const useQuotedPost = ({ id }: { id: string | number }) => {
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState('')
    const [data, setData] = useState<IndividualPostItemDto>()

    useEffect(() => {
        ;(async () => {
            try {
                const client = new ThreadPostsClient()
                const { post } = await client.getThreadPostByPostId(+id, +id)
                setData(post)
                setIsLoading(true)
            } catch (e) {
                console.error(e)
                setError(e.message)
            } finally {
                setIsLoading(false)
            }
        })()
    }, [id])

    return { isLoading, error, post: data }
}
