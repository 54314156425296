import { useRoles } from '@/hooks/useRoles'
import DoomCentralEventRoles from '@/lib/DoomCentralEventRoles'
import DoomCentralRoles from '@/lib/DoomCentralRoles'
import ForumRoles from '@/lib/ForumRoles'
import NewsPostRoles from '@/lib/NewsPostRoles'
import { containsDuplicate } from '@/utils/helpers'
import { useAuth } from 'react-oidc-context'

export const useCapabilities = () => {
    const auth = useAuth()

    const { roles } = useRoles()

    const adminMenuRoles = [
        DoomCentralRoles.GlobalAdministrator,
        DoomCentralRoles.PromotionAdministrator,
        ForumRoles.GlobalForumAdministrator,
        DoomCentralEventRoles.GlobalEventAdministrator,
        NewsPostRoles.GlobalNewsAdministrator,
    ]

    const isGlobalAdmin = roles.includes(DoomCentralRoles.GlobalAdministrator)
    const isGlobalForumAdmin = roles.includes(ForumRoles.GlobalForumAdministrator)
    const isGlobalEventAdmin = roles.includes(DoomCentralEventRoles.GlobalEventAdministrator)
    const isGlobalNewsAdmin = roles.includes(NewsPostRoles.GlobalNewsAdministrator)
    const isAuthenticated = auth.isAuthenticated

    const canPromote =
        auth.user &&
        (auth.user?.profile.role as string[]) &&
        containsDuplicate([DoomCentralRoles.PromotionAdministrator, DoomCentralRoles.GlobalAdministrator], auth.user?.profile.role as string[])

    return {
        canPromote,
        isGlobalAdmin,
        isGlobalForumAdmin,
        isGlobalEventAdmin,
        isGlobalNewsAdmin,
        isAuthenticated
    }
}
