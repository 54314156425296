import * as React from 'react'
import { useContext, useState } from 'react'
import { Button, Col, FormGroup, Input, Label } from 'reactstrap'

import { FileParameter, ReactionsClient } from '../../../lib/DoomCentral'
import { ErrorContext } from '../../../state'

interface CreateNewReactionProps {
    setModReaction: React.Dispatch<React.SetStateAction<number>>
}

export default (props: CreateNewReactionProps) => {
    const error = useContext(ErrorContext)

    const [reactName, setReactName] = useState<string>('')
    const [reactFile, setReactFile] = useState<FileParameter>()
    const [loading, setLoading] = useState<boolean>(false)

    const submitEmote = async () => {
        setLoading(true)
        try {
            let client = new ReactionsClient()
            const response = (await client.create(reactName, reactFile).then((response) => response)) as number
            props.setModReaction(response)
            setReactName('')
            setReactFile(null)
        } catch (e) {
            error.setError(JSON.parse(e.response))
        }
        setLoading(false)
    }

    const handleSetFile = async (evt: React.ChangeEvent<HTMLInputElement>) => {
        const upload: FileParameter = {
            data: evt.target.files[0],
            fileName: evt.target.files[0].name,
        }
        setReactFile(upload)
    }

    return (
        <React.Fragment>
            <h2>Create Reaction</h2>
            <FormGroup row>
                <Label for='reactSelect' sm={2}>
                    Reaction Name
                </Label>
                <Col sm={10}>
                    <Input name='reactSelect' id='reactSelect' value={reactName} onChange={(e) => setReactName(e.target.value)} />
                </Col>
            </FormGroup>
            <FormGroup row>
                <Label for='ReactionFile' sm={2}>
                    Reaction File
                </Label>
                <Col sm={10}>
                    <input type='file' id='ReactionFile' name='ReactionFile' accept='image/png, image/jpeg' onChange={handleSetFile} />
                </Col>
            </FormGroup>
            <FormGroup row>
                <Col>
                    <Button disabled={!reactName || !reactFile || loading} onClick={submitEmote}>
                        Submit Reaction
                    </Button>
                </Col>
            </FormGroup>
        </React.Fragment>
    )
}
