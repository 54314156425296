import * as React from 'react'
import { useContext, useEffect, useState } from 'react'
import { Button, FormGroup, FormText, Input, Label } from 'reactstrap'

import { IErrorResponse } from '../../../../ErrorResponse'
import { ForumGroupsClient, IForumGroupsDto, UpdateForumGroupCommand } from '../../../../lib/DoomCentral'
import { ErrorContext } from '../../../../state'

interface UpdateForumGroupProps {
    setUpdatedGroup: React.Dispatch<React.SetStateAction<number>>
    selectedGroup: IForumGroupsDto
    loading: boolean
}

export default (props: UpdateForumGroupProps) => {
    const error = useContext(ErrorContext)

    const [loading, setLoading] = useState<boolean>(false)
    const [name, setName] = useState<string>('')
    const [desc, setDesc] = useState<string>('')

    useEffect(() => {
        if (props.selectedGroup) {
            setName(props.selectedGroup.name)
            setDesc(props.selectedGroup.description)
        }
    }, [props.selectedGroup])

    const submitForumGroup = async () => {
        setLoading(true)
        try {
            let client = new ForumGroupsClient()
            let command = new UpdateForumGroupCommand()
            command.id = props.selectedGroup.id
            command.name = name
            command.description = desc

            const response = await client.update(props.selectedGroup.id, command).then((response) => response)
            props.setUpdatedGroup(response)
        } catch (e) {
            error.setError(e.response as IErrorResponse)
        }
        setLoading(false)
    }

    return (
        <React.Fragment>
            <FormGroup>
                <Label for='forumgroup'>Update Forum Group</Label>
                <Input value={name} onChange={(e) => setName(e.target.value)} />
                <FormText>Forum Group Name</FormText>
                <Input value={desc} onChange={(e) => setDesc(e.target.value)} />
                <FormText>Forum Group Description</FormText>
                <div className='d-flex'>
                    <div className='ms-auto'>
                        <Button onClick={submitForumGroup} disabled={!props.selectedGroup || (!name && !desc) || loading}>
                            Update Forum Group
                        </Button>
                    </div>
                </div>
            </FormGroup>
        </React.Fragment>
    )
}
