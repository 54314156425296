import { navigateToReturnUrl } from '@/utils/helpers'
import { User, WebStorageStateStore } from 'oidc-client-ts'
import { AuthProviderProps } from 'react-oidc-context'

import { getReturnUrl } from './components/api-authorization/AuthHelpers'

export const ApplicationName = 'DoomCentral'

export const QueryParameterNames = {
    ReturnUrl: 'returnUrl',
    Message: 'message',
}

export const LogoutActions = {
    LogoutCallback: 'logout-callback',
    Logout: 'logout',
    LoggedOut: 'logged-out',
}

export const LoginActions = {
    Login: 'login',
    LoginCallback: 'login-callback',
    LoginFailed: 'login-failed',
    Profile: 'profile',
    Register: 'register',
}

const prefix = '/authentication'

export const ApplicationPaths = {
    DefaultLoginRedirectPath: '/',
    ApiAuthorizationClientConfigurationUrl: `_configuration/${ApplicationName}`,
    DiscoveryEndpoint: `/.well-known/openid-configuration`,
    ApiAuthorizationPrefix: prefix,
    Login: `${prefix}/${LoginActions.Login}`,
    LoginCallback: `${prefix}/${LoginActions.LoginCallback}`,
    LogOutCallback: `${prefix}/${LogoutActions.LogoutCallback}`,
    IdentityRegisterPath: 'Identity/Account/Register',
    IdentityManagePath: 'Identity/Account/Manage',
    AccessDenied: 'Identity/Account/AccessDenied',
}

export const authority_url = window.location.origin
export const client_id = 'DoomCentral'

export const oidcConfig: AuthProviderProps = {
    authority: authority_url,
    client_id: client_id,
    redirect_uri: authority_url + ApplicationPaths.LoginCallback,
    post_logout_redirect_uri: authority_url + ApplicationPaths.LogOutCallback,
    onSigninCallback: (user: User) => {
        navigateToReturnUrl(getReturnUrl(user.state as string))
    },
    silent_redirect_uri: authority_url + ApplicationPaths.LoginCallback,
    loadUserInfo: true,
    monitorAnonymousSession: true,
    filterProtocolClaims: true,
    revokeTokensOnSignout: true,
    response_type: 'code',
    automaticSilentRenew: true,
    validateSubOnSilentRenew: true,
    scope: 'DoomCentral.WebUIAPI openid profile',
    userStore: new WebStorageStateStore({ store: localStorage }),
}
