import * as React from 'react'
import { useContext, useEffect, useState } from 'react'
import { Button, FormGroup, FormText, Input, InputGroup, InputGroupText, Label, ListGroup, ListGroupItem } from 'reactstrap'

import { ForumPermission } from './ForumPermission'
import { IForumTreeObject } from './ForumPermissionTreeObject'

interface GrantPanelProps {
    loading: boolean
    selectedTreeItem: IForumTreeObject
    forumTree: IForumTreeObject[]
    setForumTree: React.Dispatch<React.SetStateAction<IForumTreeObject[]>>
    setSelectedTreeItem: React.Dispatch<React.SetStateAction<IForumTreeObject>>
}

export default (props: GrantPanelProps) => {
    const [currentPermission, setCurrentPermission] = useState<ForumPermission>(ForumPermission.Inherit)

    const changeForumPermission = (permission: ForumPermission): IForumTreeObject => {
        setCurrentPermission(permission)
        let selectedItem = props.selectedTreeItem
        selectedItem.permission = permission

        return selectedItem
    }

    useEffect(() => {
        if (props.selectedTreeItem !== null) {
            setCurrentPermission(props.selectedTreeItem.permission)

            let tree = props.forumTree
            props.forumTree.map((s, idx) => {
                if (props.selectedTreeItem.id === s.id) {
                    tree[idx].permission = props.selectedTreeItem.permission
                }

                s.children.map((t, _idx) => {
                    if (props.selectedTreeItem.id === t.id) {
                        tree[idx].children[_idx].permission = props.selectedTreeItem.permission
                    }
                })
            })

            props.setForumTree(tree)
        }
    }, [props.selectedTreeItem])

    return (
        <React.Fragment>
            {!props.selectedTreeItem && <div>No forum or forum group selected...</div>}
            {props.selectedTreeItem && (
                <React.Fragment>
                    <h2>{props.selectedTreeItem.name}</h2>
                    <FormGroup>
                        <Label for='permissionSelect'>Permission</Label>
                        <Input
                            id='permissionSelect'
                            name='permissionSelect'
                            type='select'
                            value={ForumPermission[currentPermission]}
                            onChange={(e) => props.setSelectedTreeItem(changeForumPermission(ForumPermission[e.target.value]))}
                        >
                            {Object.values(ForumPermission)
                                .filter((value) => typeof value === 'string')
                                .map((key) => (
                                    <option key={key} value={key}>
                                        {key}
                                    </option>
                                ))}
                        </Input>
                    </FormGroup>
                </React.Fragment>
            )}
        </React.Fragment>
    )
}
