import * as React from 'react'
import { NavLink } from 'react-router-dom'
import { Nav } from 'reactstrap'

export default () => {
    return (
        <React.Fragment>
            <Nav className='admin-nav' vertical>
                <NavLink to={'/admin/manageusers'} className='nav-link'>
                    User Management
                </NavLink>
                <NavLink to={'/admin/manageemotes'} className='nav-link'>
                    Emote Management
                </NavLink>
                <NavLink to={'/admin/managebadges'} className='nav-link'>
                    Badge Management
                </NavLink>
                <NavLink to={'/admin/managereactions'} className='nav-link'>
                    Reaction Management
                </NavLink>
                <NavLink to={'/admin/manageforums'} className='nav-link'>
                    Forum Management
                </NavLink>
                <NavLink to={'/admin/manageforumpermissiongroups'} className='nav-link'>
                    Forum Permission Group Management
                </NavLink>
                <NavLink to={'/admin/manageevents'} className='nav-link'>
                    Event Management
                </NavLink>
                <NavLink to={'/admin/managenews'} className='nav-link'>
                    News Management
                </NavLink>
                <NavLink to={'/admin/managestreamers'} className='nav-link'>
                    Streamer Management
                </NavLink>
            </Nav>
        </React.Fragment>
    )
}
