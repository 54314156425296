import {
    Activity,
    EyeIcon,
    EyeOffIcon,
    FlameIcon,
    LockIcon,
    LockOpenIcon,
    MessageSquareDotIcon,
    PinIcon,
    PinOffIcon,
    Radio,
    MessageSquarePlusIcon,
    ShieldBanIcon,
    ShieldCheckIcon,
    ShieldIcon,
    ShieldMinusIcon,
    ShieldPlusIcon,
    TrashIcon,
} from 'lucide-react'

export const ApprovedIcon = (props) => <ShieldCheckIcon {...props} />
export const UnapprovedIcon = (props) => <ShieldMinusIcon {...props} />
export const ModeratedIcon = (props) => <ShieldPlusIcon {...props} />
export const NewMessageIcon = (props) => <MessageSquarePlusIcon {...props} />
export const UnmoderatedIcon = (props) => <ShieldIcon {...props} />
export const BanIcon = (props) => <ShieldBanIcon {...props} />
export const HotIcon = (props) => <FlameIcon {...props} />
export const HiddenIcon = (props) => <EyeOffIcon {...props} />
export const ShowIcon = (props) => <EyeIcon {...props} />
export const StickiedIcon = (props) => <PinIcon {...props} />
export const UnstickiedIcon = (props) => <PinOffIcon {...props} />
export const ActivityIcon = (props) => <Activity {...props} />
export const LiveIcon = (props) => <Radio {...props} />
export const UnreadIcon = (props) => <MessageSquareDotIcon {...props} />
export const LockedIcon = (props) => <LockIcon {...props} />
export const UnlockedIcon = (props) => <LockOpenIcon {...props} />
export const DeleteIcon = (props) => <TrashIcon {...props} />
