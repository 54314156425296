import { SiInstagram, SiTwitch, SiTwitter, SiYoutube } from '@icons-pack/react-simple-icons'
import { useHistory } from 'react-router'
import { Button, Container } from 'reactstrap'

import wdlLogoSm from './wdllogo-small.png'

export const Footer = () => {
    const history = useHistory()

    return (
        <>
            <Container fluid className='d-flex flex-column py-5'>
                <h5>Socials</h5>
                <div className='d-flex mb-3'>
                    <Button size='sm' className='me-1' onClick={() => history.push('')}>
                        <SiTwitter /> Twitter
                    </Button>
                    <Button size='sm' className='me-1' onClick={() => history.push('')}>
                        <SiInstagram /> Instagram
                    </Button>
                    <Button size='sm' className='me-1' onClick={() => history.push('')}>
                        <SiYoutube /> YouTube
                    </Button>
                    <Button size='sm' className='me-1' onClick={() => history.push('')}>
                        <SiTwitch /> Twitch
                    </Button>
                </div>
                <h5>Affiliates</h5>
                <div className='d-flex mb-3'>
                    <Button size='sm' className='me-1' onClick={() => history.push('')}>
                        <img src={wdlLogoSm} alt='WDL Logo' className='img-fluid pe-2' style={{ maxHeight: 24 }} />
                        WDL
                    </Button>
                </div>
                <div className='d-flex flex-column align-items-center'>
                    <p className='mb-0'>Copyright {new Date().getFullYear()} Doom Central</p>
                    <p className='mb-0'>All rights reserved</p>
                    <p className='mt-1'>Made possible with love from the Doom Central community ❤️</p>
                </div>
            </Container>
        </>
    )
}
