import { ThreadsClient } from "@/lib/DoomCentral"

export type getUserUnreadPosts = {
    threadId: number
}

export const getUserUnreadPosts = async ({ threadId }: getUserUnreadPosts) => {
    const c = new ThreadsClient()
    const res = await c.getPostsByThreadId(threadId, threadId, 1, 500)
    return res.postList.filter(post => post.unread)
}