import ErrorMessage from '@/components/ErrorMessage'
import React, { ReactNode } from 'react'
import { Toaster } from 'sonner'

interface LayoutProps {
    navbar: ReactNode
    children: ReactNode
    footer: ReactNode
}

const MAX_WIDTH = '1600px'
const MIN_HEIGHT = '75vh'

export const Layout: React.FC<LayoutProps> = ({ navbar, children, footer }) => {
    return (
        <>
            <ErrorMessage />

            <div className='d-flex justify-content-center mb-3'>
                <header className='w-100' style={{ maxWidth: MAX_WIDTH }}>
                    {navbar}
                </header>
            </div>

            <div className='d-flex justify-content-center'>
                <div className='w-100' style={{ maxWidth: MAX_WIDTH, minHeight: MIN_HEIGHT }}>
                    {children}
                </div>
            </div>

            <div className='d-flex w-100 justify-content-center border-top'>
                <footer style={{ maxWidth: MAX_WIDTH }}>{footer}</footer>
            </div>

            <Toaster richColors />
        </>
    )
}
