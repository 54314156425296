import RelativeTime from '@yaireo/relative-time'
import * as React from 'react'
import { useContext, useEffect, useState } from 'react'
import { Button, CloseButton, Collapse, NavLink, UncontrolledTooltip } from 'reactstrap'

import { IErrorResponse } from '../../ErrorResponse'
import { IIndividualPostVm, ThreadPostsClient } from '../../lib/DoomCentral'
import { ErrorContext } from '../../state'
import MarkdownPost from '../markdown-post/MarkdownPost'

interface QuoteBoxProps {
    quotedPostId: number
    setQuotedPostId: React.Dispatch<React.SetStateAction<number>>
    editMode: boolean
}

export default (props: QuoteBoxProps) => {
    const error = useContext(ErrorContext)

    const [quotedPost, setQuotedPost] = useState<IIndividualPostVm>(null)
    const [loading, setLoading] = useState<boolean>(false)
    const [open, setOpen] = useState<boolean>(false)
    const relativeTime = new RelativeTime()

    const toggle = async () => {
        let op: boolean = !open

        setOpen(op)

        if (op === true) {
            loadQuotedPost()
        }
    }

    const loadQuotedPost = async () => {
        if (props.quotedPostId > 0 && (!quotedPost || quotedPost?.post?.id !== props.quotedPostId)) {
            setLoading(true)
            try {
                let client = new ThreadPostsClient()

                const response = await client.getThreadPostByPostId(props.quotedPostId, props.quotedPostId).then((response) => response.toJSON() as Promise<IIndividualPostVm>)

                setQuotedPost(response)
            } catch (e) {
                error.setError(e.response as IErrorResponse)
            }
            setLoading(false)
        }
    }

    useEffect(() => {
        if (open === true) {
            toggle()
        }
    }, [props.quotedPostId])

    return (
        <React.Fragment>
            {props.quotedPostId > 0 && (
                <div>
                    <div className='quote-box' onClick={toggle}>
                        <span className='quoted-post-id'>This post is in reply to post id: #{props.quotedPostId}</span>
                        <div className='quote-post-icons'>
                            <i className='bi bi-caret-down-square'></i>
                            {props.editMode && <CloseButton variant='white' onClick={(e) => props.setQuotedPostId(0)} className='ms-2' />}
                        </div>
                    </div>
                    <Collapse isOpen={open}>
                        <div className='thread-container m-2'>
                            {!loading && quotedPost && (
                                <div className='post'>
                                    <div className='post-header'>
                                        <div className='post-author'>
                                            {quotedPost.post.postAuthorUserName && (
                                                <NavLink to={`/user/${quotedPost.post.postAuthorUserName}`}>{quotedPost.post.postAuthorDisplayName}</NavLink>
                                            )}
                                            {!quotedPost.post.postAuthorUserName && quotedPost.post.postAuthorDisplayName}
                                        </div>
                                        {quotedPost.post.postAuthorBadgeUrl.length > 0 && (
                                            <div className='post-author-badge'>
                                                <span id='userBadge'>
                                                    <img src={quotedPost.post.postAuthorBadgeUrl} />
                                                </span>
                                                <UncontrolledTooltip flip target='userBadge'>
                                                    {quotedPost.post.postAuthorBadgeName}
                                                </UncontrolledTooltip>
                                            </div>
                                        )}
                                        <div className='post-num'>#{quotedPost.post.postNumber}</div>
                                    </div>
                                    <div className='post-body'>
                                        <MarkdownPost content={quotedPost.post.postContent} />
                                    </div>
                                    <div className='post-footer'>
                                        <div className='post-date'>
                                            <span id={'dateCreated' + quotedPost.post.id}>{relativeTime.from(new Date(quotedPost.post.created))}</span>
                                            <UncontrolledTooltip placement='bottom' target={'dateCreated' + quotedPost.post.id}>
                                                {new Intl.DateTimeFormat('default', { dateStyle: 'full', timeStyle: 'long' }).format(new Date(quotedPost.post.created))}
                                            </UncontrolledTooltip>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </Collapse>
                </div>
            )}
        </React.Fragment>
    )
}
