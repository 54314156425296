import { getThreadMeta } from '@/api/getThreadMeta'
import { useQuery } from '@tanstack/react-query'

export type useThreadMetaProps = {
    id: number
}

export const useThreadMeta = ({ id }) => {
    return useQuery({
        queryKey: ['thread-meta', id],
        queryFn: async () => await getThreadMeta({ id: +id }),
    })
}
