import * as React from 'react'
import { Button } from 'reactstrap'

interface DataSizePresetButtonsProps {
    tags: any[]
    setTags: React.Dispatch<React.SetStateAction<any[]>>
}

export default (props: DataSizePresetButtonsProps) => {
    return (
        <React.Fragment>
            <Button onClick={(e) => e} disabled={true}>
                Image Formats
            </Button>{' '}
            <Button onClick={(e) => e} disabled={true}>
                WAD/PK3
            </Button>{' '}
            <Button onClick={(e) => e} disabled={true}>
                ZIP Package Formats
            </Button>{' '}
        </React.Fragment>
    )
}
