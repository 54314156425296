import * as React from 'react'
import { useContext, useState } from 'react'
import { Button, FormGroup, FormText, Input, Label } from 'reactstrap'

import { IErrorResponse } from '../../../../ErrorResponse'
import { CreateForumCommand, ForumsClient, IForumGroupsDto } from '../../../../lib/DoomCentral'
import { ErrorContext } from '../../../../state'
import PostFileSettings from '../../../postfilesettings/PostFileSettings'

interface CreateNewForumProps {
    setNewForum: React.Dispatch<React.SetStateAction<number>>
    forumGroup: IForumGroupsDto
    loading: boolean
}

export default (props: CreateNewForumProps) => {
    const error = useContext(ErrorContext)

    const [loading, setLoading] = useState<boolean>(false)
    const [name, setName] = useState<string>('')
    const [desc, setDesc] = useState<string>('')
    const [hidden, setHidden] = useState<boolean>(false)
    const [locked, setLocked] = useState<boolean>(false)
    const [moderated, setModerated] = useState<boolean>(false)

    const [tags, setTags] = useState<any[]>([])
    const [maxFilesPerPost, setMaxFilesPerPost] = useState<number>(5)
    const [maxFileSizePerPost, setMaxFileSizePerPost] = useState<number>(2097152)

    const submitForum = async () => {
        setLoading(true)
        try {
            let client = new ForumsClient()
            let command = new CreateForumCommand()
            command.name = name
            command.description = desc
            command.forumGroupId = props.forumGroup.id
            command.hidden = hidden
            command.locked = locked
            command.moderated = moderated
            command.maxFilesPerPost = maxFilesPerPost
            command.maxFileSize = maxFileSizePerPost

            const response = await client.create(command).then((response) => response)

            props.setNewForum(response)

            setName('')
            setDesc('')
            setHidden(false)
            setLocked(false)
            setModerated(false)
        } catch (e) {
            console.log(e)
            error.setError(e.response as IErrorResponse)
        }
        setLoading(false)
    }

    return (
        <React.Fragment>
            <FormGroup>
                <Label for='forum'>Create New Forum</Label>
                <Input value={name} onChange={(e) => setName(e.target.value)} />
                <FormText>Forum Name</FormText>
                <Input value={desc} onChange={(e) => setDesc(e.target.value)} />
                <FormText>Forum Description</FormText>
                <FormGroup row>
                    <Label check>
                        <Input type='checkbox' checked={hidden} onChange={(e) => setHidden(e.target.checked)} /> Hidden
                    </Label>
                </FormGroup>
                <FormGroup row>
                    <Label check>
                        <Input type='checkbox' checked={locked} onChange={(e) => setLocked(e.target.checked)} /> Locked
                    </Label>
                </FormGroup>
                <PostFileSettings
                    setTags={setTags}
                    tags={tags}
                    setMaxFilesPerPost={setMaxFilesPerPost}
                    maxFilesPerPost={maxFilesPerPost}
                    setMaxFileSize={setMaxFileSizePerPost}
                    maxFileSize={maxFileSizePerPost}
                    loading={loading}
                />
                <div className='d-flex'>
                    <div className='ms-auto'>
                        <Button onClick={submitForum} disabled={!name || !desc || loading || props.loading || !props.forumGroup}>
                            Create New Forum
                        </Button>
                    </div>
                </div>
            </FormGroup>
        </React.Fragment>
    )
}
