import { navigateToReturnUrl } from '@/utils/helpers'
import * as React from 'react'
import { useContext } from 'react'
import { Button } from 'reactstrap'

import { IErrorResponse } from '../../ErrorResponse'
import { CreateDoomCentralEventCommand, DoomCentralEventsClient, EventRegion } from '../../lib/DoomCentral'
import { ErrorContext } from '../../state'

interface SubmitNewEventButtonProps {
    eventName: string
    post: string
    region: EventRegion
    startDate: string
    endDate: string
    streamerId?: number
    publish: boolean
    loading: boolean
    setLoading: React.Dispatch<React.SetStateAction<boolean>>
}

export default (props: SubmitNewEventButtonProps) => {
    const error = useContext(ErrorContext)

    const submitNewEvent = async () => {
        props.setLoading(true)
        try {
            let client = new DoomCentralEventsClient()
            const command = new CreateDoomCentralEventCommand()
            command.eventRegion = props.region
            command.eventName = props.eventName
            command.eventPost = props.post
            command.eventStartDateTime = new Date(new Date(props.startDate).toISOString())
            command.eventEndDateTime = new Date(props.endDate)
            if (props.streamerId <= 0) {
                command.eventStreamerId = null
            } else {
                command.eventStreamerId = props.streamerId
            }
            command.publish = props.publish
            const response = await client.create(command).then((response) => response as number)
            await navigateToReturnUrl(`/event/${response}`)
        } catch (e) {
            error.setError(e.response as IErrorResponse)
        }
        props.setLoading(false)
    }

    return (
        <React.Fragment>
            <div className='d-flex'>
                <div className='ms-auto'>
                    <Button color='primary' disabled={props.loading || !props.eventName || !props.post || !props.startDate || !props.endDate} onClick={(e) => submitNewEvent()}>
                        Submit Event
                    </Button>
                </div>
            </div>
        </React.Fragment>
    )
}
