import * as React from 'react'

import HelmetPages from '../helmet/HelmetPages'
import AdminPanel from './AdminPanel'

export default () => {
    return (
        <React.Fragment>
            <HelmetPages title={'Doom Central - Admin Dashboard'} />
            <AdminPanel>
                <p>Hello and welcome to the admin panel!</p>
                <p>With great power...</p>
            </AdminPanel>
        </React.Fragment>
    )
}
