import { navigateToReturnUrl } from '@/utils/helpers'
import * as React from 'react'
import { useContext } from 'react'
import { Button } from 'reactstrap'

import { IErrorResponse } from '../../ErrorResponse'
import { DoomCentralEventsClient, EventRegion, UpdateDoomCentralEventCommand } from '../../lib/DoomCentral'
import { ErrorContext } from '../../state'

interface SubmitEditEventButtonProps {
    eventId: number
    eventTitle: string
    post: string
    publish: boolean
    region: EventRegion
    streamerId?: number
    start: string
    end: string
    loading: boolean
    setLoading: React.Dispatch<React.SetStateAction<boolean>>
}

export default (props: SubmitEditEventButtonProps) => {
    const error = useContext(ErrorContext)

    const submitEditEvent = async () => {
        props.setLoading(true)
        try {
            let client = new DoomCentralEventsClient()
            const command = new UpdateDoomCentralEventCommand()
            command.eventId = props.eventId
            command.eventName = props.eventTitle
            command.eventPost = props.post
            command.eventRegion = props.region
            command.eventStartDateTime = new Date(new Date(props.start).toISOString())
            command.eventEndDateTime = new Date(new Date(props.end).toISOString())
            command.eventStreamerId = props.streamerId
            command.published = props.publish
            const response = await client.update(props.eventId, command).then((response) => response as number)
            await navigateToReturnUrl(`/event/${response}`)
        } catch (e) {
            error.setError(e.response as IErrorResponse)
        }
        props.setLoading(false)
    }

    return (
        <React.Fragment>
            <div className='d-flex'>
                <div className='ms-auto'>
                    <Button disabled={props.eventId <= 0 || !props.eventTitle || !props.post || !props.region || !props.start || !props.end} onClick={(e) => submitEditEvent()}>
                        Submit Event
                    </Button>
                </div>
            </div>
        </React.Fragment>
    )
}
