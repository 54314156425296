import { getThreads } from '@/api/getThreads'
import { keepPreviousData, useQuery } from '@tanstack/react-query'

export type useThreadsProps = {
    id: number | string
}

export const useThreads = ({ id }: useThreadsProps) => {
    return useQuery({
        queryKey: ['threads'],
        queryFn: async () => await getThreads({ id: +id })
    })
}
