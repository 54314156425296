import * as React from 'react'

interface NewsOptionsProps {
    pageSize: number
    setPageSize: React.Dispatch<React.SetStateAction<number>>
}

export default (props: NewsOptionsProps) => {
    return (
        <React.Fragment>
            <label>
                Page Size:
                <select value={props.pageSize} onChange={(e) => props.setPageSize(parseInt(e.target.value, 10))}>
                    <option value={50}>50</option>
                    <option value={75}>75</option>
                    <option value={100}>100</option>
                    <option value={250}>250</option>
                </select>
            </label>
        </React.Fragment>
    )
}
