import * as React from 'react'
import { useContext, useEffect, useState } from 'react'

import { EmotesClient, IEmoteListVm, IEmotesDto } from '../../../lib/DoomCentral'
import { ErrorContext } from '../../../state'
import HelmetPages from '../../helmet/HelmetPages'
import AdminPanel from '../AdminPanel'
import CreateNewEmote from './CreateNewEmote'
import DeleteEmote from './DeleteEmote'

export default () => {
    const [modEmote, setModEmote] = useState<number>(0)

    return (
        <React.Fragment>
            <HelmetPages title={'Doom Central - Manage Emotes'} />
            <AdminPanel>
                <h1>Manage Emotes</h1>
                <CreateNewEmote setModEmote={setModEmote} />
                <hr />
                <DeleteEmote modEmote={modEmote} setModEmote={setModEmote} />
            </AdminPanel>
        </React.Fragment>
    )
}
