import * as React from 'react'
import { useContext, useEffect, useState } from 'react'
import { Col, Row } from 'reactstrap'

import { IErrorResponse } from '../../ErrorResponse'
import useQueryParam from '../../hooks/useQueryParam'
import { IRawNewsPostVm, ISidebarVm, NewsClient, SidebarClient } from '../../lib/DoomCentral'
import { ErrorContext } from '../../state'
import HelmetPages from '../helmet/HelmetPages'
import NewsEditorBoxContainer from '../newseditor/NewsEditorBoxContainer'
import NewsEditorPublish from '../newseditor/NewsEditorPublish'
import NewsEditorTitle from '../newseditor/NewsEditorTitle'
import Sidebars from '../sidebars/Sidebars'
import SubmitEditNewsButton from './SubmitEditNewsButton'

export default () => {
    const error = useContext(ErrorContext)

    const [sidebarLoading, setSidebarLoading] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(false)

    const [title, setTitle] = useState<string>('')
    const [postData, setPostData] = useState<string>('')
    const [sidebarData, setSidebarData] = useState<ISidebarVm>()
    const [publish, setPublish] = useState<boolean>(false)

    const [newsId, setNewsId] = useQueryParam('newsId', '')

    // Initial load for the sidebars.
    useEffect(() => {
        const fetchData = async () => {
            setSidebarLoading(true)
            try {
                let client = new SidebarClient()
                const response = await client.getSidebars().then((response) => response.toJSON() as Promise<ISidebarVm>)
                setSidebarData(response)
            } catch (e) {
                error.setError(e.response as IErrorResponse)
            }
            setSidebarLoading(false)
        }

        fetchData()
    }, [])

    // Load details for every change to NewsId
    useEffect(() => {
        const fetchData = async () => {
            setLoading(true)
            try {
                if (parseInt(newsId) > 0) {
                    let client = new NewsClient()
                    const response = await client.getRawNewsDataById(parseInt(newsId)).then((response) => response.toJSON() as Promise<IRawNewsPostVm>)
                    setPostData(response.newsDetails.content)
                    setTitle(response.newsDetails.title)
                    setPublish(response.newsDetails.published)
                }
            } catch (e) {
                error.setError(e.response as IErrorResponse)
            }
            setLoading(false)
        }

        fetchData()
    }, [newsId])

    return (
        <React.Fragment>
            <HelmetPages title='Doom Central - Edit News Post' />
            <Sidebars loading={sidebarLoading} newsItems={sidebarData?.newsItems} recentThreads={sidebarData?.recentThreads}>
                <div className='maincontent-container p-4'>
                    <Row>
                        <Col lg={8}>
                            <NewsEditorTitle title={title} setTitle={setTitle} />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <NewsEditorBoxContainer post={postData} setPost={setPostData} />
                            <NewsEditorPublish publish={publish} setPublish={setPublish} />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <SubmitEditNewsButton newsId={parseInt(newsId)} loading={loading} setLoading={setLoading} newsTitle={title} post={postData} publish={publish} />
                        </Col>
                    </Row>
                </div>
            </Sidebars>
        </React.Fragment>
    )
}
