import { HomeClient } from '@/lib/DoomCentral'
import { useQuery } from '@tanstack/react-query'

export const useRecentThreads = () => {
    return useQuery({
        queryKey: ['recent-threads'],
        queryFn: async () => {
            const client = new HomeClient()
            const res = await client.getHomePage()
            return res.recentThreads
        },
    })
}
