import * as React from 'react'
import { useContext, useEffect, useState } from 'react'
import { Button, Form, FormGroup, Input, Label } from 'reactstrap'

import { IErrorResponse } from '../../../../ErrorResponse'
import { IUserDto, SetRolesForUserCommand, UsersClient } from '../../../../lib/DoomCentral'
import DoomCentralEventRoles from '../../../../lib/DoomCentralEventRoles'
import DoomCentralRoles from '../../../../lib/DoomCentralRoles'
import ForumRoles from '../../../../lib/ForumRoles'
import NewsPostRoles from '../../../../lib/NewsPostRoles'
import { ErrorContext } from '../../../../state'

interface UserRolesProps {
    selectedUser: IUserDto
    setModUser: React.Dispatch<React.SetStateAction<string>>
    userRoles: string[]
    setHidden: React.Dispatch<React.SetStateAction<boolean>>
}

export default (props: UserRolesProps) => {
    const error = useContext(ErrorContext)
    const [loading, setLoading] = useState<boolean>(false)

    const [doomCentralUser, setDoomCentralUser] = useState<boolean>(false)
    const [globalAdministrator, setGlobalAdministrator] = useState<boolean>(false)
    const [globalEventAdministrator, setGlobalEventAdministrator] = useState<boolean>(false)
    const [globalForumAdministrator, setGlobalForumAdministrator] = useState<boolean>(false)
    const [globalNewsAdministrator, setGlobalNewsAdministrator] = useState<boolean>(false)
    const [eventOrganizer, setEventOrganizer] = useState<boolean>(false)
    const [promotionAdministrator, setPromotionAdministrator] = useState<boolean>(false)
    const [newsAuthor, setNewsAuthor] = useState<boolean>(false)

    useEffect(() => {
        setDoomCentralUser(false)
        setGlobalAdministrator(false)
        setGlobalEventAdministrator(false)
        setGlobalForumAdministrator(false)
        setGlobalNewsAdministrator(false)
        setEventOrganizer(false)
        setPromotionAdministrator(false)
        setNewsAuthor(false)

        props.userRoles &&
            props.userRoles.map((s, idx) => {
                if (s === DoomCentralRoles.DoomCentralUser) {
                    setDoomCentralUser(true)
                } else if (s === DoomCentralRoles.GlobalAdministrator) {
                    setGlobalAdministrator(true)
                } else if (s === DoomCentralEventRoles.GlobalEventAdministrator) {
                    setGlobalEventAdministrator(true)
                } else if (s === ForumRoles.GlobalForumAdministrator) {
                    setGlobalForumAdministrator(true)
                } else if (s === NewsPostRoles.GlobalNewsAdministrator) {
                    setGlobalNewsAdministrator(true)
                } else if (s === DoomCentralEventRoles.EventOrganizer) {
                    setEventOrganizer(true)
                } else if (s === DoomCentralRoles.PromotionAdministrator) {
                    setPromotionAdministrator(true)
                } else if (s === NewsPostRoles.NewsAuthor) {
                    setNewsAuthor(true)
                }
            })
    }, [props.userRoles])

    const createRolesArray = (): string[] => {
        let roles: string[] = []

        if (doomCentralUser) {
            roles.push(DoomCentralRoles.DoomCentralUser)
        }
        if (globalAdministrator) {
            roles.push(DoomCentralRoles.GlobalAdministrator)
        }
        if (globalEventAdministrator) {
            roles.push(DoomCentralEventRoles.GlobalEventAdministrator)
        }
        if (globalForumAdministrator) {
            roles.push(ForumRoles.GlobalForumAdministrator)
        }
        if (globalNewsAdministrator) {
            roles.push(NewsPostRoles.GlobalNewsAdministrator)
        }
        if (eventOrganizer) {
            roles.push(DoomCentralEventRoles.EventOrganizer)
        }
        if (promotionAdministrator) {
            roles.push(DoomCentralRoles.PromotionAdministrator)
        }
        if (newsAuthor) {
            roles.push(NewsPostRoles.NewsAuthor)
        }

        return roles
    }

    const submitNewUserRoles = async () => {
        if (props.selectedUser) {
            setLoading(true)
            try {
                let client = new UsersClient()
                const command = new SetRolesForUserCommand()
                command.userId = props.selectedUser.id
                command.roles = createRolesArray()
                const response = await client.setRoleForUser(command).then((response) => response as boolean)
                props.setModUser(props.selectedUser.id)
                props.setHidden(false)
                const timer = setTimeout(() => {
                    props.setHidden(true)
                }, 5000)
                return () => {
                    clearTimeout(timer)
                    setLoading(false)
                }
            } catch (e) {
                error.setError(e.response as IErrorResponse)
            }
            setLoading(false)
        }
    }

    return (
        <React.Fragment>
            <p>User Roles</p>
            <Form>
                <FormGroup check>
                    <ul>
                        <li>
                            <Label check>
                                <Input
                                    type='checkbox'
                                    name={'doomCentralUser'}
                                    id={'doomCentralUser'}
                                    onChange={(e) => setDoomCentralUser(e.target.checked)}
                                    checked={doomCentralUser}
                                />
                                Doom Central User
                            </Label>
                        </li>
                        <li>
                            <Label check>
                                <Input
                                    type='checkbox'
                                    name={'globalAdministrator'}
                                    id={'globalAdministrator'}
                                    onChange={(e) => setGlobalAdministrator(e.target.checked)}
                                    checked={globalAdministrator}
                                />
                                Global Administrator
                            </Label>
                        </li>
                        <li>
                            <Label check>
                                <Input
                                    type='checkbox'
                                    name={'globalEventAdministrator'}
                                    id={'globalEventAdministrator'}
                                    onChange={(e) => setGlobalEventAdministrator(e.target.checked)}
                                    checked={globalEventAdministrator}
                                />
                                Global Event Administrator
                            </Label>
                        </li>
                        <li>
                            <Label check>
                                <Input
                                    type='checkbox'
                                    name={'globalForumAdministrator'}
                                    id={'globalForumAdministrator'}
                                    onChange={(e) => setGlobalForumAdministrator(e.target.checked)}
                                    checked={globalForumAdministrator}
                                />
                                Global Forum Administrator
                            </Label>
                        </li>
                        <li>
                            <Label check>
                                <Input
                                    type='checkbox'
                                    name={'globalNewsAdministrator'}
                                    id={'globalNewsAdministrator'}
                                    onChange={(e) => setGlobalNewsAdministrator(e.target.checked)}
                                    checked={globalNewsAdministrator}
                                />
                                Global News Administrator
                            </Label>
                        </li>
                        <li>
                            <Label check>
                                <Input
                                    type='checkbox'
                                    name={'eventOrganizer'}
                                    id={'eventOrganizer'}
                                    onChange={(e) => setEventOrganizer(e.target.checked)}
                                    checked={eventOrganizer}
                                />
                                Event Organizer
                            </Label>
                        </li>
                        <li>
                            <Label check>
                                <Input
                                    type='checkbox'
                                    name={'promotionAdministrator'}
                                    id={'promotionAdministrator'}
                                    onChange={(e) => setPromotionAdministrator(e.target.checked)}
                                    checked={promotionAdministrator}
                                />
                                Promotion Administrator
                            </Label>
                        </li>
                        <li>
                            <Label check>
                                <Input type='checkbox' name={'newsAuthor'} id={'newsAuthor'} onChange={(e) => setNewsAuthor(e.target.checked)} checked={newsAuthor} />
                                News Author
                            </Label>
                        </li>
                    </ul>
                </FormGroup>
                <Button color='primary' onClick={(e) => submitNewUserRoles()}>
                    Submit Roles
                </Button>
            </Form>
        </React.Fragment>
    )
}
