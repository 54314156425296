import {
    ApproveForumThreadCommand,
    DeleteForumThreadCommand,
    LockThreadCommand,
    ModerateThreadCommand,
    StickyForumThreadCommand,
    ThreadsClient,
    UnlockThreadCommand,
    UnmoderateThreadCommand,
    UnstickyForumThreadCommand,
} from '@/lib/DoomCentral'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useHistory } from 'react-router-dom'
import { toast } from 'sonner'

export const useThreadModTools = ({ id }) => {
    const client = new ThreadsClient()
    const queryClient = useQueryClient()
    const history = useHistory()

    const handleSuccess = (message: string) => {
        toast.success(message)
        queryClient.invalidateQueries({ queryKey: ['thread-meta', id] })
    }

    const lockThreadMutation = useMutation({
        mutationFn: async () => {
            const cmd = new LockThreadCommand()
            cmd.threadId = id
            return client.lock(cmd)
        },
        onSuccess: () => {
            handleSuccess('Thread Locked')
        },
    })

    const unlockThreadMutation = useMutation({
        mutationFn: async () => {
            const cmd = new UnlockThreadCommand()
            cmd.threadId = id
            return client.unlock(cmd)
        },
        onSuccess: () => {
            handleSuccess('Thread Unlocked')
        },
    })

    const stickyThreadMutation = useMutation({
        mutationFn: async () => {
            const cmd = new StickyForumThreadCommand()
            cmd.threadId = id
            return client.sticky(cmd)
        },
        onSuccess: () => {
            handleSuccess('Thread Stickied')
        },
    })

    const unstickyThreadMutation = useMutation({
        mutationFn: async () => {
            const cmd = new UnstickyForumThreadCommand()
            cmd.threadId = id
            return client.unsticky(cmd)
        },
        onSuccess: () => {
            handleSuccess('Thread Unstickied')
        },
    })

    const moderateThreadMutation = useMutation({
        mutationFn: async () => {
            const cmd = new ModerateThreadCommand()
            cmd.threadId = id
            return client.moderate(cmd)
        },
        onSuccess: () => {
            handleSuccess('Thread Moderated')
        },
    })

    const unmoderateThreadMutation = useMutation({
        mutationFn: async () => {
            const cmd = new UnmoderateThreadCommand()
            cmd.threadId = id
            return client.unmoderate(cmd)
        },
        onSuccess: () => {
            handleSuccess('Thread Unmoderated')
        },
    })

    const approveThreadMutation = useMutation({
        mutationFn: async () => {
            const cmd = new ApproveForumThreadCommand()
            cmd.threadId = id
            return client.approve(cmd)
        },
        onSuccess: () => {
            handleSuccess('Thread Approved')
        },
    })

    const deleteThreadMutation = useMutation({
        mutationFn: async () => {
            const command = new DeleteForumThreadCommand()
            command.threadId = id
            const confirmation = confirm('Are you sure you want to delete this thread? The changes are irreversible.')
            if (confirmation) {
                await client.delete(id, command)
                toast.success('Thread deleted. Refresh the page.')
            }
        },
        onSuccess: () => {
            toast.success('Thread Deleted')
            history.push('/forums')
        },
    })

    return {
        lockThreadMutation,
        unlockThreadMutation,
        stickyThreadMutation,
        unstickyThreadMutation,
        moderateThreadMutation,
        unmoderateThreadMutation,
        approveThreadMutation,
        deleteThreadMutation,
    }
}
