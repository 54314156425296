import * as React from 'react'
import { useContext, useState } from 'react'
import { Button, FormGroup, FormText, Input, InputGroup, InputGroupText, Label, ListGroup, ListGroupItem } from 'reactstrap'

import { IErrorResponse } from '../../../ErrorResponse'
import { ISearchUsersVm, IUserDto, UsersClient } from '../../../lib/DoomCentral'
import { ErrorContext } from '../../../state'

interface AddUserToPermissionsGroupProps {
    loading: boolean
    users: IUserDto[]
    setUsers: React.Dispatch<React.SetStateAction<IUserDto[]>>
}

export default (props: AddUserToPermissionsGroupProps) => {
    const error = useContext(ErrorContext)

    const [loading, setLoading] = useState<boolean>(false)
    const [userName, setUserName] = useState<string>('')
    const [userSearchList, setUserSearchList] = useState<IUserDto[]>([])
    const [selectedAddUser, setSelectedAddUser] = useState<IUserDto>(null)
    const [selectedRemoveUser, setSelectedRemoveUser] = useState<IUserDto>(null)

    const searchUsers = async (userNameSearchQuery: string) => {
        setLoading(true)
        try {
            let client = new UsersClient()

            const response = await client.searchUsers(userNameSearchQuery).then((response) => response.toJSON() as Promise<ISearchUsersVm>)
            setUserSearchList(response.userList)
        } catch (e) {
            error.setError(e.response as IErrorResponse)
        }
        setLoading(false)
    }

    const addUser = (user: IUserDto): IUserDto[] => {
        let dupes: boolean = false
        props.users.map((s, idx) => {
            if (s.id === user.id) {
                dupes = true
            }
        })
        if (dupes) {
            return props.users
        }
        setSelectedAddUser(null)
        return [...props.users, user]
    }

    const dupeUser = (user: IUserDto): boolean => {
        let dupes: boolean = false
        props.users.map((s, idx) => {
            if (s.id === user.id) {
                dupes = true
            }
        })
        return dupes
    }

    const removeUser = (user: IUserDto): IUserDto[] => {
        setSelectedRemoveUser(null)
        return [...props.users.filter((_, i) => _.id !== user.id)]
    }

    return (
        <React.Fragment>
            <InputGroup>
                <InputGroupText>User name</InputGroupText>
                <Input value={userName} onChange={(e) => setUserName(e.target.value)} />
                <Button color='primary' onClick={(e) => searchUsers(userName)}>
                    Search
                </Button>
            </InputGroup>
            <InputGroup>
                <Input name='select' type='select'>
                    {userSearchList.length > 0 &&
                        userSearchList.map((s, idx) => (
                            <option key={s.id} onClick={(e) => setSelectedAddUser(s)}>
                                {s.userName}
                            </option>
                        ))}
                </Input>
                <Button color='primary' onClick={(e) => props.setUsers(addUser(selectedAddUser))} disabled={!selectedAddUser || dupeUser(selectedAddUser)}>
                    Add User
                </Button>
            </InputGroup>
            {props.users.length > 0 && (
                <select size={10} style={{ width: '100%' }}>
                    {props.users.map((s, idx) => (
                        <option key={s.id} onClick={(e) => setSelectedRemoveUser(s)}>
                            {s.userName}
                        </option>
                    ))}
                </select>
            )}
            <InputGroup>
                <Button color='danger' onClick={(e) => props.setUsers(removeUser(selectedRemoveUser))} disabled={!selectedRemoveUser}>
                    Remove User
                </Button>
            </InputGroup>
        </React.Fragment>
    )
}
