import MDEditor from '@uiw/react-md-editor'
import * as React from 'react'
import { useState } from 'react'
import { Button, Input } from 'reactstrap'
import rehypeRaw from 'rehype-raw'
import rehypeSanitize, { defaultSchema } from 'rehype-sanitize'
import remarkBreaks from 'remark-breaks'
import remarkDirective from 'remark-directive'
import remarkGfm from 'remark-gfm'
import remarkImages from 'remark-images'
import remarkUnwrapImages from 'remark-unwrap-images'

interface NewPostBoxProps {
    post: string
    setPost: React.Dispatch<React.SetStateAction<string>>
}

export default (props: NewPostBoxProps) => {
    return (
        <React.Fragment>
            <MDEditor
                value={props.post}
                onChange={props.setPost}
                previewOptions={{
                    remarkPlugins: [
                        [remarkGfm, { singleTilde: false }],
                        remarkBreaks,
                        remarkUnwrapImages,
                        remarkDirective,
                        remarkImages
                    ],
                    rehypePlugins: [
                        [
                            rehypeSanitize
                        ],
                    ],
                }}
            />
        </React.Fragment>
    )
}
