import * as React from 'react'
import { NavLink } from 'react-router-dom'
import { Button, Col, Input, InputGroup, Row } from 'reactstrap'

interface NewsAuthorInfoProps {
    newsAuthorDisplayName: string
    newsAuthor: string
    newsDate: Date
}

export default (props: NewsAuthorInfoProps) => {
    return (
        <React.Fragment>
            <Row>
                <Col>
                    {props.newsAuthor && (
                        <p className='lead'>
                            by <NavLink to={`/user/${props.newsAuthor}`}>{props.newsAuthorDisplayName}</NavLink>
                        </p>
                    )}
                    {!props.newsAuthor && (
                        <div className='placeholder-glow'>
                            <span className='module-item-title placeholder col-8'></span>
                        </div>
                    )}
                </Col>
            </Row>
            <hr />
            <Row>
                <Col>
                    {props.newsDate && (
                        <p className='mb-0'>
                            Posted on {new Intl.DateTimeFormat('default', { dateStyle: 'full', timeStyle: 'long' }).format(new Date(props.newsDate))} in{' '}
                            <NavLink to={'/news/'}>News</NavLink>
                        </p>
                    )}
                    {!props.newsDate && (
                        <div className='placeholder-glow'>
                            <span className='module-item-title placeholder col-8'></span>
                        </div>
                    )}
                </Col>
            </Row>
            <hr />
        </React.Fragment>
    )
}
