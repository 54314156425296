import * as React from 'react'
import { Route } from 'react-router'

import DoomCentralRoles from '../../lib/DoomCentralRoles'
import AuthorizeRoute from '../api-authorization/AuthorizeRoute'
import ManageSubscriptions from '../managesubscriptions/ManageSubscriptions'
import ProfilePage from '../profilepage/ProfilePage'

export default () => (
    <React.Fragment>
        <AuthorizeRoute path='/managesubscriptions' component={ManageSubscriptions} componentroles={DoomCentralRoles.DoomCentralUser} />
        <Route exact path='/user/:userName' component={ProfilePage} />
    </React.Fragment>
)
