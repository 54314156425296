import { Columns } from '@/components/columns/Columns'
import { CommunityNewsCard } from '@/components/community-news-card/CommunityNewsCard'
import { EventsCard } from '@/components/events-card/EventsCard'
import HelmetPages from '@/components/helmet/HelmetPages'
import { RecentThreadsCard } from '@/components/recent-threads-card/RecentThreadsCard'
import { ForumList } from '../../components/forum-list/ForumList'

export const ForumsPage = () => {
    return (
        <>
            <HelmetPages title='Doom Central - Forums' />
            <Columns
                rightColumn={
                    <div>
                        <CommunityNewsCard className='mb-3' />
                        <EventsCard className='mb-3' />
                        <RecentThreadsCard className='mb-3' />
                    </div>
                }
            >
                <ForumList />
            </Columns>
        </>
    )
}
