import { routeForResourceType } from '@/utils/helpers'
import * as React from 'react'
import { useEffect } from 'react'
import { NavLink } from 'react-router-dom'
import { Input, Table } from 'reactstrap'

import { IPromotableItemDto } from '../../lib/DoomCentral'

interface SelectResourceProps {
    resources: IPromotableItemDto[]
    selectedResource: IPromotableItemDto
    setSelectedResource: React.Dispatch<React.SetStateAction<IPromotableItemDto>>
}

export default (props: SelectResourceProps) => {
    const selectResource = (index: number) => {
        props.setSelectedResource(props.resources[index] ?? props.resources[0])
    }

    return (
        <React.Fragment>
            <Table>
                <thead>
                    <tr key='header'>
                        <th> </th>
                        <th>#</th>
                        <th>Resource Type</th>
                        <th>Link</th>
                        <th>Sample Content</th>
                    </tr>
                </thead>
                <tbody>
                    {props.resources &&
                        props.resources.map((s, idx) => (
                            <tr key={idx}>
                                <td>
                                    <Input type='radio' name='usergroup' value={idx} onClick={(s) => selectResource(idx)} />
                                </td>
                                <td>{idx}</td>
                                <td>{routeForResourceType(s.promotedType)}</td>
                                <td>
                                    <NavLink to={`/${routeForResourceType(s.promotedType)}/${s.resourceId}`}>{s.title}</NavLink>
                                </td>
                                <td>{s.sampleContent}</td>
                            </tr>
                        ))}
                </tbody>
            </Table>
        </React.Fragment>
    )
}
