import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'
import rehypeSanitize, { defaultSchema } from 'rehype-sanitize'
import remarkBreaks from 'remark-breaks'
import remarkDirective from 'remark-directive'
import remarkGfm from 'remark-gfm'
import remarkImages from 'remark-images'
import remarkColorDirectivePlugin from '../../remarkColorDirectivePlugin'

interface MarkdownPostProps {
    content: string
}

export default (props: MarkdownPostProps) => {
    return (
        <ReactMarkdown
            remarkPlugins={[[remarkGfm, { singleTilde: false }], remarkBreaks, remarkDirective, remarkImages, remarkColorDirectivePlugin]}
            rehypePlugins={[
                [
                    rehypeRaw,
                ],
            ]}
        >
            {props.content}
        </ReactMarkdown>
    )
}
