import PostPagination from '@/components/thread/PostPagination'
import PostStation from '@/components/thread/PostStation'
import ThreadPostList from '@/components/thread/ThreadPostList'
import useQueryParam from '@/hooks/useQueryParam'
import * as React from 'react'
import { useEffect, useState } from 'react'
import { useAuth } from 'react-oidc-context'
import { NavLink } from 'react-router-dom'

interface SingleMediaContainerProps {
    children?: React.ReactNode | React.ReactNode[]
    loading: boolean
    id: number
    authorized: boolean
    isAdmin: boolean
    allowedFileExtensions: string
    maxFileSizeBytes: number
    maxFilesInPost: number
}

/**
 * A container that is designed to take 1 page media and attach posts to it.
 * Now even does forum threads.
 */
export default (props: SingleMediaContainerProps) => {
    const [pageNumber, setPageNumber] = useState<number>(0)
    const [pageNumberCounter, setPageNumberCounter] = useState<number>(0) // used to display page number, but not control when changing it
    const [pageSize, setPageSize] = useState<number>(40)
    const [pageCount, setPageCount] = useState<number>(0)
    const [postId, setPostId] = useState<number>(null)
    const [gotoMode, setGotoMode] = useState<string>(null)

    const [newPostNum, setNewPostNum] = useState<number>(0)

    const [quotedPostId, setQuotedPostId] = useState<number>(0)

    const [pageSizeParam, setPageSizeParam] = useQueryParam('pageSize', '')
    const [pageNumberParam, setPageNumberParam] = useQueryParam('pageNumber', '')
    const [gotoParam, setGotoParam] = useQueryParam('goto', '')
    const [postIdParam, setPostIdParam] = useQueryParam('postId', '')

    const auth = useAuth()

    const setPageChanges = (page: number, displayOnly: boolean) => {
        if (displayOnly === true) {
            setPageNumberCounter(page)
        } else {
            setPageNumberParam(page.toString())
            setGotoParam('')
        }
    }

    // Set vars based off of params.
    useEffect(() => {
        if (pageSizeParam) {
            setPageSize(parseInt(pageSizeParam))
        } else {
            setPageSize(40)
        }
    }, [pageSizeParam])

    useEffect(() => {
        if (pageNumberParam) {
            setPageNumber(parseInt(pageNumberParam))
            setPageNumberCounter(parseInt(pageNumberParam))
        } else {
            setPageNumber(0)
            setPageNumberCounter(0)
        }
    }, [pageNumberParam])

    useEffect(() => {
        if (postIdParam) {
            setPostId(parseInt(postIdParam))
        } else {
            setPostId(0)
        }
    }, [postIdParam])

    useEffect(() => {
        if (gotoParam) {
            setGotoMode(gotoParam)
        } else {
            setGotoMode(null)
        }
    }, [gotoParam])

    return (
        <>
            {props.children}
            {pageCount > 1 && <PostPagination pageCount={pageCount} currentPage={pageNumberCounter - 1} setPageChange={setPageChanges} />}
            <ThreadPostList
                id={props.id}
                loading={props.loading}
                pageNumber={pageNumber}
                pageNumberCounter={pageNumberCounter}
                pageSize={pageSize}
                newPostNum={newPostNum}
                setNewPostNum={setNewPostNum}
                setPageCount={setPageCount}
                setPageChange={setPageChanges}
                setMode={setGotoMode}
                setQuotedPostId={setQuotedPostId}
                authorized={props.authorized}
                isAdmin={props.isAdmin}
                mode={gotoMode}
                postId={postId}
            />
            {pageCount > 1 && <PostPagination pageCount={pageCount} currentPage={pageNumberCounter - 1} setPageChange={setPageChanges} />}
            {auth.user && auth.isAuthenticated && props.authorized && !props.loading && (
                <PostStation
                    id={props.id}
                    quotedPostId={quotedPostId}
                    setNewPostNum={setNewPostNum}
                    setQuotedPostId={setQuotedPostId}
                    setGotoMode={setGotoMode}
                    maxFilesInPost={props.maxFilesInPost}
                    maxFileSizeBytes={props.maxFileSizeBytes}
                    allowedFileExtensions={props.allowedFileExtensions}
                />
            )}
            {(!auth.user || !auth.isAuthenticated) && !props.loading && (
                <div className='my-2 text-muted text-center'>
                    To post, you need to{' '}
                    <a className='link-primary' role='button' onClick={() => auth.signinRedirect()}>
                        log in
                    </a>
                    !
                </div>
            )}
            {auth.user && auth.isAuthenticated && !props.authorized && !props.loading && <div className='my-2 text-muted text-center'>You are not authorized to post here.</div>}
        </>
    )
}
