import * as React from 'react'
import { useContext, useEffect, useState } from 'react'
import { Input, Label } from 'reactstrap'

import { ForumsClient, IForumDto, IForumListVm } from '../../../../lib/DoomCentral'
import { ErrorContext } from '../../../../state'

interface ForumSelectProps {
    modForum: number
    setForumSelect: React.Dispatch<React.SetStateAction<IForumDto>>
    loading: boolean
}

export default (props: ForumSelectProps) => {
    const error = useContext(ErrorContext)

    const [loading, setLoading] = useState<boolean>(false)
    const [selectedForumId, setSelectedForumId] = useState<number>(0)
    const [forums, setForums] = useState<IForumListVm>(null)

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true)
            try {
                let client = new ForumsClient()
                const response = await client.getForums().then((response) => response.toJSON() as Promise<IForumListVm>)
                setForums(response)
            } catch (e) {
                error.setError(JSON.parse(e.response))
            }
            setLoading(false)
        }

        fetchData()
    }, [props.modForum])

    useEffect(() => {
        if (forums && forums.forums && forums.forums.length > 0) {
            selectForum(forums.forums[0].id)
        }
    }, [forums])

    const selectForum = (forumId: number) => {
        setSelectedForumId(forumId)
        props.setForumSelect(forums.forums.find((f) => f.id === forumId) ?? forums.forums[0])
        return
    }

    return (
        <React.Fragment>
            <Label for='forumSelect'>Forum:</Label>
            <Input name='forumSelect' id='forumSelect' type='select' value={selectedForumId} onChange={(e) => selectForum(parseInt(e.target.value, 10))}>
                {forums &&
                    forums.forums.map((s, idx) => (
                        <option value={s.id} key={s.id}>
                            {s.name}
                        </option>
                    ))}
            </Input>
        </React.Fragment>
    )
}
