import { useEffect, useRef, useState } from "react"

export const useInView = () => {

    const ref = useRef()

    const [isInView, setIsInView] = useState(false)

    const callbackFunction = entries => {
        const [entry] = entries
        setIsInView(entry.isIntersecting)
    }

    useEffect(() => {

        const observer = new IntersectionObserver(callbackFunction)

        if (ref.current) observer.observe(ref.current)

        const currentRef = ref.current

        return () => {
            if (currentRef) observer.unobserve(currentRef)
        }

    }, [ref])

    return [ref, isInView]
    
}