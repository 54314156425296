import * as React from 'react'
import { Helmet } from 'react-helmet-async'

interface HelmetPagesProps {
    title: string
}

export default (props: HelmetPagesProps) => (
    <Helmet>
        <title>{props.title}</title>
    </Helmet>
)
