import * as React from 'react'
import { useContext, useEffect, useState } from 'react'
import { Button, Col, Form, FormGroup, Input, Label, Row } from 'reactstrap'

import { IErrorResponse } from '../../ErrorResponse'
import { ForumsClient, IForumDto, IForumListVm, IPromotableItemDto, IPromotableResourceVm, PromotionsClient } from '../../lib/DoomCentral'
import { ErrorContext } from '../../state'

interface SearchForResourcesProps {
    setResources: React.Dispatch<React.SetStateAction<IPromotableItemDto[]>>
    modPromotion: number
}

export default (props: SearchForResourcesProps) => {
    const error = useContext(ErrorContext)

    const [loading, setLoading] = useState<boolean>(false)
    const [forum, setForum] = useState<number>()
    const [forumList, setForumList] = useState<IForumDto[]>()
    const [includeNews, setIncludeNews] = useState<boolean>(true)
    const [includeEvents, setIncludeEvents] = useState<boolean>(true)
    const [title, setTitle] = useState<string>('')

    useEffect(() => {
        setTitle('')
    }, [props.modPromotion])

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true)
            try {
                let client = new ForumsClient()
                const response = await client.getForums().then((response) => response.toJSON() as Promise<IForumListVm>)
                setForumList(response.forums)
            } catch (e) {
                error.setError(e.response as IErrorResponse)
            }
            setLoading(false)
        }

        fetchData()
    }, [])

    const fetchResourceSearch = async () => {
        setLoading(true)
        try {
            let client = new PromotionsClient()
            const response = await client
                .searchForPromotableResources(includeNews, includeEvents, forum, title)
                .then((response) => response.toJSON() as Promise<IPromotableResourceVm>)
            props.setResources(response.promotableItems)
        } catch (e) {
            error.setError(e.response as IErrorResponse)
        }
        setLoading(false)
    }

    const renderForumSelect = () => {
        return (
            <Col sm={10}>
                <select name='forums' id='forums' value={forum} onChange={(e) => handleForumChange(e)}>
                    <option key={null} value={null}>
                        All Forums
                    </option>
                    {forumList &&
                        forumList.length > 0 &&
                        forumList.map((f) => (
                            <option key={f.id} value={f.id}>
                                {f.name}
                            </option>
                        ))}
                </select>
            </Col>
        )
    }

    const handleForumChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        if (e.target.value !== 'All Forums') {
            setForum(parseInt(e.target.value))
        } else {
            setForum(null)
        }
    }

    return (
        <React.Fragment>
            <Form>
                <FormGroup row>
                    <Label for='forums'>Forums</Label>
                    {renderForumSelect()}
                </FormGroup>
                <FormGroup check>
                    <ul>
                        <li>
                            <Label check>
                                <Input type='checkbox' name='eventsBox' id='eventsBox' onChange={(e) => setIncludeEvents(e.target.checked)} checked={includeEvents} />
                                {'     '}
                                Include Events
                            </Label>
                        </li>
                        <li>
                            <Label check>
                                <Input type='checkbox' name='newsBox' id='newsBox' onChange={(e) => setIncludeNews(e.target.checked)} checked={includeNews} />
                                {'     '}
                                Include News
                            </Label>
                        </li>
                    </ul>
                </FormGroup>
                <FormGroup row>
                    <Label>Title</Label>
                    <Col sm={10}>
                        <Input value={title} onChange={(e) => setTitle(e.target.value)} />
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Button color='primary' onClick={(e) => fetchResourceSearch()} disabled={loading || !title}>
                        Search for resource
                    </Button>
                </FormGroup>
            </Form>
        </React.Fragment>
    )
}
