import { createContext, useState } from 'react'

import { IErrorContext, IErrorResponse } from './ErrorResponse'

export const ErrorContext = createContext({
    error: null as IErrorResponse,
    setError: null,
} as IErrorContext)

export const ErrorProvider = ({ children }) => {
    const [error, setError] = useState(null as IErrorResponse)

    return (
        <ErrorContext.Provider
            value={{
                error: error,
                setError: setError,
            }}
        >
            {children}
        </ErrorContext.Provider>
    )
}
