import { routeForResourceType } from '@/utils/helpers'
import type { Identifier, XYCoord } from 'dnd-core'
import type { FC } from 'react'
import { useRef } from 'react'
import { useDrag, useDrop } from 'react-dnd'
import { Card, CardBody, CardImg, CardText, CardTitle, CloseButton } from 'reactstrap'

import { PromotedType } from '../../lib/DoomCentral'
import { ItemTypes } from './ItemTypes'

export interface CardProps {
    id: any
    title: string
    blurb: string
    url: string
    type: PromotedType
    index: number
    deletePromotion: (index: number) => void
    moveCard: (dragIndex: number, hoverIndex: number) => void
}

interface DragItem {
    index: number
    id: string
    type: string
}

export const PromotionItem: FC<CardProps> = ({ id, title, blurb, url, type, index, moveCard, deletePromotion }) => {
    const ref = useRef<HTMLDivElement>(null)
    const [{ handlerId }, drop] = useDrop<DragItem, void, { handlerId: Identifier | null }>({
        accept: ItemTypes.CARD,
        collect(monitor) {
            return {
                handlerId: monitor.getHandlerId(),
            }
        },
        hover(item: DragItem, monitor) {
            if (!ref.current) {
                return
            }
            const dragIndex = item.index
            const hoverIndex = index

            // Don't replace items with themselves
            if (dragIndex === hoverIndex) {
                return
            }

            // Determine rectangle on screen
            const hoverBoundingRect = ref.current?.getBoundingClientRect()

            // Get vertical middle
            const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2

            // Determine mouse position
            const clientOffset = monitor.getClientOffset()

            // Get pixels to the top
            const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top

            // Only perform the move when the mouse has crossed half of the items height
            // When dragging downwards, only move when the cursor is below 50%
            // When dragging upwards, only move when the cursor is above 50%

            // Dragging downwards
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return
            }

            // Dragging upwards
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return
            }

            // Time to actually perform the action
            moveCard(dragIndex, hoverIndex)

            // Note: we're mutating the monitor item here!
            // Generally it's better to avoid mutations,
            // but it's good here for the sake of performance
            // to avoid expensive index searches.
            item.index = hoverIndex
        },
    })

    const [{ isDragging }, drag] = useDrag({
        type: ItemTypes.CARD,
        item: () => {
            return { id, index }
        },
        collect: (monitor: any) => ({
            isDragging: monitor.isDragging(),
        }),
    })

    const opacity = isDragging ? 0 : 1
    drag(drop(ref))
    return (
        <Card className='my-2' innerRef={ref} data-handler-id={handlerId} style={{ opacity }}>
            <CloseButton className=' p-2 ms-auto me-2 my-2 bg-light' onClick={(e) => deletePromotion(index)} />
            <CardImg
                alt='Promotion Image'
                src={url}
                style={{
                    height: 180,
                }}
                top
                width='100%'
            />
            <CardBody>
                <CardTitle tag='h5'>{title}</CardTitle>
                <CardText>{blurb}</CardText>
                <CardText>
                    <small className='text-muted'>
                        {routeForResourceType(type)}
                        <br />#{index + 1}
                    </small>
                </CardText>
            </CardBody>
        </Card>
    )
}
