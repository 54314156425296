import { MutableRefObject, useContext, useEffect, useState } from "react"
import { useCapabilities } from "./useCapabilities"
import { ReadPostsContext } from "@/contexts/ReadPostsContext"
import { PostItemDto } from "@/lib/DoomCentral"

export type useMarkPostsAsReadProps =  {
    post: PostItemDto
    isInView: boolean | MutableRefObject<undefined>
    threadId: number
}

export const useMarkPostsAsRead = ({ post, isInView }: useMarkPostsAsReadProps) => {

    const { postsToRead, setPostsToRead } = useContext(ReadPostsContext)

    const { isAuthenticated } = useCapabilities()

    useEffect(() => {
        if (!isAuthenticated) return

        if (post.unread && isInView && !postsToRead.includes(post.id)) {
            setPostsToRead([...postsToRead, post.id])
        }

    }, [isInView, post])

}