import { getPaginatedThread } from '@/api/getPaginatedThread'
import { keepPreviousData, useQuery } from '@tanstack/react-query'

export type useThreadProps = {
    id: number | string
    page?: number
    limit?: number
}

export const usePaginatedThread = ({ id, page = 1, limit = 10 }: useThreadProps) => {
    return useQuery({
        queryKey: ['thread', { id, page, limit }],
        queryFn: async () => await getPaginatedThread({ id: +id, page, limit }),
        placeholderData: keepPreviousData,
        staleTime: 5000,
    })
}
