import * as React from 'react'
import { useEffect, useState } from 'react'
import Select, { GroupProps, components } from 'react-select'
import { FormGroup, Label } from 'reactstrap'

import { IForumGroupDto } from '../../lib/DoomCentral'

interface NewThreadOptionsProps {
    forumGroups: IForumGroupDto[]
    setForumId: React.Dispatch<React.SetStateAction<number>>
    forumId: number
}

export interface ForumOption {
    readonly value: string
    readonly label: string
}

const Group = (props: GroupProps<ForumOption, false>) => (
    <div>
        <components.Group {...props} />
    </div>
)

export interface GroupedOption {
    readonly label: string
    readonly options: readonly ForumOption[]
}

export default (props: NewThreadOptionsProps) => {
    const [groupOption, setGroupOption] = useState<GroupedOption[]>(null)

    useEffect(() => {
        const forumGroups: GroupedOption[] = []
        props.forumGroups &&
            props.forumGroups.map((s, idx) => {
                const forums: ForumOption[] = []
                s.forums.map((t, _idx) => {
                    forums.push({ label: t.name, value: t.id.toString() })
                })
                forumGroups.push({ label: s.groupName, options: forums })
            })
        setGroupOption(forumGroups)
    }, [props.forumGroups])

    return (
        <React.Fragment>
            <FormGroup>
                <Label for='forum'>Forum</Label>
                <Select<ForumOption>
                    options={groupOption}
                    components={{ Group }}
                    onChange={(e) => props.setForumId(parseInt(e.value))}
                    classNames={{
                        option: (state) => (state.isSelected ? 'text-light' : 'text-dark'),
                    }}
                    value={
                        groupOption
                            ?.filter((f) => f.options.find((g) => parseInt(g.value) === props.forumId) ?? null)[0]
                            ?.options?.find((f) => parseInt(f.value) === props.forumId) ?? null
                    }
                />
            </FormGroup>
        </React.Fragment>
    )
}
