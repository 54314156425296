import * as React from 'react'
import { useContext, useEffect, useState } from 'react'
import { Button, FormGroup, FormText, Input, InputGroup, InputGroupText, Label, ListGroup, ListGroupItem } from 'reactstrap'

import { IErrorResponse } from '../../../../ErrorResponse'
import { ForumGroupsClient, ForumOrderObject, IAllForumGroupDto, IForumOrderObject, ReorderForumGroupsAndForumsCommand } from '../../../../lib/DoomCentral'
import { ErrorContext } from '../../../../state'

interface ForumOrderComponentProps {
    forums: IAllForumGroupDto[]
    loading: boolean
    setModOrder: React.Dispatch<React.SetStateAction<number>>
}

export default (props: ForumOrderComponentProps) => {
    const error = useContext(ErrorContext)

    const [loading, setLoading] = useState<boolean>(false)
    const [orderArray, setOrderArray] = useState<IForumOrderObject[]>([])

    const changeGroupOrder = (groupId: number, newOrder: number) => {
        let order: ForumOrderObject[] = []

        orderArray.map((s, idx) => {
            let obj: ForumOrderObject = {} as ForumOrderObject
            let children: ForumOrderObject[] = []

            obj.resourceId = s.resourceId

            if (s.resourceId === groupId) {
                obj.order = newOrder
            } else {
                obj.order = s.order
            }

            s.children.map((t, _idx) => {
                let childobj: ForumOrderObject = {} as ForumOrderObject

                childobj.resourceId = t.resourceId
                childobj.order = t.order

                children = [...children, childobj]
            })

            obj.children = children

            order = [...order, obj]
        })

        setOrderArray(order)
    }

    const changeForumOrder = (groupId: number, forumId: number, newOrder: number) => {
        let order: ForumOrderObject[] = []

        orderArray.map((s, idx) => {
            let obj: ForumOrderObject = {} as ForumOrderObject
            let children: ForumOrderObject[] = []

            obj.resourceId = s.resourceId
            obj.order = s.order

            s.children.map((t, _idx) => {
                let childobj: ForumOrderObject = {} as ForumOrderObject

                childobj.resourceId = t.resourceId
                if (s.resourceId === groupId && t.resourceId === forumId) {
                    childobj.order = newOrder
                } else {
                    childobj.order = t.order
                }

                children = [...children, childobj]
            })

            obj.children = children

            order = [...order, obj]
        })

        setOrderArray(order)
    }

    const submitNewOrder = async () => {
        if (!loading && !props.loading) {
            setLoading(true)
            try {
                let client = new ForumGroupsClient()
                let command = new ReorderForumGroupsAndForumsCommand()

                let order: ForumOrderObject[] = []

                orderArray.map((s, idx) => {
                    let orderItem: ForumOrderObject = new ForumOrderObject()
                    let orderChildren: ForumOrderObject[] = []

                    orderItem.resourceId = s.resourceId
                    orderItem.order = s.order

                    s.children.map((t, _idx) => {
                        let child: ForumOrderObject = new ForumOrderObject()

                        child.resourceId = t.resourceId
                        child.order = t.order

                        orderChildren = [...orderChildren, child]
                    })

                    orderItem.children = orderChildren

                    order = [...order, orderItem]
                })

                command.orderArray = order

                const response = await client.reorder(command)

                props.setModOrder(response)
            } catch (e) {
                error.setError(e.response as IErrorResponse)
            }
            setLoading(false)
        }
    }

    useEffect(() => {
        let array: ForumOrderObject[] = []

        props.forums.map((s, idx) => {
            let obj: ForumOrderObject = {} as ForumOrderObject
            let children: ForumOrderObject[] = []

            s.forums.map((t, _idx) => {
                let childobj: ForumOrderObject = {} as ForumOrderObject

                childobj.resourceId = t.id
                childobj.order = t.order

                children = [...children, childobj]
            })

            obj.resourceId = s.id
            obj.order = s.order
            obj.children = children

            array = [...array, obj]
        })

        setOrderArray(array)
    }, [props.forums])

    return (
        <React.Fragment>
            {props.forums && props.forums.length > 0 && (
                <div>
                    {props.forums.map((s, idx) => (
                        <div>
                            <div>
                                <Label for={'group' + s.id}>
                                    <Input
                                        type='number'
                                        name='orderGroup'
                                        disabled={loading || props.loading}
                                        min='0'
                                        step='1'
                                        id={'group' + s.id}
                                        value={(orderArray.length > 0 && orderArray.find((f) => f.resourceId === s.id).order) ?? 0}
                                        onChange={(e) => changeGroupOrder(s.id, parseInt(e.target.value))}
                                    />{' '}
                                    {s.groupName}
                                </Label>
                                <p>{s.groupDescription}</p>
                                <p>Original order: {s.order}</p>
                            </div>

                            {s.forums.map((t, _idx) => (
                                <div className='ms-5'>
                                    <Label for={'forum' + t.id}>
                                        <Input
                                            type='number'
                                            name='orderForum'
                                            disabled={loading || props.loading}
                                            min='0'
                                            step='1'
                                            id={'forum' + t.id}
                                            value={(orderArray.length > 0 && orderArray.flatMap((f) => f.children).find((fi) => fi.resourceId === t.id).order) ?? 0}
                                            onChange={(e) => changeForumOrder(s.id, t.id, parseInt(e.target.value))}
                                        />{' '}
                                        {t.name}
                                    </Label>
                                    <p>{t.description}</p>
                                    <p>Original order: {t.order}</p>
                                </div>
                            ))}
                        </div>
                    ))}
                    <Button color='primary' onClick={(e) => submitNewOrder()} disabled={orderArray.length <= 0 && !loading && !props.loading}>
                        Submit new forum order
                    </Button>
                </div>
            )}
        </React.Fragment>
    )
}
