import { IThreadDetailDto, PostItemDto, ThreadPostsClient, UpdateThreadPostCommand } from '@/lib/DoomCentral'
import { UserCanEditForumPost, UserCanPostInThread, UserIsForumAdmin } from '@/utils/helpers'
import { cn } from '@/utils/utils'
import RelativeTime from '@yaireo/relative-time'
import { useEffect, useState } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { useAuth } from 'react-oidc-context'
import { useHistory } from 'react-router'
import { Button, Card, CardBody, Input, InputGroup, Label } from 'reactstrap'
import { toast } from 'sonner'

import MarkdownPost from '../../components/markdown-post/MarkdownPost'
import { MarkdownEditor } from '../markdown-editor/MarkdownEditor'
import { PostFileCard } from '../post-file-card/PostFileCard'
import { QuotedPost } from '../quoted-post/QuotedPost'
import { useInView } from '@/hooks/useInView'
import { useMarkPostsAsRead } from '@/hooks/useMarkPostsAsRead'

interface PostProps {
    post: PostItemDto
    meta: IThreadDetailDto
    onQuotePostClick: (post: PostItemDto) => void
}

const relativeTime = new RelativeTime()


export const Post = ({ post, meta, onQuotePostClick, ...rest }: PostProps) => {
    const auth = useAuth()

    const [isEditing, setIsEditing] = useState(false)
    const [isSubmittingEdit, setIsSubmittingEdit] = useState(false)
    const [canSubmitEdit, setCanSubmitEdit] = useState(false)
    const [editReason, setEditReason] = useState('')
    const [editedPost, setEditedPost] = useState(post.embedlessPost)

    const [ref, isInView] = useInView()

    useMarkPostsAsRead({ post, isInView, threadId: meta.id })

    // TODO: User capabilities hook
    const canEditPost = auth.isAuthenticated ? UserCanEditForumPost(auth.user, post) : false
    const canQuotePost = auth.isAuthenticated ? UserCanPostInThread(auth.user, meta) : false
    const isOwner = auth.isAuthenticated ? Boolean(post.postAuthorId === auth?.user.profile.sub) : false
    const canDeletePost = (auth.isAuthenticated ? UserIsForumAdmin(auth.user, meta.forumId) : false) || isOwner

    const generatePostURL = () => `${window.location.origin}/thread/${meta.id}#post-${post.id}`

    const onEditClick = () => setIsEditing(true)

    const onDeleteClick = (post: PostItemDto) => {
        alert('Not implemented')
        // onDelete(post)
    }

    const onQuoteClick = (e, post: PostItemDto) => {
        e.preventDefault()
        onQuotePostClick(post)
    }

    const history = useHistory()

    const submitEdit = async () => {
        try {
            setIsSubmittingEdit(true)
            const client = new ThreadPostsClient()
            const payload = {
                post: editedPost,
                postId: post.id,
                editReason,
                quotedPostId: post.quotedPostId,
            }
            const cmd = new UpdateThreadPostCommand(payload)
            await client.update(post.id, cmd)
            history.push(window.location) // refresh the page
        } catch (e) {
            console.error(e)
            toast.error('Failed to edit post')
        } finally {
            setIsSubmittingEdit(false)
        }
    }

    useEffect(() => {
        if (editReason === '' || editedPost === '' || editedPost === post.embedlessPost) {
            setCanSubmitEdit(false)
        } else {
            setCanSubmitEdit(true)
        }
    }, [editReason])

    return (
        <div ref={ref as any}>
            <Card {...rest} className='rounded-0' id={`post-${post.id}`}>
                <CardBody>
                    {!isEditing ? (
                        <>
                            <div className={cn('d-flex', 'flex-row', 'justify-content-between', 'align-items-center', 'mb-3')}>
                                <p className={cn(post.unread && 'text-bold', 'mb-0')}>
                                    Posted {relativeTime.from(post.created)} by {post.postAuthorDisplayName}
                                </p>
                                <div className='d-flex'>
                                    <CopyToClipboard text={generatePostURL()} onCopy={() => toast.success('Post URL copied to clipboard')}>
                                        <Button outline className='me-1 border-0'>
                                            Link to this post
                                        </Button>
                                    </CopyToClipboard>
                                    {canQuotePost && (
                                        <Button outline className='border-0 me-1' onClick={(e) => onQuoteClick(e, post)}>
                                            Quote
                                        </Button>
                                    )}
                                    {canEditPost && (
                                        <Button outline className='border-0' onClick={onEditClick}>
                                            Edit
                                        </Button>
                                    )}
                                    {canDeletePost && (
                                        <Button outline color='danger' className='border-0' onClick={() => onDeleteClick(post)}>
                                            Delete
                                        </Button>
                                    )}
                                </div>
                            </div>

                            <div>
                                {post.quotedPostId && (
                                    <div className='mb-3'>
                                        <QuotedPost postId={post.quotedPostId} />
                                    </div>
                                )}
                                <Card className='mb-3'>
                                    <CardBody>
                                        <MarkdownPost content={post.postContent} />
                                    </CardBody>
                                </Card>
                                {post.postFiles.filter((p) => !p.hidden && !p.deleted).length > 0 && (
                                    <>
                                        <div className='d-inline-flex'>
                                            {post.postFiles.map((file) => (
                                                <PostFileCard key={file.id} postFile={file} postId={post.id} />
                                            ))}
                                        </div>
                                    </>
                                )}
                            </div>

                            {post.editIteration >= 1 && post.editReason && (
                                <div>
                                    <p className='text-sm m-0'>
                                        <em>
                                            Edited {post.editIteration} times. Edit reason: {post.editReason}
                                        </em>
                                    </p>
                                </div>
                            )}
                        </>
                    ) : (
                        <>
                            <div className={cn('d-flex', 'flex-row', 'justify-content-end', 'mb-3')}>
                                <Button outline className='me-1 border-0' onClick={submitEdit} color='primary' disabled={!canSubmitEdit || isSubmittingEdit}>
                                    Save
                                </Button>
                                <Button outline className='me-1 border-0' onClick={() => setIsEditing(false)}>
                                    Cancel
                                </Button>
                            </div>

                            <div className='mb-3'>
                                <MarkdownEditor value={editedPost} onChange={(v) => setEditedPost(v)} />
                            </div>

                            <InputGroup className={cn('d-flex', 'align-items-center')}>
                                <Label for='editReason' className='me-3'>
                                    Reason for edit
                                </Label>
                                <Input id='editReason' value={editReason} onChange={(e) => setEditReason(e.target.value)} />
                            </InputGroup>
                        </>
                    )}
                </CardBody>
            </Card>
        </div>

    )
}

//     {(!props.editMode || (props.editMode && props.postToEdit != props.postItem.id)) && (
//         <>
//             <div className='post-header'>
//                 {props.postItem.unread && <span className='forum-unread-posts'>� </span>}
//                 <div className='post-author'>
//                     <span>{props.postItem.postAuthorDisplayName}</span>
//                 </div>
//                 {props.postItem.postAuthorBadgeUrl.length > 0 && (
//                     <div className='post-author-badge'>
//                         <span id='userBadge'>
//                             <img src={props.postItem.postAuthorBadgeUrl} />
//                         </span>
//                         <UncontrolledTooltip flip target='userBadge'>
//                             {props.postItem.postAuthorBadgeName}
//                         </UncontrolledTooltip>
//                     </div>
//                 )}
//                 <div className='post-num'>
//                     <HashLink to={`?page=${props.pageNumber}#postid-${props.postItem.id}`}>#{props.postItem.postNumber}</HashLink>
//                 </div>
//             </div>
//             {props.postItem.quotedPostId > 0 && <QuoteBox quotedPostId={props.postItem.quotedPostId} setQuotedPostId={null} editMode={false} />}
//             {props.isAdmin && (
//                 <ForumModeratorTools postId={props.postItem.id} setModPost={props.setModPost} locked={props.postItem.locked} approved={props.postItem.approved} />
//             )}
//             <figure>
//                 <MarkdownPost content={props.postItem.postContent} />
//                 <figcaption className='blockquote-footer'>
//                     Posted{' '}
//                     <span>
//                         <span id={'dateCreated' + props.postItem.id}>{relativeTime.from(new Date(props.postItem.created))}</span>
//                         <UncontrolledTooltip placement='bottom' target={'dateCreated' + props.postItem.id}>
//                             {new Intl.DateTimeFormat('default', { dateStyle: 'full', timeStyle: 'long' }).format(new Date(props.postItem.created))}
//                         </UncontrolledTooltip>
//                     </span>{' '}
//                     by{' '}
//                     {props.postItem.postAuthorUserName && (
//                         <NavLink to={`/user/${props.postItem.postAuthorUserName}`}>{props.postItem.postAuthorDisplayName}</NavLink>
//                     )}
//                     {!props.postItem.postAuthorUserName && props.postItem.postAuthorDisplayName}
//                 </figcaption>
//             </figure>
//             <div className='post-footer'>
//                 <div className='post-actions'>
//                     {/* <div className='post-num'>
//                                 <HashLink to={`#${s.id}`}>
//                                     <small>hash: #{s.postNumber}</small>
//                                 </HashLink>
//                             </div> */}
//                     {auth.user && auth.isAuthenticated && props.authorized && (
//                         <small>
//                             <a onClick={(e) => props.setQuotedPostId(props.postItem.id)} className='post-action'>
//                                 quote
//                             </a>
//                             <i className='bi bi-dot post-action-div'></i>
//                         </small>
//                     )}

//                     <p className='text-end'>
//                         <HashLink to={`?page=${props.pageNumber}#postid-${props.postItem.id}`} className='post-action text-right'>
//                             <small>Link to this comment</small>
//                         </HashLink>
//                     </p>

//                     {auth.user && auth.isAuthenticated && (auth.user.profile.sub === props.postItem.postAuthorId || props.isAdmin) && (
//                         <small>
//                             <i className='bi bi-dot post-action-div'></i>
//                             <a onClick={(e) => props.editPost(props.postItem.id, props.postItem.quotedPostId, props.postItem.postContent)} className='post-action'>
//                                 edit
//                             </a>
//                         </small>
//                     )}
//                 </div>
//             </div>
//         </>
//     )}
//     {auth.user && auth.isAuthenticated && props.editMode && props.postToEdit === props.postItem.id && (
//         <EditPostStation
//             clearEditMode={props.clearEditMode}
//             editedPost={props.editedPost}
//             editReason={props.editReason}
//             id={props.postItem.id}
//             postToQuote={props.postToQuote}
//             setPostToQuote={props.setPostToQuote}
//         />
//     )}
//     <PostFileDisplayer postFiles={props.postItem.postFiles} postId={props.postItem.id} />
// </Card>
