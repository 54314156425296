import * as React from 'react'
import { Button, Input, InputGroup, InputGroupText } from 'reactstrap'

interface EditReasonSubmitCancelProps {
    editReason: string
    editContent: string
    setEditReason: React.Dispatch<React.SetStateAction<string>>
    cancelEditMode: VoidFunction
}

export default (props: EditReasonSubmitCancelProps) => {
    return (
        <React.Fragment>
            <div>
                <InputGroup size='lg'>
                    <InputGroupText>Edit Reason</InputGroupText>
                    <Input onChange={(e) => props.setEditReason(e.target.value)} />
                    <Button color='primary'>Submit</Button>
                    <Button color='danger' onClick={(e) => props.cancelEditMode()}>
                        Cancel
                    </Button>
                </InputGroup>
            </div>
        </React.Fragment>
    )
}
