// Register `hName`, `hProperties` types, used when turning markdown to HTML:
/// <reference types="mdast-util-to-hast" />
// Register directive nodes in mdast:
/// <reference types="mdast-util-directive" />

import { visit } from 'unist-util-visit'

const colorMapping = new Map(
    Object.entries({
        red: '#ff4747',
        blue: '#3636ff',
        yellow: '#fff238',
        orange: '#ffa303',
        cyan: '#05deff',
        purple: '#dd02ff',
        violet: '#ae0cff',
        lime: '#2bff01',
        green: '#029e3b',
    })
)

// This plugin is an example to turn `::color` into divs, passing the color
export default function remarkColorDirectivePlugin() {
    /**
     * @param {import('mdast').Root} tree
     *   Tree.
     * @returns {undefined}
     *   Nothing.
     */
    return (tree: any) => {
        // @ts-ignore
        visit(tree, (node) => {
            if (
                node.type === 'containerDirective' ||
                node.type === 'leafDirective' ||
                node.type === 'textDirective'
            ) {
                const nodeName = String(node.name)
                if (!colorMapping.has(nodeName)) return

                const data = node.data || (node.data = {})
                const tagName = node.type === 'textDirective' ? 'span' : 'div'

                data.hName = tagName
                data.hProperties = { style: `color: ${colorMapping.get(nodeName)}`, }
            }
        })
    }
}