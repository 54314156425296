import * as React from 'react'
import { useContext, useEffect, useState } from 'react'
import { Button, Col, Form, FormGroup, Input, Label, Row } from 'reactstrap'

import { IErrorResponse } from '../../../../ErrorResponse'
import { AwardBadgeCommand, BadgesClient, IUserBadgeDto, IUserBadgeReportVm, IUserDto, RevokeBadgeCommand, SetPreferredBadgeCommand } from '../../../../lib/DoomCentral'
import { ErrorContext } from '../../../../state'

interface UserBadgesProps {
    selectedUser: IUserDto
    setModUser: React.Dispatch<React.SetStateAction<string>>
    userBadges: IUserBadgeDto[]
    availableBadges: IUserBadgeDto[]
    setHidden: React.Dispatch<React.SetStateAction<boolean>>
}

export default (props: UserBadgesProps) => {
    const error = useContext(ErrorContext)
    const [loading, setLoading] = useState<boolean>(false)
    const [userBadge, setUserBadge] = useState<IUserBadgeDto>(null)
    const [allBadge, setAllBadge] = useState<IUserBadgeDto>(null)

    useEffect(() => {
        if (props.userBadges && props.userBadges.length > 0) {
            setUserBadge(props.userBadges[0])
        } else {
            setUserBadge(null)
        }
    }, [props.userBadges])

    useEffect(() => {
        if (props.availableBadges && props.availableBadges.length > 0) {
            setAllBadge(props.availableBadges[0])
        } else {
            setAllBadge(null)
        }
    }, [props.availableBadges])

    const setPreferredBadge = async () => {
        setLoading(true)
        try {
            let client = new BadgesClient()
            const command = new SetPreferredBadgeCommand()
            command.badgeOwnerId = userBadge.id
            const response = await client.setPreferredBadge(command).then((response) => response as number)
            props.setModUser(props.selectedUser.id)
            props.setHidden(false)
            const timer = setTimeout(() => {
                props.setHidden(true)
            }, 5000)
            return () => {
                clearTimeout(timer)
                setLoading(false)
            }
        } catch (e) {
            error.setError(e.response as IErrorResponse)
        }
        setLoading(false)
    }

    const revokeBadge = async () => {
        setLoading(true)
        try {
            let client = new BadgesClient()
            const command = new RevokeBadgeCommand()
            command.badgeOwnerId = userBadge.id
            const response = await client.revoke(command).then((response) => response as number)
            props.setModUser(props.selectedUser.id)
            props.setHidden(false)
            const timer = setTimeout(() => {
                props.setHidden(true)
            }, 5000)
            return () => {
                clearTimeout(timer)
                setLoading(false)
            }
        } catch (e) {
            error.setError(e.response as IErrorResponse)
        }
        setLoading(false)
    }

    const awardBadge = async () => {
        setLoading(true)
        try {
            let client = new BadgesClient()
            const command = new AwardBadgeCommand()
            command.badgeId = allBadge.id
            command.badgeOwner = props.selectedUser.id
            const response = await client.award(command).then((response) => response as number)
            props.setModUser(props.selectedUser.id)
            props.setHidden(false)
            const timer = setTimeout(() => {
                props.setHidden(true)
            }, 5000)
            return () => {
                clearTimeout(timer)
                setLoading(false)
            }
        } catch (e) {
            error.setError(e.response as IErrorResponse)
        }
        setLoading(false)
    }

    return (
        <React.Fragment>
            {props.selectedUser && (
                <React.Fragment>
                    <Form>
                        <Row>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for='userbadgeselect'>User Badges</Label>
                                    <Input
                                        type='select'
                                        value={userBadge?.id ?? 0}
                                        name='userbadgeselect'
                                        id='userbadgeselect'
                                        onChange={(e) => setUserBadge(props.userBadges.find((f) => f.id === parseInt(e.currentTarget.value)) ?? props.userBadges[0])}
                                        disabled={loading || !props.userBadges || props.userBadges.length <= 0}
                                    >
                                        {props.userBadges &&
                                            props.userBadges.map((s, idx) => (
                                                <option value={s.id} key={s.id}>
                                                    {s.badgeName} ({s.badgeFileName})
                                                </option>
                                            ))}
                                    </Input>
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for='allbadgeselect'>All Badges</Label>
                                    <Input
                                        type='select'
                                        value={allBadge?.id ?? 0}
                                        name='allbadgeselect'
                                        id='allbadgeselect'
                                        onChange={(e) => setAllBadge(props.availableBadges.find((f) => f.id === parseInt(e.currentTarget.value)) ?? props.availableBadges[0])}
                                        disabled={loading || !props.availableBadges || props.availableBadges.length <= 0}
                                    >
                                        {props.availableBadges &&
                                            props.availableBadges.map((s, idx) => (
                                                <option value={s.id} key={s.id}>
                                                    {s.badgeName} ({s.badgeFileName})
                                                </option>
                                            ))}
                                    </Input>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                                <FormGroup>{userBadge && <img src={'/images/badges/' + userBadge.badgeFileName} />}</FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>{allBadge && <img src={'/images/badges/' + allBadge.badgeFileName} />}</FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                                <FormGroup>
                                    <Button onClick={setPreferredBadge} color='primary' disabled={loading || !props.userBadges || props.userBadges.length <= 0} className='my-2'>
                                        Set Preferred Badge
                                    </Button>
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Button onClick={awardBadge} disabled={loading || !props.availableBadges || props.availableBadges.length <= 0} className='my-2'>
                                        Award User Badge
                                    </Button>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                                <FormGroup>
                                    <Button onClick={revokeBadge} color='danger' disabled={loading || !props.userBadges || props.userBadges.length <= 0} className='my-2'>
                                        Remove Badge from User
                                    </Button>
                                </FormGroup>
                            </Col>
                            <Col md={6}></Col>
                        </Row>
                    </Form>
                </React.Fragment>
            )}
        </React.Fragment>
    )
}
