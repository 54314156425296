import { containsDuplicate } from '@/utils/helpers'
import { useEffect, useState } from 'react'
import React from 'react'
import { useAuth } from 'react-oidc-context'
import { Link } from 'react-router-dom'
import {
    Navbar as BSNavbar,
    Button,
    Collapse,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Nav,
    NavItem,
    NavLink,
    NavbarBrand,
    NavbarToggler,
    UncontrolledDropdown,
} from 'reactstrap'

import DoomCentralEventRoles from '../../lib/DoomCentralEventRoles'
import DoomCentralRoles from '../../lib/DoomCentralRoles'
import ForumRoles from '../../lib/ForumRoles'
import NewsPostRoles from '../../lib/NewsPostRoles'
import { redirectToProfile, redirectToRegister } from '../api-authorization/AuthHelpers'
import Notifications from '../notifications/Notifications'
import { ThemeDropdown } from '../theme-dropdown/ThemeDropdown'

const FORUM_LINKS = [
    {
        name: 'Threads',
        url: '/threads',
        order: 5,
    },
    {
        name: 'Forums',
        url: '/forums',
        order: 10,
    },
    {
        name: 'Events',
        url: '/events',
        order: 15,
    },
    {
        name: 'News',
        url: '/news',
        order: 20,
    },
].sort((a, b) => a.order - b.order)

export const Navbar = () => {
    const [isOpen, setIsOpen] = useState(false)

    const auth = useAuth()
    const adminMenuRoles = [
        DoomCentralRoles.GlobalAdministrator,
        DoomCentralRoles.PromotionAdministrator,
        ForumRoles.GlobalForumAdministrator,
        DoomCentralEventRoles.GlobalEventAdministrator,
        NewsPostRoles.GlobalNewsAdministrator,
    ]

    const canPromote =
        auth.user &&
        (auth.user?.profile.role as string[]) &&
        containsDuplicate([DoomCentralRoles.PromotionAdministrator, DoomCentralRoles.GlobalAdministrator], auth.user?.profile.role as string[])

    const toggle = () => {
        setIsOpen(!isOpen)
    }

    useEffect(() => {
        return auth.events.addAccessTokenExpiring(() => {
            auth.signinSilent()
        })
    }, [auth.events, auth.signinSilent])

    useEffect(() => {
        return auth.events.addAccessTokenExpired(() => {
            auth.signinSilent()
        })
    }, [auth.events, auth.signinSilent])

    return (
        <BSNavbar className='navbar navbar-expand-sm sticky-top'>
            <NavbarBrand tag={Link} to='/' aria-label='Doom Central'>
                <img src='/images/doomcentrallogo.png' height='64' width='64' className='img-fluid' alt='Doom Central' />
            </NavbarBrand>
            <NavbarToggler onClick={toggle} className='mr-2' />
            <Collapse isOpen={isOpen} navbar>
                <Nav navbar>
                    {FORUM_LINKS.map((link) => (
                        <NavItem key={link.url} className='nav-item'>
                            <NavLink tag={Link} to={link.url} className='btn btn-link'>
                                {link.name}
                            </NavLink>
                        </NavItem>
                    ))}
                </Nav>

                <Nav navbar className='ms-auto'>
                    {auth.isAuthenticated ? (
                        <>
                            <UncontrolledDropdown nav inNavbar>
                                <DropdownToggle nav caret>
                                    Hello {auth.user?.profile.preferred_username}
                                </DropdownToggle>
                                <DropdownMenu end>
                                    <DropdownItem>
                                        <NavItem className='ml-auto'>
                                            <NavLink tag={Link} to={'#profile'} onClick={() => redirectToProfile()}>
                                                View Profile
                                            </NavLink>
                                        </NavItem>
                                    </DropdownItem>
                                    <DropdownItem>
                                        <NavItem className='ml-auto'>
                                            <NavLink tag={Link} to={'/managesubscriptions'}>
                                                Manage Subscriptions
                                            </NavLink>
                                        </NavItem>
                                    </DropdownItem>
                                    <DropdownItem divider />

                                    {canPromote && (
                                        <DropdownItem>
                                            <NavItem className='ml-auto'>
                                                <NavLink tag={Link} to={'/editpromotions'}>
                                                    Edit Promotions
                                                </NavLink>
                                            </NavItem>
                                        </DropdownItem>
                                    )}

                                    {containsDuplicate(adminMenuRoles, auth.user?.profile.role as string[]) && (
                                        <React.Fragment>
                                            <DropdownItem>
                                                <NavItem className='ml-auto'>
                                                    <NavLink tag={Link} to={'/admin'}>
                                                        Admin Panel
                                                    </NavLink>
                                                </NavItem>
                                            </DropdownItem>
                                            <DropdownItem divider />
                                        </React.Fragment>
                                    )}
                                    <DropdownItem>
                                        <NavItem className='ml-auto'>
                                            <NavLink tag={Link} to="#logout" onClick={() => auth.signoutRedirect()}>
                                                Logout
                                            </NavLink>
                                        </NavItem>
                                    </DropdownItem>
                                </DropdownMenu>
                            </UncontrolledDropdown>
                        </>
                    ) : (
                        <>
                            <li key='nav-item'>
                                <Link to='#register' className='btn btn-link' onClick={() => redirectToRegister()}>
                                    Register
                                </Link>
                            </li>
                            <li className='nav-item'>
                                <Link to='#login' className='btn btn-link' onClick={() => auth.signinRedirect()}>
                                    Login
                                </Link>
                            </li>
                        </>
                    )}
                    <ThemeDropdown className='ms-3' />
                    {auth.isAuthenticated && <Notifications className='ms-3' />}
                </Nav>
            </Collapse>
        </BSNavbar>
    )
}
