import { X } from 'lucide-react'
import { useContext, useEffect, useState } from 'react'
import { Button, Toast } from 'reactstrap'

import { ErrorContext } from '../state'

const ErrorMessage = () => {
    const error = useContext(ErrorContext)
    const [hidden, setHidden] = useState<boolean>(true)

    useEffect(() => {
        setHidden(false)
        const timer = setTimeout(() => {
            setHidden(true)
        }, 5000)
        return () => {
            clearTimeout(timer)
        }
    }, [error])

    return (
        <>
            {error.error !== null &&
                !(Object.keys(error.error).length === 0 && error.constructor === Object) && ( // javascript checking for empty object...
                    <Toast isOpen={!hidden} className='position-fixed top-0 start-50 translate-middle-x mt-5 text-bg-danger' color='danger'>
                        <div className='d-flex'>
                            <div className='toast-body w-100'>
                                {error.error.title}
                                HTTP Status: {error.error.status}
                                {error.error.errors !== undefined &&
                                    Object.values(error.error.errors).map((value) => (
                                        <p>
                                            <>{value}</>
                                        </p>
                                    ))}
                                {error.error.detail && <p>{error.error.detail}</p>}
                            </div>
                            <Button size='sm' color='danger' onClick={() => setHidden(true)}>
                                <X /> <span className='visually-hidden'>Dismiss toast</span>
                            </Button>
                        </div>
                    </Toast>
                )}
        </>
    )
}

export default ErrorMessage
