import { MarkThreadPostsAsReadCommand, ThreadsClient } from "@/lib/DoomCentral"

export type markPostAsReadArgs = {
    highestReadPostId: number;
    threadId: number;
}

export const markPostAsRead = async ({ highestReadPostId, threadId }) => {
    const client = new ThreadsClient()
    const cmd = new MarkThreadPostsAsReadCommand({ threadId, highestReadThreadPost: highestReadPostId })
    return await client.markAsRead(cmd)
}