import { FormatUTCISOToLocalISO } from '@/utils/helpers'
import * as React from 'react'
import { useContext, useEffect, useState } from 'react'
import { Col, Row } from 'reactstrap'

import { IErrorResponse } from '../../ErrorResponse'
import useQueryParam from '../../hooks/useQueryParam'
import { DoomCentralEventsClient, EventRegion, IRawEventPostVm, ISidebarVm, SidebarClient } from '../../lib/DoomCentral'
import { ErrorContext } from '../../state'
import EventEditorBoxContainer from '../eventeditor/EventEditorBoxContainer'
import EventEditorEndDate from '../eventeditor/EventEditorEndDate'
import EventEditorPublish from '../eventeditor/EventEditorPublish'
import EventEditorRegionOptions from '../eventeditor/EventEditorRegionOptions'
import EventEditorStartDate from '../eventeditor/EventEditorStartDate'
import EventEditorStreamer from '../eventeditor/EventEditorStreamer'
import EventEditorTitle from '../eventeditor/EventEditorTitle'
import HelmetPages from '../helmet/HelmetPages'
import Sidebars from '../sidebars/Sidebars'
import SubmitEditEventButton from './SubmitEditEventButton'

export default () => {
    const error = useContext(ErrorContext)

    const [loading, setLoading] = useState<boolean>(false)

    const [region, setRegion] = useState<EventRegion>(EventRegion.Global)
    const [title, setTitle] = useState<string>('')
    const [post, setPost] = useState<string>('')
    const [streamerId, setStreamerId] = useState<number>(0)
    const [sidebarData, setSidebarData] = useState<ISidebarVm>()
    const [start, setStart] = useState<string>('')
    const [end, setEnd] = useState<string>('')
    const [publish, setPublish] = useState<boolean>(false)

    const [eventId, setEventId] = useQueryParam('eventId', '')

    // Initial load for the thread page.
    useEffect(() => {
        const fetchData = async () => {
            setLoading(true)
            try {
                let client = new SidebarClient()
                const response = await client.getSidebars().then((response) => response.toJSON() as Promise<ISidebarVm>)
                setSidebarData(response)
            } catch (e) {
                error.setError(e.response as IErrorResponse)
            }
            setLoading(false)
        }

        fetchData()
    }, [])

    // Load details for every change to eventId
    useEffect(() => {
        const fetchData = async () => {
            setLoading(true)
            try {
                if (parseInt(eventId ?? '0') > 0) {
                    let client = new DoomCentralEventsClient()
                    const response = await client.getRawEventDataById(parseInt(eventId ?? '0')).then((response) => response.toJSON() as Promise<IRawEventPostVm>)
                    setPost(response.eventDetails.content)
                    setTitle(response.eventDetails.title)
                    setPublish(response.eventDetails.published)
                    setRegion(response.eventDetails.eventRegion)
                    setStreamerId(response.eventDetails.streamerId)
                    setStart(FormatUTCISOToLocalISO(new Date(response.eventDetails.eventStartDate)))
                    setEnd(FormatUTCISOToLocalISO(new Date(response.eventDetails.eventEndDate)))
                }
            } catch (e) {
                error.setError(e.response as IErrorResponse)
            }
            setLoading(false)
        }

        fetchData()
    }, [eventId])

    return (
        <React.Fragment>
            <HelmetPages title='Doom Central - Edit Event' />
            <Sidebars loading={loading} newsItems={sidebarData?.newsItems} recentThreads={sidebarData?.recentThreads}>
                <div className='maincontent-container p-4'>
                    <Row>
                        <Col lg={8}>
                            <EventEditorRegionOptions region={region} setRegion={setRegion} />
                            <EventEditorTitle title={title} setTitle={setTitle} />
                            <EventEditorStartDate startDate={start} setStartDate={setStart} />
                            <EventEditorEndDate endDate={end} setEndDate={setEnd} />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <EventEditorBoxContainer post={post} setPost={setPost} />
                            <EventEditorStreamer streamerId={streamerId} setStreamerId={setStreamerId} />
                            <EventEditorPublish publish={publish} setPublish={setPublish} />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <SubmitEditEventButton
                                eventId={parseInt(eventId ?? '0')}
                                loading={loading}
                                setLoading={setLoading}
                                eventTitle={title}
                                post={post}
                                publish={publish}
                                streamerId={streamerId}
                                region={region}
                                start={start}
                                end={end}
                            />
                        </Col>
                    </Row>
                </div>
            </Sidebars>
        </React.Fragment>
    )
}
