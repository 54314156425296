import { ApprovedIcon, HiddenIcon, LockedIcon, ModeratedIcon, StickiedIcon } from '@/components/icons/Icons'
import { useThreadMeta } from '@/hooks/useThreadMeta'
import { formatBytes } from '@/utils/helpers'
import RelativeTime from '@yaireo/relative-time'
import { NavLink } from 'react-router-dom'
import { Badge, Col, Row } from 'reactstrap'

const relativeTime = new RelativeTime()

export type ThreadHeaderProps = {
    id: number
}

export const ThreadHeader = ({ id }: ThreadHeaderProps) => {
    const { isLoading, isError, data } = useThreadMeta({ id })

    // don't need to show loading indicator here
    if (isLoading) return

    if (isError) {
        return <div>There was an error getting the thread header.</div>
    }

    const { approved, forumId, forumName, title, moderated, hidden, stickied, locked, allowedFileExtensions, maxFileSize, maxFilesPerPost, threadDate, threadAuthorDisplayName } =
        data

    return (
        <div className='mb-3'>
            <Row>
                <>
                    <Col xs={12} md={9} className='align-content-center'>
                        <h1>{title}</h1>
                        <p>
                            Posted {relativeTime.from(threadDate)} by {threadAuthorDisplayName} in <NavLink to={`/forum/${forumId}`}>{forumName}</NavLink>
                        </p>

                        {approved && (
                            <Badge className='me-1' color='success'>
                                <ApprovedIcon className={'me-1'} style={{ width: '1rem' }} />
                                Approved
                            </Badge>
                        )}
                        {stickied && (
                            <Badge className='me-1' color='primary'>
                                <StickiedIcon className={'me-1'} style={{ width: '1rem' }} />
                                Stickied
                            </Badge>
                        )}
                        {locked && (
                            <Badge className='me-1' color='warning'>
                                <LockedIcon className={'me-1'} style={{ width: '1rem' }} />
                                Locked
                            </Badge>
                        )}
                        {moderated && (
                            <Badge className='me-1' color='warning'>
                                <ModeratedIcon className={'me-1'} style={{ width: '1rem' }} />
                                Moderated
                            </Badge>
                        )}
                        {hidden && (
                            <Badge className='me-1' color='secondary'>
                                <HiddenIcon className={'me-1'} style={{ width: '1rem' }} />
                                Hidden
                            </Badge>
                        )}
                    </Col>
                    {allowedFileExtensions && (
                        <Col xs={12} md={3} className='d-flex flex-column align-content-center'>
                            <Badge style={{ maxWidth: 200 }} className='mb-1'>
                                <small>Max files per post: {maxFilesPerPost}</small>
                            </Badge>
                            <Badge style={{ maxWidth: 200 }} className='mb-1'>
                                <small>Max upload file size: {formatBytes(maxFileSize)}</small>
                            </Badge>
                            <Badge style={{ maxWidth: 200 }} className='mb-1'>
                                <small>Allowed extensions: {allowedFileExtensions}</small>
                            </Badge>
                        </Col>
                    )}
                </>
            </Row>
        </div>
    )
}
