import * as React from 'react'
import { FormGroup, FormText, Input, Label } from 'reactstrap'

interface EventEditorPublishProps {
    publish: boolean
    setPublish: React.Dispatch<React.SetStateAction<boolean>>
}

export default (props: EventEditorPublishProps) => {
    return (
        <React.Fragment>
            <FormGroup>
                <Label for='publish'>
                    <Input id='publish' name='publish' type='checkbox' checked={props.publish} onChange={(e) => props.setPublish(e.target.checked)} /> Publish this event. This will
                    make this event viewable to users.
                </Label>
            </FormGroup>
        </React.Fragment>
    )
}
