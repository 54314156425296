import { routeForResourceType } from '@/utils/helpers'
import { cn } from '@/utils/utils'
import { MouseEvent, useEffect, useRef, useState } from 'react'
import { Link, NavLink, useHistory } from 'react-router-dom'
import { Card, CardBody, CardImg, CardSubtitle, CardText, CardTitle, Col, Row } from 'reactstrap'

import { IPromotedItemDto, PromotedType } from '../../lib/DoomCentral'
import { LoadingIndicator } from '../loading-indicator/LoadingIndicator'
import './promoted.scss'

interface PromotedProps {
    promotedItems: IPromotedItemDto[]
    loading: boolean
}

export const Promoted = ({ promotedItems, loading }: PromotedProps) => {
    const buildPromoUrl = (type: PromotedType, id: string | number) => `${routeForResourceType(type)}/${id}`

    const [bigPromo, ...promos] = promotedItems

    return (
        <div className='mb-3'>
            {loading ? (
                <LoadingIndicator />
            ) : (
                <Row className='g-0'>
                    <Col lg={12} xl={8}>
                        {bigPromo && (
                            <Link key={bigPromo.resourceId} to={buildPromoUrl(bigPromo.promotedType, bigPromo.resourceId)} style={{ textDecoration: 'none', color: 'inherit' }}>
                                <div className='card promo-card p-0 me-3 h-100' style={{ maxHeight: 1080, maxWidth: 1920 }}>
                                    <img
                                        src={bigPromo.promotedImageUrl}
                                        className='card-img'
                                        alt={`Promo image for event: ${bigPromo.promotedTitle}`}
                                        style={{ objectFit: 'cover', width: '100%', height: '100%' }}
                                    />

                                    <div className='card-img-overlay d-flex flex-column justify-content-end'>
                                        <div className={cn('p-3', 'bg-primary')}>
                                            <h1 className='card-title fs-3'>
                                                <strong>{bigPromo.promotedTitle}</strong>
                                            </h1>
                                            <p className='card-text text-body'>
                                                <strong>{bigPromo.promotedBlurb}</strong>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        )}
                    </Col>
                    <Col lg={6} xl={4}>
                        {promos.map(({ promotedType, resourceId, promotedTitle, promotedImageUrl, promotedBlurb }) => (
                            <Link key={resourceId} to={buildPromoUrl(promotedType, resourceId)} style={{ textDecoration: 'none', color: 'inherit' }}>
                                <div className='card promo-card p-0 me-3 h-100' style={{ maxHeight: 300, maxWidth: 400 }}>
                                    <img
                                        src={promotedImageUrl}
                                        className='card-img'
                                        alt={`Promo image for event: ${promotedTitle}`}
                                        style={{ objectFit: 'cover', width: '100%', height: '100%' }}
                                    />

                                    <div className='card-img-overlay d-flex flex-column justify-content-end'>
                                        <div className={cn('p-3', 'bg-secondary')}>
                                            <h2 className='card-title fs-3'>
                                                <strong>{promotedTitle}</strong>
                                            </h2>
                                            <p className='card-text text-body'>
                                                <strong>{promotedBlurb}</strong>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        ))}
                    </Col>
                </Row>
            )}
        </div>
    )
}
