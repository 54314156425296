import { formatBytes } from '@/utils/helpers'
import * as React from 'react'
import { useCallback, useEffect, useState } from 'react'
import { FileError, FileRejection, useDropzone } from 'react-dropzone'
import styled from 'styled-components'

interface PostFileDropzoneProps {
    allowedFileExtensions: string
    maxFileSizeBytes: number
    maxFilesInPost: number
    currentFiles: number
    uploadedFiles: File[]
    setUploadedFiles: React.Dispatch<React.SetStateAction<File[]>>
    fileRejects: FileRejection[]
    setFileRejects: React.Dispatch<React.SetStateAction<FileRejection[]>>
}

export default (props: PostFileDropzoneProps) => {
    const onDrop = useCallback(
        (acceptedFiles: File[], fileRejections: FileRejection[]) => {
            var validatedFiles = [] as File[]
            var newFileErrors = [] as FileRejection[]
            // Validate the files after drop because we don't trust react-dropzone.
            acceptedFiles.map((s, idx) => {
                var combined = props.uploadedFiles.concat(validatedFiles)
                var res = validatePostFile(s, combined)

                if (res === null) {
                    validatedFiles = [...validatedFiles, s]
                } else {
                    newFileErrors = [...newFileErrors, res]
                }
            })

            fileRejections.map((s, idx) => {
                newFileErrors = [...newFileErrors, s]
            })

            props.setFileRejects([...props.fileRejects, ...newFileErrors])
            props.setUploadedFiles([...props.uploadedFiles, ...validatedFiles])
        },
        [props.uploadedFiles, props.fileRejects],
    )

    const fileAmountValidator = (files: File[]): FileError => {
        if (files.length + 1 > props.maxFilesInPost) {
            return {
                code: 'too-many-files',
                message: `This file you tried to upload exceeds the max limit of ${props.maxFilesInPost} files per post.`,
            }
        }

        return null
    }

    const sameFileNameCheckValidator = (file: File, files: File[]): FileError => {
        if (files.some((s) => s.name === file.name)) {
            return {
                code: 'same-file-name',
                message: `This file has the same file name as another file being prepared to be uploaded.`,
            }
        }

        return null
    }

    const validateFileName = (fileName: string): boolean => {
        var extensionList: string[] = props.allowedFileExtensions.split('/')

        if (extensionList.some((s) => fileName.endsWith(s))) {
            return true
        }

        return false
    }

    const fileSizeValidator = (file: File): FileError => {
        if (file.size > props.maxFileSizeBytes) {
            return {
                code: 'file-too-large',
                message: `File size is larger than ${props.maxFileSizeBytes} bytes.`,
            }
        }

        return null
    }

    const fileExtensionValidator = (file: File): FileError => {
        if (!validateFileName(file.name)) {
            return {
                code: 'invalid-file',
                message: `You tried to upload a file type not allowed here! Allowed file types are: ${props.allowedFileExtensions.replaceAll('/', ', ')}`,
            }
        }

        return null
    }

    const validatePostFile = (file: File, fileList: File[]): FileRejection => {
        var sizeCheck = fileSizeValidator(file)
        if (sizeCheck != null) {
            return {
                file: file,
                errors: [sizeCheck],
            } as FileRejection
        }

        var extensionCheck = fileExtensionValidator(file)
        if (extensionCheck != null) {
            return {
                file: file,
                errors: [extensionCheck],
            } as FileRejection
        }

        var sameFileNameCheck = sameFileNameCheckValidator(file, fileList)
        if (sameFileNameCheck != null) {
            return {
                file: file,
                errors: [sameFileNameCheck],
            } as FileRejection
        }

        var fileAmountCheck = fileAmountValidator(fileList)
        if (fileAmountCheck != null) {
            return {
                file: file,
                errors: [fileAmountCheck],
            } as FileRejection
        }

        return null
    }

    // We don't validate drag/drop file extensions because browsers are weird with that API.
    // The file API is much easier to determine file extensions, so we move validation up there.
    const { getRootProps, getInputProps, fileRejections } = useDropzone({
        onDrop,
        //accept: {} // we check this further up since browser APIs are so different on this
        validator: fileSizeValidator,
    })

    return (
        <React.Fragment>
            {props.allowedFileExtensions.length > 0 && props.maxFilesInPost > 0 && props.maxFileSizeBytes > 0 && (
                <PostFileContainer {...getRootProps()}>
                    <input {...getInputProps()} accept={props.allowedFileExtensions.replaceAll('/', ',')} />
                    <PostFileContainerText>Drag 'n' drop some files here, or click to select files.</PostFileContainerText>
                    <PostFileContainerText>Accepted file types: {props.allowedFileExtensions.replaceAll('/', ', ')}</PostFileContainerText>
                    <PostFileContainerText>Accepted file size: {formatBytes(props.maxFileSizeBytes)}</PostFileContainerText>
                    <PostFileContainerText>Maximum files per post: {props.maxFilesInPost}</PostFileContainerText>
                </PostFileContainer>
            )}
            {(props.allowedFileExtensions.length <= 0 || props.maxFilesInPost <= 0 || props.maxFileSizeBytes <= 0) && (
                <PostFileContainer>
                    <PostFileContainerText>File uploads are disabled here.</PostFileContainerText>
                </PostFileContainer>
            )}
        </React.Fragment>
    )
}

const PostFileContainer = styled.div`
    padding: 7px 10px;
    margin: 0;
    font-size: 13px;
    line-height: 16px;
    color: var(--bs-secondary-text-emphasis);
    background-color: var(--bs-secondary-bg);
    border: 1px solid var(--bs-secondary-color);
    border-top: 0;
    border-top-color: currentcolor;
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
`

const PostFileContainerText = styled.p`
    margin: 0;
`
