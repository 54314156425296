import * as React from 'react'
import { Switch } from 'react-router'

import DoomCentralEventRoles from '../../lib/DoomCentralEventRoles'
import DoomCentralRoles from '../../lib/DoomCentralRoles'
import ForumRoles from '../../lib/ForumRoles'
import NewsPostRoles from '../../lib/NewsPostRoles'
import StreamerRoles from '../../lib/StreamerRoles'
import AdminHome from '../admin/AdminHome'
import ManageBadges from '../admin/managebadges/ManageBadges'
import ManageEmotes from '../admin/manageemotes/ManageEmotes'
import ManageEvents from '../admin/manageevents/ManageEvents'
import ManageForums from '../admin/manageforum/ManageForums'
import ManageForumPermissionGroups from '../admin/manageforumpermissiongroups/ManageForumPermissionGroups'
import ManageNews from '../admin/managenews/ManageNews'
import ManageReactions from '../admin/managereactions/ManageReactions'
import ManageStreamer from '../admin/managestreamers/ManageStreamer'
import ManageUsers from '../admin/manageusers/ManageUsers'
import AuthorizeRoute from '../api-authorization/AuthorizeRoute'

export default () => (
    <React.Fragment>
        <Switch>
            <AuthorizeRoute
                exact
                path='/admin'
                component={AdminHome}
                componentroles={[
                    DoomCentralRoles.GlobalAdministrator,
                    DoomCentralRoles.PromotionAdministrator,
                    ForumRoles.GlobalForumAdministrator,
                    DoomCentralEventRoles.GlobalEventAdministrator,
                    NewsPostRoles.GlobalNewsAdministrator,
                ]}
            />
            <AuthorizeRoute path='/admin/manageforums' component={ManageForums} componentroles={[DoomCentralRoles.GlobalAdministrator, ForumRoles.GlobalForumAdministrator]} />
            <AuthorizeRoute
                path='/admin/manageforumpermissiongroups'
                component={ManageForumPermissionGroups}
                componentroles={[DoomCentralRoles.GlobalAdministrator, ForumRoles.GlobalForumAdministrator]}
            />
            <AuthorizeRoute
                path='/admin/manageusers'
                component={ManageUsers}
                componentroles={[
                    DoomCentralRoles.GlobalAdministrator,
                    ForumRoles.GlobalForumAdministrator,
                    DoomCentralEventRoles.GlobalEventAdministrator,
                    NewsPostRoles.GlobalNewsAdministrator,
                ]}
            />
            <AuthorizeRoute
                path='/admin/manageemotes'
                component={ManageEmotes}
                componentroles={[
                    DoomCentralRoles.GlobalAdministrator,
                    ForumRoles.GlobalForumAdministrator,
                    DoomCentralEventRoles.GlobalEventAdministrator,
                    NewsPostRoles.GlobalNewsAdministrator,
                ]}
            />
            <AuthorizeRoute
                path='/admin/managebadges'
                component={ManageBadges}
                componentroles={[
                    DoomCentralRoles.GlobalAdministrator,
                    ForumRoles.GlobalForumAdministrator,
                    DoomCentralEventRoles.GlobalEventAdministrator,
                    NewsPostRoles.GlobalNewsAdministrator,
                ]}
            />
            <AuthorizeRoute
                path='/admin/managereactions'
                component={ManageReactions}
                componentroles={[
                    DoomCentralRoles.GlobalAdministrator,
                    ForumRoles.GlobalForumAdministrator,
                    DoomCentralEventRoles.GlobalEventAdministrator,
                    NewsPostRoles.GlobalNewsAdministrator,
                ]}
            />
            <AuthorizeRoute
                path='/admin/manageevents'
                component={ManageEvents}
                componentroles={[DoomCentralRoles.GlobalAdministrator, DoomCentralEventRoles.GlobalEventAdministrator]}
            />
            <AuthorizeRoute path='/admin/managenews' component={ManageNews} componentroles={[DoomCentralRoles.GlobalAdministrator, NewsPostRoles.GlobalNewsAdministrator]} />
            <AuthorizeRoute path='/admin/managestreamers' component={ManageStreamer} componentroles={[DoomCentralRoles.GlobalAdministrator, StreamerRoles.StreamAdministrator]} />
        </Switch>
    </React.Fragment>
)
