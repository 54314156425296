import { getUserUnreadPosts } from '@/api/getUserUnreadPosts'
import { Columns } from '@/components/columns/Columns'
import { CommunityNewsCard } from '@/components/community-news-card/CommunityNewsCard'
import { EventsCard } from '@/components/events-card/EventsCard'
import { ApprovedIcon, HotIcon, LockedIcon, ModeratedIcon, StickiedIcon } from '@/components/icons/Icons'
import { LoadingIndicator } from '@/components/loading-indicator/LoadingIndicator'
import { RecentThreadsCard } from '@/components/recent-threads-card/RecentThreadsCard'
import { useCapabilities } from '@/hooks/useCapabilities'
import { useThreads } from '@/hooks/useThreads'
import { cn } from '@/utils/utils'
import RelativeTime from '@yaireo/relative-time'
import { NavLink, useHistory, useParams } from 'react-router-dom'
import { Badge, Button, Card, CardBody, ListGroup, ListGroupItem } from 'reactstrap'
import { toast } from 'sonner'

const relativeTime = new RelativeTime()

export const ForumPage = () => {

    const { id } = useParams<{ id: string }>()

    if (!id) {
        toast.error('Invalid forum id')
        // TODO: Should probably redirect here
    }

    const history = useHistory()

    const { data, isLoading, isError, isFetching } = useThreads({ id })

    const { isGlobalForumAdmin, isGlobalAdmin, isAuthenticated } = useCapabilities()

    if (isLoading || isFetching) {
        return <LoadingIndicator />
    }

    if (isError) {
        toast.error('Error fetching threads')
        return <div>There was an error fetching threads.</div>
    }

    const jumpToLastUnread = async (threadId: number) => {
        const unreadPosts = await getUserUnreadPosts({threadId})
        if (unreadPosts.length === 0) {
            toast.error('You have 0 unread posts')
            return
        }
        const highestId = unreadPosts.reduce((max, obj) => obj.id > max ? obj.id : max, unreadPosts[0].id);
        const redirectUrl = `/thread/${threadId}#post-${highestId}`
        history.push(redirectUrl)
    }

    return (
        <>
            <Columns
                rightColumn={
                    <div>
                        {(isGlobalForumAdmin || isGlobalAdmin) && (
                            <Card className='mb-3'>
                                <CardBody>
                                    <div>
                                        <h5>Forum mod tools</h5>
                                        <p>
                                            <strong>Coming Soon</strong>
                                        </p>
                                    </div>
                                </CardBody>
                            </Card>
                        )}
                        <CommunityNewsCard className='mb-3' />
                        <EventsCard className='mb-3' />
                        <RecentThreadsCard className='mb-3' />
                    </div>
                }
            >
                <Card>
                    <CardBody>
                        <h1 className={cn(data.forumMeta.description ? 'mb-3' : 'mb-4')}>{data.forumMeta.name}</h1>
                        {data.forumMeta.description && <h4 className='mb-4 fs-5'>{data.forumMeta.description}</h4>}
                        <div>
                            {data.threads.length === 0 ? (
                                <div>
                                    <p>There are no threads in this forum.</p>
                                    <Button color='primary' onClick={() => history.push(`/newthread?forumid=${id}`)}>
                                        Create Thread
                                    </Button>
                                </div>
                            ) : (
                                <div>
                                    <ListGroup>
                                        {data.threads.map(({ id: threadId, title, unread, unreadPosts, hot, locked, moderated, approved, dcEvent, dcForum, dcNews, stickied, highestPostId }) => (
                                            <ListGroupItem key={threadId} className={cn('d-flex', 'flex-row', 'justify-content-between')}>
                                                <div className={cn("d-inline-flex", 'align-items-center')}>
                                                    {stickied && <StickiedIcon className='mb-1' />}
                                                    {dcEvent && <span>DC EVENT</span>}
                                                    {dcNews && <span>DC NEWS</span>}
                                                    {hot && <HotIcon className='mb-1' />}
                                                    {locked && <LockedIcon className='mb-1' />}
                                                    {moderated && <ModeratedIcon className='mb-1' />}
                                                    {approved && <ApprovedIcon className='mb-1' />}
                                                    <h4 className='ms-2'>
                                                        <NavLink to={`/thread/${threadId}`}>{title}</NavLink>
                                                    </h4>
                                                </div>
                                                {isAuthenticated && <div>
                                                    {unreadPosts > 0 
                                                        ? (
                                                        <Button onClick={() => jumpToLastUnread(threadId)} color="link">
                                                            <span className="me-1">
                                                                Jump to last unread
                                                            </span>
                                                            <Badge className="ms-1" pill color={unreadPosts > 0 ? 'primary' : 'secondary'}>
                                                                {unreadPosts} Unread
                                                            </Badge>
                                                        </Button>
                                                        ): (
                                                            <Button color="link" onClick={() => history.push(`/thread/${threadId}?page=1&pageSize=50#post-${highestPostId}`)}>Jump to last post</Button>
                                                        )}
                                                    </div>}
                                            </ListGroupItem>
                                        ))}
                                    </ListGroup>
                                </div>
                            )}
                        </div>
                    </CardBody>
                </Card>
            </Columns>
        </>
    )
}
