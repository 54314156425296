import { useTheme } from '@/hooks/useTheme'
import { useState } from 'react'
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap'

export const ThemeDropdown = ({ ...rest }) => {

    const { theme, setTheme } = useTheme()

    const [dropdownOpen, setDropdownOpen] = useState(false)

    const toggle = () => setDropdownOpen((prevState) => !prevState)

    return (
        <Dropdown isOpen={dropdownOpen} toggle={toggle} {...rest}>
            <DropdownToggle nav caret>
                Theme
            </DropdownToggle>
            <DropdownMenu>
                <DropdownItem onClick={() => setTheme('light')}>☀️ Light</DropdownItem>
                <DropdownItem onClick={() => setTheme('dark')}>🌙 Dark</DropdownItem>
                <DropdownItem onClick={() => setTheme('system')}>💻 System</DropdownItem>
            </DropdownMenu>
        </Dropdown>
    )
}
