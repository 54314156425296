import * as React from 'react'
import { useContext, useEffect, useState } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { AccordionBody, AccordionHeader, AccordionItem, Card, Col, Row, UncontrolledAccordion } from 'reactstrap'

import { IErrorResponse } from '../../ErrorResponse'
import { ISidebarVm, IThreadPostItemDto, IUserProfileVm, SidebarClient, UsersClient } from '../../lib/DoomCentral'
import { ErrorContext } from '../../state'
import HelmetPages from '../helmet/HelmetPages'
import Sidebars from '../sidebars/Sidebars'

interface ProfilePageRouterProps {
    userName: string
}

interface ProfilePageProps extends RouteComponentProps<ProfilePageRouterProps> {
    // Add your regular properties here
}

export default (props: ProfilePageProps) => {
    const error = useContext(ErrorContext)
    const [loading, setLoading] = useState<boolean>(false)

    const [sidebarData, setSidebarData] = useState<ISidebarVm>(null)
    const [userData, setUserData] = useState<IUserProfileVm>(null)

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true)
            try {
                let client = new SidebarClient()
                const response = await client.getSidebars().then((response) => response.toJSON() as Promise<ISidebarVm>)
                setSidebarData(response)
            } catch (e) {
                error.setError(e.response as IErrorResponse)
            }
            setLoading(false)
        }

        fetchData()
    }, [])

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true)
            try {
                let client = new UsersClient()
                const response = await client.getUserProfile(props.match.params.userName).then((response) => response.toJSON() as Promise<IUserProfileVm>)
                setUserData(response)
            } catch (e) {
                error.setError(e.response as IErrorResponse)
            }
            setLoading(false)
        }

        fetchData()
    }, [parseInt(props.match.params.userName)])

    return (
        <React.Fragment>
            <HelmetPages title={`Doom Central - Profile - ${userData?.userSummary?.displayName ?? 'Loading...'}`} />
            <Sidebars loading={loading} newsItems={sidebarData?.newsItems} recentThreads={sidebarData?.recentThreads}>
                <div className='maincontent-container p-4'>
                    {userData && userData.userSummary && (
                        <Row>
                            <Col sm={8}>
                                <h1 className='display-4'>{userData?.userSummary?.displayName ?? ''}</h1>
                            </Col>
                            <Col sm={4}>
                                {userData?.userSummary?.badgeUrl && userData?.userSummary?.badgeUrl.length > 0 && (
                                    <div>
                                        <div className='d-flex justify-content-end align-items-center'>
                                            <h6>{userData?.userSummary?.badgeName}</h6>
                                        </div>
                                        <div className='d-flex justify-content-end align-items-center'>
                                            <img src={userData?.userSummary?.badgeUrl} />
                                        </div>
                                    </div>
                                )}
                            </Col>
                            <Col sm={4}>
                                <p className='lead fw-normal'>User Name</p>
                            </Col>
                            <Col sm={8}>
                                <p className='fs-5 fw-bold text-end'>{userData?.userSummary?.userName ?? ''}</p>
                            </Col>
                            <Col sm={4}>
                                <p className='lead fw-normal'>Register Date</p>
                            </Col>
                            <Col sm={8}>
                                <p className='fs-5 fw-bold text-end'>
                                    {new Intl.DateTimeFormat('default', { dateStyle: 'full', timeStyle: 'long' }).format(new Date(userData?.userSummary?.registrationDate))}
                                </p>
                            </Col>
                            <Col sm={4}>
                                <p className='lead fw-normal'>Last Login Date</p>
                            </Col>
                            <Col sm={8}>
                                <p className='fs-5 fw-bold text-end'>
                                    {new Intl.DateTimeFormat('default', { dateStyle: 'full', timeStyle: 'long' }).format(new Date(userData?.userSummary?.lastLoginDate))}
                                </p>
                            </Col>
                        </Row>
                    )}
                    {userData && userData.threadPosts.length > 0 && <Row></Row>}
                </div>
            </Sidebars>
        </React.Fragment>
    )
}
