import * as React from 'react'
import { NavLink } from 'react-router-dom'
import { Card, CardBody, CardHeader, CardTitle, Col, Row } from 'reactstrap'

import { countries } from '../../countries'
import { INewsItemsDto, IRecentThreadDto } from '../../lib/DoomCentral'
import ForumNav from '../forumnav/ForumNav'

interface SidebarProps {
    children?: React.ReactNode | React.ReactNode[]
    recentThreads?: IRecentThreadDto[]
    newsItems?: INewsItemsDto[]
    loading: boolean
}

export default (props: SidebarProps) => {
    return (
        // <Row xs="1" sm="1" md="1" lg="3" xl="3" xxl="3">
        //     <Col lg="2" xl="2" xxl="2">

        //         <aside>
        //             <Card>
        //                 <CardHeader>
        //                     <CardTitle>Community News</CardTitle>
        //                 </CardHeader>
        //                 <CardBody>
        //                     {!props.loading && props.newsItems && props.newsItems.map((s) => (
        //                         <NavLink to={`/news/${s.id}`} key={s.id}>
        //                             <>
        //                                 {s.title}
        //                                 Posted on {new Intl.DateTimeFormat('default', { month: 'short', day: 'numeric' }).format(new Date(s.postDate))} by {s.postAuthor}
        //                                 {s.posts}
        //                             </>
        //                         </NavLink>
        //                     ))}
        //                     {props.loading && props.newsItems && props.newsItems.length <= 0 && (
        //                         <Spinner />
        //                     )}

        //                 </CardBody>
        //             </Card>
        //         </aside>
        //     </Col>
        //     <Col lg="8" xl="8" xxl="8">
        //         <main>
        <div>{props.children}</div>
        //         </main>
        //     </Col>
        //     <Col lg="2" xl="2" xxl="2">
        //         <aside>
        //             <Card>
        //                 <CardHeader>
        //                     <CardTitle>Recent Threads</CardTitle>
        //                 </CardHeader>
        //                 <CardBody>
        //                     {!props.loading && props.recentThreads && props.recentThreads.map((s) => (
        //                         <>
        //                             <Spinner />
        //                             <NavLink to={`/thread/${s.id}`} key={s.id} className="module-link">
        //                                 {s.isSticky && (<i className="bi bi-pin-angle module-icon"></i>)}
        //                                 <span className="module-item-title">{s.title}</span>
        //                                 <span className="module-item-count">{s.posts}</span>
        //                             </NavLink>
        //                         </>
        //                     ))}
        //                     {props.loading && props.recentThreads && props.recentThreads.length <= 0 && (
        //                         <Spinner />
        //                     )}
        //                 </CardBody>
        //             </Card>
        //         </aside>
        //     </Col>
        // </Row>
    )
}
