import * as React from 'react'
import { NavLink } from 'react-router-dom'

interface PagesLinksProps {
    threadId: number
    pages: number
}

export default (props: PagesLinksProps) => {
    const createPagesLinks = (pages: number): JSX.Element[] => {
        let content: JSX.Element[] = []

        if (pages <= 6) {
            for (let i = 1; i <= pages; i++) {
                content = [
                    ...content,
                    <NavLink to={`/thread/${props.threadId}?page=${i}`} className='thread-pages'>
                        {i}
                    </NavLink>,
                ]
                content = [...content, <span> </span>]
            }
        } else {
            for (let i = 1; i <= 3; i++) {
                content = [
                    ...content,
                    <NavLink to={`/thread/${props.threadId}?page=${i}`} className='thread-pages'>
                        {i}
                    </NavLink>,
                ]
                content = [...content, <span> </span>]
            }

            content = [...content, <span>... </span>]

            for (let i = pages - 3; i <= pages; i++) {
                content = [
                    ...content,
                    <NavLink to={`/thread/${props.threadId}?page=${i}`} className='thread-pages'>
                        {i}
                    </NavLink>,
                ]
                content = [...content, <span> </span>]
            }
        }

        return content
    }

    return <React.Fragment>{props.pages > 0 && props.threadId > 0 && <>{createPagesLinks(props.pages)}</>}</React.Fragment>
}
