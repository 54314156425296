import ApiAuthorizationRoutes from '@/components/api-authorization/ApiAuthorizationRoutes'
import { Footer } from '@/components/footer/Footer'
import { Layout } from '@/components/layout/Layout'
import { LoadingIndicator } from '@/components/loading-indicator/LoadingIndicator'
import { Navbar } from '@/components/navbar/Navbar'
import AdminRoutes from '@/components/routes/AdminRoutes'
import DoomCentralRoutes from '@/components/routes/DoomCentralRoutes'
import EventRoutes from '@/components/routes/EventRoutes'
import ForumRoutes from '@/components/routes/ForumRoutes'
import NewsRoutes from '@/components/routes/NewsRoutes'
import PromotionRoutes from '@/components/routes/PromotionRoutes'
import { GoogleAnalyticsClient } from '@/lib/DoomCentral'
import { Home } from '@/pages/home/Home'
import { ErrorContext, ErrorProvider } from '@/state'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { useContext, useEffect, useState } from 'react'
import ReactGA from 'react-ga4'
import { HelmetProvider } from 'react-helmet-async'
import { AuthProvider } from 'react-oidc-context'
import { Route } from 'react-router'
import { BrowserRouter as Router } from 'react-router-dom'

import { oidcConfig } from './configuration'

const queryClient = new QueryClient()

export default () => {
    const error = useContext(ErrorContext)

    const [loading, setLoading] = useState<boolean>(false)
    const [initialized, setInitialized] = useState<boolean>(false)

    useEffect(() => {
        ;(async () => {
            setLoading(true)
            try {
                const client = new GoogleAnalyticsClient()
                const response = await client.getGoogleAnalyticsId().then((response) => response as string)
                ReactGA.initialize(response)
                setInitialized(true)
            } catch (e) {
                // any error is likely due to the user blocking traffic from GA
                console.warn([e.code, e.name, e.message].join(', '))
                // error.setError(e.response as IErrorResponse)
            }
            setLoading(false)
        })()
    }, [])

    useEffect(() => {
        if (initialized) {
            ReactGA.send({ hitType: 'pageview', page: location.pathname + location.search })
        }
    }, [initialized, location])

    return (
        <HelmetProvider>
            <AuthProvider {...oidcConfig}>
                <ErrorProvider>
                    <QueryClientProvider client={queryClient}>
                        <Router>
                            <Layout navbar={<Navbar />} footer={<Footer />}>
                                {loading ? (
                                    <LoadingIndicator />
                                ) : (
                                    <div className='mb-3'>
                                        <ApiAuthorizationRoutes />
                                        <AdminRoutes />
                                        <DoomCentralRoutes />
                                        <PromotionRoutes />
                                        <ForumRoutes />
                                        <NewsRoutes />
                                        <EventRoutes />
                                        <Route exact path='/' component={Home} />
                                    </div>
                                )}
                            </Layout>
                        </Router>
                    </QueryClientProvider>
                </ErrorProvider>
            </AuthProvider>
        </HelmetProvider>
    )
}
