import * as React from 'react'
import ReactPaginate from 'react-paginate'

interface PostPaginationProps {
    pageCount: number
    currentPage: number
    setPageChange: (page: number, displayOnly: boolean) => void
}

export default (props: PostPaginationProps) => {
    return (
        <React.Fragment>
            <div className='d-flex justify-content-end'>
                <ReactPaginate
                    forcePage={props.currentPage}
                    previousLabel={'←'}
                    nextLabel={'→'}
                    pageCount={props.pageCount}
                    onPageChange={(p) => props.setPageChange(p.selected + 1, false)}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    renderOnZeroPageCount={null}
                    containerClassName={'pagination'}
                    previousLinkClassName={'page-link'}
                    nextLinkClassName={'page-link'}
                    disabledClassName={'page-item disabled'}
                    activeClassName={'page-item active'}
                    activeLinkClassName={'page-link'}
                    nextClassName={'page-item'}
                    previousClassName={'page-item'}
                    breakClassName={'page-item'}
                    breakLinkClassName={'page-link'}
                    pageClassName={'page-item'}
                    pageLinkClassName={'page-link'}
                />
            </div>
        </React.Fragment>
    )
}
