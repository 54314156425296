import * as React from 'react'
import { Route } from 'react-router'

import DoomCentralEventRoles from '../../lib/DoomCentralEventRoles'
import DoomCentralRoles from '../../lib/DoomCentralRoles'
import AuthorizeRoute from '../api-authorization/AuthorizeRoute'
import EditEvent from '../editevent/EditEvent'
import EventListing from '../eventlisting/EventListing'
import EventPage from '../eventpage/EventPage'
import NewEvent from '../newevent/NewEvent'

export default () => (
    <>
        <AuthorizeRoute
            path='/newevent'
            component={NewEvent}
            componentroles={[DoomCentralEventRoles.GlobalEventAdministrator, DoomCentralEventRoles.EventOrganizer, DoomCentralRoles.GlobalAdministrator]}
        />
        <AuthorizeRoute
            exact
            path='/editevent'
            component={EditEvent}
            componentroles={[DoomCentralEventRoles.GlobalEventAdministrator, DoomCentralEventRoles.EventOrganizer, DoomCentralRoles.GlobalAdministrator]}
        />
        <Route exact path='/events' component={EventListing} />
        <Route exact path='/event/:id' component={EventPage} />
    </>
)
