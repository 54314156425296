import { useEffect } from 'react';

export const useScrollToHash = (hash: string) => {
    useEffect(() => {
        if (!hash) return;
        
        const targetId = hash.substring(1);

        const scrollToElement = () => {
            const $target = document.getElementById(targetId);
            if ($target) {
                $target.scrollIntoView();
                return true;
            }
            return false;
        };

        if (!scrollToElement()) {
            const observer = new MutationObserver((_, me) => {
                if (scrollToElement()) {
                    me.disconnect(); // stop observing once we've found and scrolled to the target
                }
            });

            observer.observe(document, {
                childList: true,
                subtree: true
            });

            return () => observer.disconnect();
        }
    }, [hash]);
};
