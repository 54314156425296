import { LoadingIndicator } from '@/components/loading-indicator/LoadingIndicator'
import { useCommunityNews } from '@/hooks/useCommunityNews'
import { Card, CardBody, CardHeader, CardTitle } from 'reactstrap'

export const CommunityNewsCard = ({ ...rest }) => {
    const { isLoading, error, data: communityNews } = useCommunityNews()

    return (
        <Card {...rest}>
            <CardBody style={{ minHeight: 64 }}>
                <CardTitle tag='h2' className='mb-3'>
                    Community News
                </CardTitle>
                {isLoading ? (
                    <LoadingIndicator />
                ) : error || communityNews.length === 0 ? (
                    <p>No news available.</p>
                ) : (
                    communityNews.map((newsItem) => (
                        <div key={newsItem.id}>
                            <h6>
                                <strong>{newsItem.title}</strong>
                            </h6>
                            <p>{newsItem.postDate.toDateString()}</p>
                            <p>{newsItem.posts} posts</p>
                        </div>
                    ))
                )}
            </CardBody>
        </Card>
    )
}
