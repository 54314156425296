import * as React from 'react'
import { FormGroup, Label } from 'reactstrap'

import NewPostBox from '../newpostbox/NewPostBox'

interface EventEditorBoxContainerProps {
    post: string
    setPost: React.Dispatch<React.SetStateAction<string>>
}

export default (props: EventEditorBoxContainerProps) => {
    return (
        <React.Fragment>
            <FormGroup>
                <Label for='post-editor'>Event Post</Label>
                <NewPostBox post={props.post} setPost={props.setPost} />
            </FormGroup>
        </React.Fragment>
    )
}
