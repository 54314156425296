import { DeleteIcon } from '@/components/icons/Icons'
import { PostStation2Context } from '@/contexts/PostStation2Context'
import { formatBytes } from '@/utils/helpers'
import { RefMDEditor } from '@uiw/react-md-editor'
import { ForwardedRef, LegacyRef, forwardRef, useCallback, useContext, useImperativeHandle, useRef } from 'react'
import { FileRejection, useDropzone } from 'react-dropzone'
import { Button, Col, ListGroup, ListGroupItem, Row, Tooltip } from 'reactstrap'

import { cn } from '../../utils/utils'
import { MarkdownEditor } from '../markdown-editor/MarkdownEditor'
import { QuotedPost } from '../quoted-post/QuotedPost'
import { ImagePreviewTooltip } from './ImagePreviewTooltip'
import { validatePostFile } from './postFileValidator'

export type PostStation2Props = {}

export const PostStation2 = forwardRef<ForwardedRef<LegacyRef<RefMDEditor>>, PostStation2Props>((props, ref) => {
    const { actions, meta, quotedPost, postContent, fileRejects, uploadedFiles } = useContext(PostStation2Context)
    const allowedFileExtensions = meta.allowedFileExtensions
    const maxFileSize = meta.maxFileSize
    const maxFiles = meta.maxFilesPerPost

    const canPostFiles = allowedFileExtensions?.length > 0 && maxFileSize > 0 && maxFiles > 0

    const onDrop = useCallback(
        (acceptedFiles: File[], fileRejections: FileRejection[]) => {
            const validatedFiles: File[] = []
            const newFileErrors: FileRejection[] = []

            acceptedFiles.forEach((file) => {
                const combinedFiles = [...uploadedFiles, ...validatedFiles]
                const validationResult = validatePostFile(file, combinedFiles, maxFileSize, maxFiles, allowedFileExtensions)

                if (validationResult === null) {
                    validatedFiles.push(file)
                } else {
                    newFileErrors.push(validationResult)
                }
            })

            newFileErrors.push(...fileRejections)
            const newFileRejects = [...fileRejects, ...newFileErrors]
            const newUploadedFiles = [...uploadedFiles, ...validatedFiles]

            actions.setFileRejects(newFileRejects)
            actions.setUploadedFiles(newUploadedFiles)
        },
        [uploadedFiles, fileRejects],
    )

    const { getRootProps, getInputProps, isDragActive, open } = useDropzone({ onDrop, noClick: true, noKeyboard: true })

    return (
        <>
            {quotedPost && (
                <div className='my-3'>
                    <QuotedPost reply postId={quotedPost.id} />
                </div>
            )}

            <div {...getRootProps()} className={cn(isDragActive && 'border border-success')}>
                <input {...getInputProps()} />
                <div className='my-3'>
                    <MarkdownEditor ref={ref} value={postContent} onChange={(v) => actions.setPostContent(v)} />
                    {(uploadedFiles.length > 0 || fileRejects.length > 0) && (
                        <Row className='my-3'>
                            {uploadedFiles.length > 0 && (
                                <Col xs={12} md={6}>
                                    <ListGroup>
                                        <h6>Uploaded Files</h6>
                                        {uploadedFiles.map((file) => {
                                            return (
                                                <ListGroupItem key={file.name + file.size} className={cn('d-flex', 'justify-content-between', 'align-items-center')}>
                                                    <ImagePreviewTooltip label={`${file.name} - ${formatBytes(file.size)}`} file={file} />
                                                    <Button size='sm' color='danger' outline style={{ border: 0 }} onClick={() => actions.removeFile(file.name)}>
                                                        <DeleteIcon />
                                                    </Button>
                                                </ListGroupItem>
                                            )
                                        })}
                                    </ListGroup>
                                </Col>
                            )}

                            {fileRejects.length > 0 && (
                                <Col xs={12} md={6}>
                                    <ListGroup>
                                        <h6>Rejected Files</h6>
                                        {fileRejects.map((rejected) => (
                                            <ListGroupItem key={rejected.file.name}>
                                                <p>
                                                    {rejected.file.name} - {formatBytes(rejected.file.size)}
                                                </p>
                                                {rejected.errors.length > 0 && (
                                                    <ListGroup>
                                                        {rejected.errors.map((error) => (
                                                            <ListGroupItem color='danger' key={error.code + error.message}>
                                                                {error.code} {error.message}
                                                            </ListGroupItem>
                                                        ))}
                                                    </ListGroup>
                                                )}
                                            </ListGroupItem>
                                        ))}
                                    </ListGroup>
                                </Col>
                            )}
                        </Row>
                    )}
                    <div className='my-3'>
                        {canPostFiles && (
                            <Button color='secondary' onClick={open} className='me-1'>
                                Attach Files
                            </Button>
                        )}
                        <Button color='primary' disabled={actions.submitPost.isLoading || postContent === ''} onClick={actions.submitPost.exec}>
                            Submit Post
                        </Button>
                    </div>
                </div>
            </div>
        </>
    )
})
