import * as React from 'react'
import { useContext, useEffect, useState } from 'react'
import { Col, Row } from 'reactstrap'

import { IErrorResponse } from '../../../../ErrorResponse'
import { ForumsClient, IAllForumGroupDto, IAllForumListingVm, IForumGroupListVm } from '../../../../lib/DoomCentral'
import { ErrorContext } from '../../../../state'
import ForumOrderComponent from './ForumOrderComponent'

interface ManageForumOrderProps {
    loading: boolean
    modGroup: number
    modForum: number
}

export default (props: ManageForumOrderProps) => {
    const error = useContext(ErrorContext)

    const [forums, setForums] = useState<IAllForumGroupDto[]>([])
    const [loading, setLoading] = useState<boolean>(false)
    const [modOrder, setModOrder] = useState<number>(0)

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true)
            try {
                let client = new ForumsClient()
                const response = await client.getAllForumsAndGroups().then((response) => response.toJSON() as Promise<IAllForumListingVm>)
                setForums(response.forumGroups)
            } catch (e) {
                error.setError(e.response as IErrorResponse)
            }
            setLoading(false)
        }

        fetchData()
    }, [props.modForum, props.modGroup, modOrder])

    return (
        <React.Fragment>
            <Row>
                <h2>Order Forum Groups</h2>
                <Col>
                    <ForumOrderComponent forums={forums} loading={loading && props.loading} setModOrder={setModOrder} />
                </Col>
            </Row>
        </React.Fragment>
    )
}
