import { SigninRedirectArgs } from 'oidc-client-ts'
import { useEffect } from 'react'
import { useAuth } from 'react-oidc-context'

import { getReturnUrl } from './AuthHelpers'

const LoginRedirect = () => {
    const auth = useAuth()
    var args = {} as SigninRedirectArgs
    args.state = getReturnUrl(null)

    useEffect(() => {
        ;(async () => {
            await auth.signinRedirect(args)
        })()
    }, [])

    return <div>Processing login</div>
}

export default LoginRedirect
