import * as React from 'react'
import { useContext, useEffect, useState } from 'react'

import { IErrorResponse } from '../../../ErrorResponse'
import { ForumGroupsClient, IForumGroupListVm } from '../../../lib/DoomCentral'
import { ErrorContext } from '../../../state'
import HelmetPages from '../../helmet/HelmetPages'
import AdminPanel from '../AdminPanel'
import ManageForum from './forum/ManageForum'
import ManageForumGroup from './forumgroup/ManageForumGroup'
import ManageForumOrder from './forumorder/ManageForumOrder'

export default () => {
    const error = useContext(ErrorContext)

    const [modGroup, setModGroup] = useState<number>(0)
    const [modForum, setModForum] = useState<number>(0)
    const [forumGroups, setForumGroups] = useState<IForumGroupListVm>(null)
    const [loading, setLoading] = useState<boolean>(false)

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true)
            try {
                let client = new ForumGroupsClient()
                const response = await client.get().then((response) => response.toJSON() as Promise<IForumGroupListVm>)
                setForumGroups(response)
            } catch (e) {
                error.setError(e.response as IErrorResponse)
            }
            setLoading(false)
        }

        fetchData()
    }, [modGroup])

    return (
        <React.Fragment>
            <HelmetPages title={'Doom Central - Manage Forums'} />
            <AdminPanel>
                <h1>Manage Forums</h1>
                <ManageForumGroup forumGroups={forumGroups} setModGroup={setModGroup} />
                <hr />
                <ManageForum modForum={modForum} setModForum={setModForum} forumGroups={forumGroups} loading={loading} />
                <hr />
                <ManageForumOrder loading={loading} modGroup={modGroup} modForum={modForum} />
            </AdminPanel>
        </React.Fragment>
    )
}
