import { idForThreadType, routeForResourceType, routeForThreadType } from '@/utils/helpers'
import RelativeTime from '@yaireo/relative-time'
import * as React from 'react'
import { NavLink } from 'react-router-dom'
import { Col, Row } from 'reactstrap'

import { PostViewMode, PromotedType, ThreadListItemDto } from '../../lib/DoomCentral'
import PagesLinks from './PagesLinks'
import UnreadControl from './UnreadControl'

interface ThreadListProps {
    threadList: ThreadListItemDto[]
}

export default (props: ThreadListProps) => {
    const relativeTime = new RelativeTime()

    return (
        <React.Fragment>
            <div className='thread-list'>
                {props.threadList &&
                    props.threadList.map((s, idx) => (
                        <Row xs={1} sm={1} md={1} lg={3} className='thread g-0' key={s.id}>
                            <Col lg={8} className='main'>
                                {s.unread && <span className='forum-unread-posts'>• </span>}
                                {s.stickied && <span className='tag stickied'>Stickied</span>}
                                <span className='d-inline-block text-truncate title'>
                                    <NavLink to={`/${routeForThreadType(s)}/${idForThreadType(s)}`}>{s.title}</NavLink>
                                    {s.locked && <span className='tag locked'>🔒 Locked</span>}
                                    {s.moderated && <span className='tag moderated'>🛡️ Moderated</span>}
                                    {s.hidden && <span className='tag hidden'>🔎 Hidden</span>}
                                    {!s.approved && <span className='tag approved'>🧑‍⚖️ Not Approved</span>}
                                    {s.unread && <UnreadControl threadUrl={`/thread/` + s.id} unreadPosts={s.unreadPosts} />}
                                </span>
                                {s.hot && <span className='tag hot'>🔥 Hot</span>}
                                <div className='description'>
                                    posted {relativeTime.from(new Date(s.created))} by{' '}
                                    {s.threadAuthorUserName && <NavLink to={`/user/${s.threadAuthorUserName}`}>{s.threadAuthorDisplayName}</NavLink>}
                                    {!s.threadAuthorUserName && s.threadAuthorDisplayName}
                                    {s.dcForum && (
                                        <>
                                            {' '}
                                            in <NavLink to={`/forum/${s.dcForum.id}`}>{s.forumName}</NavLink>
                                        </>
                                    )}
                                    {s.dcEvent && (
                                        <>
                                            {' '}
                                            in <NavLink to={`/events`}>Events</NavLink>
                                        </>
                                    )}
                                    {s.dcNews && (
                                        <>
                                            {' '}
                                            in <NavLink to={`/news`}>News</NavLink>
                                        </>
                                    )}
                                </div>
                            </Col>
                            <Col lg={4} className='thread-info-box'>
                                <Col className='pages'>
                                    Posts: <span className='post-count'>{s.postCount}</span>
                                    <br />
                                    Pages: <PagesLinks threadId={s.id} pages={s.pages} />
                                </Col>
                                <Col className='last-post-info'>
                                    {s.lastPost && s.lastPost.postAuthorUserName && (
                                        <React.Fragment>
                                            <NavLink to={`/user/${s.lastPost.postAuthorUserName}`}>{s.lastPost.postAuthorDisplayName}</NavLink>
                                            <br />
                                            <NavLink to={`/thread/${s.id}?goto=${PostViewMode.LastPost}`}>{relativeTime.from(new Date(s.lastPost.created))}</NavLink>
                                        </React.Fragment>
                                    )}
                                    {s.lastPost && !s.lastPost.postAuthorUserName && (
                                        <React.Fragment>
                                            {s.lastPost.postAuthorDisplayName}
                                            <br />
                                            <NavLink to={`/thread/${s.id}?goto=${PostViewMode.LastPost}`}>{relativeTime.from(new Date(s.lastPost.created))}</NavLink>
                                        </React.Fragment>
                                    )}
                                </Col>
                            </Col>
                        </Row>
                    ))}
            </div>
        </React.Fragment>
    )
}
