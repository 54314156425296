import * as React from 'react'
import { useContext, useEffect, useState } from 'react'
import { Button, Col, FormGroup, Input, Label } from 'reactstrap'

import { IErrorResponse } from '../../../ErrorResponse'
import { DeleteEmoteCommand, EmotesClient, IEmoteListVm, IEmotesDto } from '../../../lib/DoomCentral'
import { ErrorContext } from '../../../state'

interface DeleteEmoteProps {
    modEmote: number
    setModEmote: React.Dispatch<React.SetStateAction<number>>
}

export default (props: DeleteEmoteProps) => {
    const error = useContext(ErrorContext)

    const [loading, setLoading] = useState<boolean>(false)
    const [emotes, setEmotes] = useState<IEmotesDto[]>(null)
    const [selectedEmote, setSelectedEmotes] = useState<IEmotesDto>(null)

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true)
            try {
                let client = new EmotesClient()
                const response = await client.get().then((response) => response.toJSON() as Promise<IEmoteListVm>)
                setEmotes(response.emotes)
            } catch (e) {
                error.setError(e.response as IErrorResponse)
            }
            setLoading(false)
        }

        fetchData()
    }, [props.modEmote])

    const submitDeleteEmote = async () => {
        setLoading(true)
        try {
            let client = new EmotesClient()
            let command = new DeleteEmoteCommand()
            command.emoteId = selectedEmote.id
            const response = await client.delete(selectedEmote.id, command).then((response) => response as number)
            props.setModEmote(response)
        } catch (e) {
            error.setError(e.response as IErrorResponse)
        }
        setLoading(false)
    }

    return (
        <React.Fragment>
            <h2>Delete Emotes</h2>
            <FormGroup row>
                <Label>Emotes:</Label>
                <Col sm={10}>
                    <Input
                        type='select'
                        value={selectedEmote?.id ?? 0}
                        onChange={(e) => setSelectedEmotes(emotes.find((f) => f.id === parseInt(e.target.value, 10)) ?? emotes[0])}
                        disabled={loading || !emotes || emotes.length <= 0}
                    >
                        {emotes &&
                            emotes.map((s, idx) => (
                                <option value={s.id} key={s.id}>
                                    {s.emoteName}
                                </option>
                            ))}
                    </Input>
                </Col>
            </FormGroup>
            <FormGroup row>
                <Col>{selectedEmote && <img src={'/images/emotes/' + selectedEmote.emoteCode} />}</Col>
            </FormGroup>
            <FormGroup row>
                <Col>
                    <Button disabled={!emotes || emotes.length <= 0 || !selectedEmote || loading} onClick={submitDeleteEmote} color='danger'>
                        Delete Emote
                    </Button>
                </Col>
            </FormGroup>
        </React.Fragment>
    )
}
