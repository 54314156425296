import * as React from 'react'
import { useContext, useEffect, useState } from 'react'

import { IErrorResponse } from '../../../ErrorResponse'
import { IUserDto, IUsersVm, UsersClient } from '../../../lib/DoomCentral'
import { ErrorContext } from '../../../state'
import HelmetPages from '../../helmet/HelmetPages'
import AdminPanel from '../AdminPanel'
import UserActionPanel from './UserActionPanel'
import UserOptions from './UserOptions'
import UserPagination from './UserPagination'
import UserTable from './UserTable'

export default () => {
    const error = useContext(ErrorContext)

    const [modUser, setModUser] = useState<string>('')
    const [users, setUsers] = useState<IUsersVm>(null)
    const [selectedUser, setSelectedUser] = useState<IUserDto>(null)
    const [loading, setLoading] = useState<boolean>(false)
    const [pageNumber, setPageNumber] = useState<number>(1)
    const [pageSize, setPageSize] = useState<number>(40)
    const [hidden, setHidden] = useState<boolean>(true)

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true)
            try {
                let client = new UsersClient()
                const response = await client.get(pageNumber, pageSize).then((response) => response.toJSON() as Promise<IUsersVm>)
                setUsers(response)
            } catch (e) {
                error.setError(e.response as IErrorResponse)
            }
            setLoading(false)
        }

        fetchData()
    }, [])

    return (
        <React.Fragment>
            <HelmetPages title={'Doom Central - Manage Users'} />
            <AdminPanel>
                <h1>Manage Users</h1>
                <UserPagination pageCount={users?.totalPages ?? 0} setPageChange={setPageNumber} />
                <UserOptions pageSize={pageSize} setPageSize={setPageSize} />
                <UserTable users={users?.userList} setSelectedUser={setSelectedUser} />
                <UserPagination pageCount={users?.totalPages ?? 0} setPageChange={setPageNumber} />
                <UserActionPanel selectedUser={selectedUser} modUser={modUser} setModUser={setModUser} hidden={hidden} setHidden={setHidden} />
            </AdminPanel>
        </React.Fragment>
    )
}
