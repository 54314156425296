import { ApplicationPaths, QueryParameterNames, authority_url, client_id } from '../../configuration'

export const getReturnUrl = (state: string) => {
    const params = new URLSearchParams(window.location.search)
    const fromQuery = params.get(QueryParameterNames.ReturnUrl)
    if (fromQuery && !fromQuery.startsWith(`${window.location.origin}/`)) {
        // This is an extra check to prevent open redirects.
        throw new Error('Invalid return url. The return url needs to have the same origin as the current page.')
    }
    return state || fromQuery || `${window.location.origin}/`
}

export const clearTokens = () => {
    localStorage.removeItem(`oidc.user:${authority_url}:${client_id}`)
}

export const redirectToRegister = () => {
    redirectToApiAuthorizationPath(`${ApplicationPaths.IdentityRegisterPath}?${QueryParameterNames.ReturnUrl}=${encodeURI(ApplicationPaths.Login)}`)
}

export const redirectToProfile = () => {
    redirectToApiAuthorizationPath(ApplicationPaths.IdentityManagePath)
}

export const redirectToApiAuthorizationPath = (apiAuthorizationPath: string) => {
    const redirectUrl = `${window.location.origin}/${apiAuthorizationPath}`
    // It's important that we do a replace here so that when the user hits the back arrow on the
    // browser they get sent back to where it was on the app instead of to an endpoint on this
    // component.
    window.location.replace(redirectUrl)
}
