import { FileError, FileRejection } from 'react-dropzone'

export const fileAmountValidator = (files: File[], maxFilesInPost: number): FileError => {
    if (files.length + 1 > maxFilesInPost) {
        return {
            code: 'too-many-files',
            message: `This file you tried to upload exceeds the max limit of ${maxFilesInPost} files per post.`,
        }
    }

    return null
}

export const sameFileNameCheckValidator = (file: File, files: File[]): FileError => {
    if (files.some((s) => s.name === file.name)) {
        return {
            code: 'same-file-name',
            message: `This file has the same file name as another file being prepared to be uploaded.`,
        }
    }

    return null
}

export const validateFileName = (fileName: string, allowedFileExtensions: string): boolean => {
    const extensionList: string[] = allowedFileExtensions.split('/')
    return Boolean(extensionList.some((s) => fileName.endsWith(s)))
}

export const fileSizeValidator = (file: File, maxFileSizeBytes: number): FileError => {
    if (file.size > maxFileSizeBytes) {
        return {
            code: 'file-too-large',
            message: `File size is larger than ${maxFileSizeBytes} bytes.`,
        }
    }
    return null
}

export const fileExtensionValidator = (file: File, allowedFileExtensions): FileError => {
    if (!validateFileName(file.name, allowedFileExtensions)) {
        return {
            code: 'invalid-file',
            message: `You tried to upload a file type not allowed here! Allowed file types are: ${allowedFileExtensions.replaceAll('/', ', ')}`,
        }
    }
    return null
}

export const validatePostFile = (file: File, fileList: File[], maxFileSizeBytes: number, maxFilesInPost: number, allowedFileExtensions: string): FileRejection => {
    const sizeCheck = fileSizeValidator(file, maxFileSizeBytes)
    if (sizeCheck != null) {
        return {
            file: file,
            errors: [sizeCheck],
        } as FileRejection
    }

    var extensionCheck = fileExtensionValidator(file, allowedFileExtensions)
    if (extensionCheck != null) {
        return {
            file: file,
            errors: [extensionCheck],
        } as FileRejection
    }

    var sameFileNameCheck = sameFileNameCheckValidator(file, fileList)
    if (sameFileNameCheck != null) {
        return {
            file: file,
            errors: [sameFileNameCheck],
        } as FileRejection
    }

    var fileAmountCheck = fileAmountValidator(fileList, maxFilesInPost)
    if (fileAmountCheck != null) {
        return {
            file: file,
            errors: [fileAmountCheck],
        } as FileRejection
    }

    return null
}
