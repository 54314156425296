import * as React from 'react'
import { Route } from 'react-router'

import DoomCentralRoles from '../../lib/DoomCentralRoles'
import NewsPostRoles from '../../lib/NewsPostRoles'
import AuthorizeRoute from '../api-authorization/AuthorizeRoute'
import EditNews from '../editnews/EditNews'
import NewNews from '../newnews/NewNews'
import NewsListing from '../newslisting/NewsListing'
import NewsPage from '../newspage/NewsPage'

export default () => (
    <React.Fragment>
        <AuthorizeRoute
            path='/newnews'
            component={NewNews}
            componentroles={[NewsPostRoles.GlobalNewsAdministrator, NewsPostRoles.NewsAuthor, DoomCentralRoles.GlobalAdministrator]}
        />
        <AuthorizeRoute
            path='/editnews'
            component={EditNews}
            componentroles={[NewsPostRoles.GlobalNewsAdministrator, NewsPostRoles.NewsAuthor, DoomCentralRoles.GlobalAdministrator]}
        />
        <Route exact path='/news' component={NewsListing} />
        <Route exact path='/news/:id' component={NewsPage} />
    </React.Fragment>
)
