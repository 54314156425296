import * as React from 'react'
import { NavLink } from 'react-router-dom'
import { Button, Col, Input, InputGroup, Row } from 'reactstrap'

import MarkdownPost from '../markdown-post/MarkdownPost'

interface NewsContentProps {
    content: string
}

export default (props: NewsContentProps) => {
    return (
        <React.Fragment>
            <Row>
                <Col>
                    <MarkdownPost content={props.content} />
                </Col>
            </Row>
        </React.Fragment>
    )
}
