import update from 'immutability-helper'
import * as React from 'react'
import { useCallback, useEffect } from 'react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

import { IPromotedItemDto } from '../../lib/DoomCentral'
import { PromotionItem } from './PromotionItem'

interface SetPromotionOrderProps {
    promotions: IPromotedItemDto[]
    setPromotions: React.Dispatch<React.SetStateAction<IPromotedItemDto[]>>
}

export default (props: SetPromotionOrderProps) => {
    const moveCard = useCallback(
        (dragIndex: number, hoverIndex: number) => {
            props.setPromotions((prevCards: IPromotedItemDto[]) =>
                update(prevCards, {
                    $splice: [
                        [dragIndex, 1],
                        [hoverIndex, 0, prevCards[dragIndex] as IPromotedItemDto],
                    ],
                }),
            )
        },
        [props.promotions],
    )

    const renderCard = useCallback(
        (promotedItem: IPromotedItemDto, index: number) => {
            return (
                <PromotionItem
                    key={index}
                    index={index}
                    id={promotedItem.resourceId}
                    title={promotedItem.promotedTitle}
                    blurb={promotedItem.promotedBlurb}
                    url={promotedItem.promotedImageUrl}
                    type={promotedItem.promotedType}
                    deletePromotion={deletePromotion}
                    moveCard={moveCard}
                />
            )
        },
        [props.promotions],
    )

    const deletePromotion = (index: number) => {
        var newPromotions: IPromotedItemDto[] = [...props.promotions]
        newPromotions.splice(index, 1)
        props.setPromotions([...newPromotions])
    }

    // Normally you would want to split things out into separate components.
    // But in this example everything is just done in one place for simplicity
    return (
        <React.Fragment>
            <h2>Set Promotion Order</h2>
            <h5>Only top 3 are shown on the home page. 7 shown here.</h5>
            {props.promotions.length <= 0 && <h3>No promotions are currently in the system.</h3>}
            <DndProvider backend={HTML5Backend} key={1}>
                {props.promotions.map((promotion, i) => renderCard(promotion, i))}
            </DndProvider>
        </React.Fragment>
    )
}
