import clsx, { ClassValue } from 'clsx'

type Key = string | number

export interface GroupedObject<T> {
    [key: string]: T[]
}

export function groupObjectsBykey<T>(arr: T[], key: keyof T): GroupedObject<T> {
    return arr.reduce((acc: GroupedObject<T>, obj: T) => {
        const keyValue: Key = obj[key] as Key
        const groupKey: string = String(keyValue)

        if (!(groupKey in acc)) {
            acc[groupKey] = []
        }

        acc[groupKey].push(obj)
        return acc
    }, {} as GroupedObject<T>)
}

export function cn(...inputs: ClassValue[]) {
    return clsx(inputs)
}

export const getPreviewImageForFileNameString = (fileName: string, postId: number): string => {
    var approvedImageExtensions = ['.jpg', '.jpeg', '.png', '.gif', '.bmp', '.webm', '.webp'] as string[]
    if (approvedImageExtensions.some((s) => fileName.endsWith(s))) {
        return '/postfiles/' + String(postId) + '/' + fileName
    }

    // Put other file types here.
    var zipExtensions = ['.zip', '.pk3', '.7z', '.rar'] as string[]
    if (zipExtensions.some((s) => fileName.endsWith(s))) {
        return '/images/filetypes/filetypeplaceholder_zip.png'
    }

    return '/images/filetypes/filetypeplaceholder_any.png'
}

export const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms))
