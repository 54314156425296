import { getThreadMeta } from '@/api/getThreadMeta'
import { ThreadsClient } from '@/lib/DoomCentral'

type getThreadArgs = {
    id: number
    page?: number
    limit?: number
}

export const getPaginatedThread = async ({ id, page = 1, limit = 10 }: getThreadArgs) => {
    const meta = await getThreadMeta({ id })
    const client = new ThreadsClient()
    const { postList: posts, totalPages, totalCount, highestPostId } = await client.getPostsByThreadId(id, id, +page, +limit)
    return { meta, posts, totalPages, totalPosts: totalCount, highestPostId }
}
