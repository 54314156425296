import * as React from 'react'
import { useState } from 'react'

import NewPostBox from '../newpostbox/NewPostBox'

interface NewPostBoxContainerProps {
    post: string
    setPost: React.Dispatch<React.SetStateAction<string>>
}

export default (props: NewPostBoxContainerProps) => {
    return (
        <React.Fragment>
            <div className='mt-2'>
                <NewPostBox post={props.post} setPost={props.setPost} />
            </div>
        </React.Fragment>
    )
}
