import { ForumsClient, ThreadsClient } from '@/lib/DoomCentral'

type getThreadsArgs = {
    id: number
    page?: number
    limit?: number
}

export const getThreads = async ({ id, page = 1, limit = 10 }: getThreadsArgs) => {
    const fc = new ForumsClient()
    const [forumMeta] = (await fc.getForums()).forums.filter(({ id: forumId }) => +forumId === id)
    const { threadList: res } = await fc.getThreadsByForumId(id, id, page, limit)
    // const { threadList: res } = await new ThreadsClient().getThreadList(id, page, limit)
    const { totalCount, totalPages, threadList } = res
    return { threads: threadList, totalPages, totalThreads: totalCount, forumMeta }
}
