import { NavLink } from 'react-router-dom'

export default () => {
    return (
        <div>
            <p className='fs-2 text-danger mx-auto text-center'>Access Denied</p>
            <p className='mx-auto text-center'>You probably shouldn't be here. But you can't stay.</p>
            <p className='mx-auto text-center'>
                Please <NavLink to={'/'}> Go Home</NavLink>
            </p>
        </div>
    )
}
