import { LoadingIndicator } from '@/components/loading-indicator/LoadingIndicator'
import { useRecentThreads } from '@/hooks/useRecentThreads'
import { FlagIcon, FlameIcon, MessageSquarePlusIcon } from 'lucide-react'
import { Link } from 'react-router-dom'
import { Button, Card, CardBody, CardHeader, CardTitle } from 'reactstrap'

export const RecentThreadsCard = ({ ...rest }) => {
    const { isLoading, isError, data: recentThreads } = useRecentThreads()

    return (
        <Card {...rest}>
            <CardBody style={{ minHeight: 64 }}>
                <CardTitle className='mb-3' tag='h2'>
                    Recent Threads
                </CardTitle>
                {isLoading ? (
                    <LoadingIndicator />
                ) : isError || recentThreads?.length === 0 ? (
                    <p>No threads available.</p>
                    ) : (
                        recentThreads.map(({ id, isSticky, isHot, isNew, title, posts }) => (
                        <div key={id}>
                            <Link to={`/thread/${id}`} className='ps-0'>
                                {isSticky && <FlagIcon className='me-1' style={{ width: '1rem', height: '1rem' }} />}
                                {isHot && <FlameIcon className='me-1' style={{ width: '1rem', height: '1rem' }} />}
                                {isNew && <MessageSquarePlusIcon className='me-1' style={{ width: '1rem', height: '1rem' }} /> }
                                {title}
                            </Link>
                            <p>
                                <small>{posts} posts</small>
                            </p>
                        </div>
                    ))
                )}
            </CardBody>
        </Card>
    )
}
