import { navigateToReturnUrl } from '@/utils/helpers'
import * as React from 'react'
import { useContext } from 'react'
import { Button } from 'reactstrap'

import { IErrorResponse } from '../../ErrorResponse'
import { NewsClient, UpdateNewsCommand } from '../../lib/DoomCentral'
import { ErrorContext } from '../../state'

interface SubmitEditNewsButtonProps {
    newsId: number
    newsTitle: string
    post: string
    publish: boolean
    loading: boolean
    setLoading: React.Dispatch<React.SetStateAction<boolean>>
}

export default (props: SubmitEditNewsButtonProps) => {
    const error = useContext(ErrorContext)

    const submitEditNews = async () => {
        props.setLoading(true)
        try {
            let client = new NewsClient()
            const command = new UpdateNewsCommand()
            command.newsId = props.newsId
            command.newsTitle = props.newsTitle
            command.newsContent = props.post
            command.publish = props.publish
            const response = await client.update(props.newsId, command).then((response) => response as number)
            await navigateToReturnUrl(`/news/${response}`)
        } catch (e) {
            error.setError(e.response as IErrorResponse)
        }
        props.setLoading(false)
    }
    return (
        <React.Fragment>
            <div className='d-flex'>
                <div className='ms-auto'>
                    <Button color='secondary' disabled={props.newsId <= 0 || props.loading || !props.newsTitle || !props.post} onClick={(e) => submitEditNews()}>
                        Submit Event
                    </Button>
                </div>
            </div>
        </React.Fragment>
    )
}
