import { PostItemDto, ThreadDetailDto } from '@/lib/DoomCentral'
import { ListGroup } from 'reactstrap'
import { useRef, useEffect } from 'react'

import { Post } from '../post/Post'

export type PostListProps = {
    meta: ThreadDetailDto
    posts: PostItemDto[]
    onQuotePostClick: (post: PostItemDto) => void
}

export const PostList = ({ meta, posts, onQuotePostClick }: PostListProps) => {
    const scriptRoot = useRef<HTMLInputElement | null>(null) // gets assigned to a root node

    const tiktokscript = `<script async defer src="https://www.tiktok.com/embed.js"></script>`
    const twitterscript = `<script async defer src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>`
    const redditscript = `<script async defer src="https://embed.reddit.com/widgets.js" charset="UTF-8"></script>`

    useEffect(() => {
        // creates a document range (grouping of nodes in the document is my understanding)
        // in this case we instantiate it as empty, on purpose
        const range = document.createRange()
        // creates a mini-document (light weight version), in our range with our script in it
        const twitterFragment = range.createContextualFragment(twitterscript)
        const tiktokFragment = range.createContextualFragment(tiktokscript)
        const redditFragment = range.createContextualFragment(redditscript)
        // appends it to our script root - so it renders in the correct location
        scriptRoot.current?.append(twitterFragment)
        scriptRoot.current?.append(tiktokFragment)
        scriptRoot.current?.append(redditFragment)
    });

    return (
        <ListGroup>
            <>
                <div ref={scriptRoot}></div>
                {posts.length === 0 ? <div>There are no posts.</div> : posts.map((post) => <div key={post.id} className="mb-3">
                    <Post meta={meta} post={post} onQuotePostClick={onQuotePostClick} />
                </div>)}
            </>
        </ListGroup>
    )
}
