import * as React from 'react'
import { NavLink } from 'react-router-dom'
import { Input, Table } from 'reactstrap'

import { IUserDto } from '../../../lib/DoomCentral'

interface UserTableProps {
    users: IUserDto[]
    setSelectedUser: React.Dispatch<React.SetStateAction<IUserDto>>
}

export default (props: UserTableProps) => {
    const selectUser = (id: string) => {
        props.setSelectedUser(props.users.find((f) => f.id == id) ?? props.users[0])
    }

    return (
        <React.Fragment>
            <Table>
                <thead>
                    <tr key='header'>
                        <th> </th>
                        <th>User Id</th>
                        <th>User Name</th>
                        <th>User Banned?</th>
                        <th>Lockout Enabled</th>
                        <th>Locked out until</th>
                    </tr>
                </thead>
                <tbody>
                    {props.users &&
                        props.users.map((s, idx) => (
                            <tr key={s.id}>
                                <td>
                                    <Input type='radio' name='usergroup' value={s.id} onClick={(s) => selectUser(s.currentTarget.value)} />
                                </td>
                                <td>{s.id}</td>
                                <td>
                                    <NavLink to={`/user/${s.userName}`}>{s.userName}</NavLink>
                                </td>
                                <td>
                                    {!s.banned && '❌'}
                                    {s.banned && '✔️'}
                                </td>
                                <td>
                                    {!s.lockoutEnabled && '❌'}
                                    {s.lockoutEnabled && '✔️'}
                                </td>
                                <td>{s.lockoutEnd?.toISOString() ?? 'No lockout'}</td>
                            </tr>
                        ))}
                </tbody>
            </Table>
        </React.Fragment>
    )
}
