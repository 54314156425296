import * as React from 'react'
import { useEffect } from 'react'
import { AuthContextProps } from 'react-oidc-context'
import { NavLink } from 'react-router-dom'
import { Col, Row } from 'reactstrap'

interface EventHeaderProps {
    title: string
    id: number
    eventOrganizer: string
    eventOrganizerDisplayName: string
    canEdit: boolean
    eventPublishDate: Date
    loading: boolean
}

export default (props: EventHeaderProps) => {
    return (
        <React.Fragment>
            {props.canEdit && (
                <div className='d-flex'>
                    <NavLink className='ms-auto' to={`/editevent?eventId=${props.id}`}>
                        <i className='ms-auto bi bi-pencil-square'></i>
                    </NavLink>
                </div>
            )}
            <Row>
                <Col>
                    {props.title && <h1>{props.title}</h1>}
                    {!props.title && (
                        <div className='placeholder-glow'>
                            <span className='module-item-title placeholder col-8'></span>
                        </div>
                    )}
                </Col>
            </Row>
            <Row>
                <Col>
                    {props.eventOrganizerDisplayName && (
                        <p className='lead'>
                            by <NavLink to={`/user/${props.eventOrganizer}`}>{props.eventOrganizerDisplayName}</NavLink>
                        </p>
                    )}

                    {!props.eventOrganizerDisplayName && props.loading && (
                        <div className='placeholder-glow'>
                            <span className='module-item-title placeholder col-8'></span>
                        </div>
                    )}
                </Col>
            </Row>
            <Row>
                <Col>
                    {props.eventPublishDate && (
                        <p style={{ fontSize: 12 }}>
                            Posted on {new Intl.DateTimeFormat('default', { dateStyle: 'full', timeStyle: 'long' }).format(new Date(props.eventPublishDate))} in{' '}
                            <NavLink to={'/events/'}>Events</NavLink>
                        </p>
                    )}

                    {!props.eventPublishDate && props.loading && (
                        <div className='placeholder-glow'>
                            <span className='module-item-title placeholder col-8'></span>
                        </div>
                    )}
                </Col>
            </Row>
        </React.Fragment>
    )
}
