import * as React from 'react'
import { NavLink } from 'react-router-dom'
import { Button, Col, Input, InputGroup, Row } from 'reactstrap'

interface NewsHeaderProps {
    title: string
}

export default (props: NewsHeaderProps) => {
    return (
        <React.Fragment>
            <Row>
                <Col>
                    {props.title && <h1>{props.title}</h1>}
                    {!props.title && (
                        <div className='placeholder-glow'>
                            <span className='module-item-title placeholder col-8'></span>
                        </div>
                    )}
                </Col>
            </Row>
        </React.Fragment>
    )
}
