import { formatBytes } from '@/utils/helpers'
import * as React from 'react'
import { useCallback, useEffect, useState } from 'react'
import { FileError, FileRejection, useDropzone } from 'react-dropzone'
import styled from 'styled-components'

import { PostFileUploadItem } from '../../lib/DoomCentral'
import PostFilePreview from './PostFilePreview'

interface PostFilePreviewerProps {
    uploadedFiles: File[]
    removeFile: (fileName: string) => void
    descriptions: PostFileUploadItem[]
    updateDescription: (fileName: string, description: string) => void
}

export default (props: PostFilePreviewerProps) => {
    return (
        <React.Fragment>
            {props.uploadedFiles && props.uploadedFiles.length > 0 && (
                <PostFilePreviewerContainer>
                    {props.uploadedFiles.map((s, idx) => (
                        <PostFilePreview
                            file={s}
                            removeFile={props.removeFile}
                            description={props.descriptions.find((f) => f.fileName === s.name)?.description ?? ''}
                            updateDescription={props.updateDescription}
                        />
                    ))}
                </PostFilePreviewerContainer>
            )}
        </React.Fragment>
    )
}

const PostFilePreviewerContainer = styled.div`
    padding: 7px 10px;
    margin: 0;
    font-size: 13px;
    line-height: 16px;
    color: var(--bs-secondary-text-emphasis);
    background-color: var(--bs-secondary-bg);
    border: 1px solid var(--bs-secondary-color);
    border-top: 0;
    border-top-color: currentcolor;
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
`
