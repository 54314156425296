import { countries } from '../countries'
import { EventRegion } from '../lib/DoomCentral'

export const getFlagForEventRegion = (region: EventRegion): string => {
    if (region === EventRegion.Global) {
        return countries.find((f) => f.code == 'GL').flag
    } else if (region === EventRegion.Americas) {
        return countries.find((f) => f.code == 'US').flag
    } else if (region === EventRegion.Europe) {
        return countries.find((f) => f.code == 'EU').flag
    } else if (region === EventRegion.Asia) {
        return countries.find((f) => f.code == 'CN').flag
    } else if (region === EventRegion.Australia) {
        return countries.find((f) => f.code == 'AU').flag
    }
    return countries.find((f) => f.code == 'GL').flag
}

export const getEventDate = (startDate: Date, endDate: Date): string => {
    const today = new Date()

    if (today > endDate) {
        return 'Completed'
    } else if (startDate < today && endDate > today) {
        return 'Live now!'
    } else {
        return 'Ends on ' + new Date(endDate).toLocaleDateString()
    }
}

export const getEventTime = (startDate: Date, endDate: Date): string => {
    const today = new Date()

    if (today > endDate) {
        return 'Completed'
    } else if (startDate < today && endDate > today) {
        return 'Live until ' + new Date(endDate).toLocaleTimeString()
    } else {
        return new Date(endDate).toLocaleTimeString()
    }
}
