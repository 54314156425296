import { DoomCentralEventsClient } from '@/lib/DoomCentral'
import { useQuery } from '@tanstack/react-query'

export const useEvents = () => {
    return useQuery({
        queryKey: ['events'],
        queryFn: async () => {
            const client = new DoomCentralEventsClient()
            const { liveEventItems, pastEventItems, upcomingEventItems } = await client.getEvents(1, 10, 1, 10)
            return { liveEventItems, pastEventItems, upcomingEventItems }
        },
    })
}
