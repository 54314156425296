import AuthorizeRoute from '@/components/api-authorization/AuthorizeRoute'
import ForumThreadListing from '@/components/forumthreadlisting/ForumThreadListing'
import NewThread from '@/components/newthread/NewThread'
import ThreadListing from '@/components/threadlisting/ThreadListing'
import DoomCentralRoles from '@/lib/DoomCentralRoles'
import { ForumPage } from '@/pages/forum/Forum'
// import ForumListing from './forumlisting/ForumListing'
import { ForumsPage } from '@/pages/forums/Forums'
import { ThreadPage } from '@/pages/thread/ThreadPage'
import * as React from 'react'
import { Route } from 'react-router'

export default () => (
    <React.Fragment>
        <AuthorizeRoute path='/newthread' component={NewThread} componentroles={[DoomCentralRoles.DoomCentralUser]} />
        <Route exact path='/threads' component={ThreadListing} />
        <Route exact path='/forums' component={ForumsPage} />
        <Route exact path='/thread/:id' component={ThreadPage} />
        <Route exact path='/forum/:id' component={ForumPage} />
    </React.Fragment>
)
