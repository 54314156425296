import { PostStation2 } from '@/components/poststation2/PostStation2'
import { PostStation2Provider } from '@/contexts/PostStation2Context'
import { PostItemDto, ThreadDetailDto } from '@/lib/DoomCentral'
import { RefMDEditor } from '@uiw/react-md-editor'
import { ForwardedRef, LegacyRef, forwardRef } from 'react'

export type ReplyEditorProps = {
    meta: ThreadDetailDto
    quotedPost?: PostItemDto
}
export const ReplyEditor = forwardRef<ForwardedRef<LegacyRef<RefMDEditor>>, ReplyEditorProps>(({ meta, quotedPost = null }, ref) => {
    return (
        <div>
            <PostStation2Provider meta={meta} quotedPost={quotedPost}>
                <PostStation2 ref={ref} />
            </PostStation2Provider>
        </div>
    )
})
