import * as React from 'react'
import { useContext, useEffect, useState } from 'react'
import { Col, Row } from 'reactstrap'

import { IErrorResponse } from '../../ErrorResponse'
import { IPromotedItemDto, IPromotedItemsVm, ISidebarVm, PromotionsClient, SidebarClient } from '../../lib/DoomCentral'
import { ErrorContext } from '../../state'
import HelmetPages from '../helmet/HelmetPages'
import Sidebars from '../sidebars/Sidebars'
import SearchAddPromotions from './SearchAddPromotions'
import SetPromotionOrder from './SetPromotionOrder'
import SubmitNewPromotions from './SubmitNewPromotions'

export default () => {
    const error = useContext(ErrorContext)

    const [loading, setLoading] = useState<boolean>(false)

    const [promotionData, setPromotionData] = useState<IPromotedItemDto[]>([])
    const [sidebarData, setSidebarData] = useState<ISidebarVm>()

    const [modPromotions, setModPromotions] = useState<number>(0)

    // Initial load for the edit promotion page.
    useEffect(() => {
        const fetchData = async () => {
            setLoading(true)
            try {
                let client = new SidebarClient()
                const response = await client.getSidebars().then((response) => response.toJSON() as Promise<ISidebarVm>)
                setSidebarData(response)
            } catch (e) {
                error.setError(e.response as IErrorResponse)
            }
            setLoading(false)
        }

        fetchData()
    }, [])

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true)
            try {
                let client = new PromotionsClient()
                const response = await client.getPromotions().then((response) => response.toJSON() as Promise<IPromotedItemsVm>)
                setPromotionData(response.promotedItems)
            } catch (e) {
                error.setError(e.response as IErrorResponse)
            }
            setLoading(false)
        }

        fetchData()
    }, [])

    return (
        <React.Fragment>
            <HelmetPages title='Doom Central - Edit Promotions' />
            <Sidebars loading={loading} newsItems={sidebarData?.newsItems} recentThreads={sidebarData?.recentThreads}>
                <div className='maincontent-container p-4'>
                    <Row>
                        <Col>
                            <SetPromotionOrder promotions={promotionData} setPromotions={setPromotionData} />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <SearchAddPromotions promotions={promotionData} setPromotions={setPromotionData} modPromotion={modPromotions} setModPromotion={setModPromotions} />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <SubmitNewPromotions promotions={promotionData} />
                        </Col>
                    </Row>
                </div>
            </Sidebars>
        </React.Fragment>
    )
}
