import * as React from 'react'
import { useContext, useState } from 'react'
import { Button } from 'reactstrap'

import { IErrorResponse } from '../../ErrorResponse'
import { ApproveThreadPostCommand, DeleteThreadPostCommand, LockThreadPostCommand, ThreadPostsClient, UnlockThreadPostCommand } from '../../lib/DoomCentral'
import { ErrorContext } from '../../state'

interface PostModeratorToolsProps {
    postId: number
    setModPost: React.Dispatch<React.SetStateAction<number>>
    locked: boolean
    approved: boolean
}

export default (props: PostModeratorToolsProps) => {
    const error = useContext(ErrorContext)

    const [loading, setLoading] = useState<boolean>(false)

    const lockPost = async () => {
        setLoading(true)
        try {
            let client = new ThreadPostsClient()
            let command = new LockThreadPostCommand()
            command.postId = props.postId

            const response = await client.lock(command).then((response) => response)
            props.setModPost(response)
        } catch (e) {
            error.setError(e.response as IErrorResponse)
        }
        setLoading(false)
    }

    const unlockPost = async () => {
        setLoading(true)
        try {
            let client = new ThreadPostsClient()
            let command = new UnlockThreadPostCommand()
            command.postId = props.postId

            const response = await client.unlock(command).then((response) => response)
            props.setModPost(response)
        } catch (e) {
            error.setError(e.response as IErrorResponse)
        }
        setLoading(false)
    }

    const approvePost = async () => {
        setLoading(true)
        try {
            let client = new ThreadPostsClient()
            let command = new ApproveThreadPostCommand()
            command.postId = props.postId

            const response = await client.approve(command).then((response) => response)
            props.setModPost(response)
        } catch (e) {
            error.setError(e.response as IErrorResponse)
        }
        setLoading(false)
    }

    const deletePost = async () => {
        setLoading(true)
        try {
            let client = new ThreadPostsClient()
            let command = new DeleteThreadPostCommand()
            command.postId = props.postId

            const response = await client.delete(props.postId, command).then((response) => response)
            props.setModPost(response)
        } catch (e) {
            error.setError(e.response as IErrorResponse)
        }
        setLoading(false)
    }

    return (
        <React.Fragment>
            <div className='post-moderator-tool-container'>
                <div className='post-moderator-tools'>
                    {!props.approved && (
                        <Button className='moderator-btn' color='success' onClick={approvePost}>
                            <i className='bi bi-check'></i>
                        </Button>
                    )}{' '}
                    {props.locked && (
                        <Button className='moderator-btn' color='warning' onClick={unlockPost}>
                            <i className='bi bi-unlock-fill'></i>
                        </Button>
                    )}{' '}
                    {!props.locked && (
                        <Button className='moderator-btn' color='danger' onClick={lockPost}>
                            <i className='bi bi-lock-fill'></i>
                        </Button>
                    )}{' '}
                    <Button className='moderator-btn' color='danger' onClick={deletePost}>
                        <i className='bi bi-trash3-fill'></i>
                    </Button>
                </div>
            </div>
        </React.Fragment>
    )
}
