import { useEffect, useState } from "react"

export const useTheme = () => {

  // Determine what the user's preferred theme is. 
  // If they have a preference, use that.  
  // If not, use the system preference. 
  const prefersDarkMode = window.matchMedia('(prefers-color-scheme: dark)').matches
  const userThemePreference = localStorage.getItem('theme')
  const initialTheme = userThemePreference || (prefersDarkMode ? 'dark' : 'light')

  const [theme, setTheme] = useState(initialTheme)

  const handleSetTheme = (selectedTheme: 'light' | 'dark' | 'system') => {

    if (selectedTheme === 'system') {
      selectedTheme = prefersDarkMode ? 'dark' : 'light'
    }

    setTheme(selectedTheme)
    localStorage.setItem('theme', selectedTheme);
    // Update the data-bs-theme attribute on the html tag for bootstrap,
    // and the data-color-mode attribute for the markdown editor.
    ['data-bs-theme', 'data-color-mode'].forEach(attr => {
      document.documentElement.setAttribute(attr, selectedTheme)
    })
  }

  useEffect(() => {
    const userThemePref = localStorage.getItem('theme')

    if (userThemePref) {
      setTheme(userThemePref);
      localStorage.setItem('theme', userThemePref);
      ['data-bs-theme', 'data-color-mode'].forEach(attr => {
        document.documentElement.setAttribute(attr, userThemePref)
      })
      return
    }

    ['data-bs-theme', 'data-color-mode'].forEach(attr => {
      document.documentElement.setAttribute(attr, 'dark')
    })
    localStorage.setItem('theme', 'dark');

  }, [])

  return { theme, setTheme: handleSetTheme }

}