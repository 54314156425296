import { FormatETA, FormatShortTimeString } from '@/utils/helpers'
import * as React from 'react'
import { Col, Row } from 'reactstrap'

import { EventRegion } from '../../lib/DoomCentral'
import { getFlagForEventRegion } from '../../utils/EventHelpers'

interface EventInfoProps {
    eventStartDate: Date
    eventEndDate: Date
    duration: string
    streamer: string
    streamerPic: string
    region: EventRegion
    loading: boolean
}

export default (props: EventInfoProps) => {
    return (
        <React.Fragment>
            <Row>
                <Col>
                    <div className='event-section-title'>ETA</div>
                    {props.eventEndDate && props.eventEndDate && !props.loading && <span className='event-eta'>{FormatETA(props.eventStartDate, props.eventEndDate)}</span>}
                    {props.loading && (
                        <div className='placeholder-glow'>
                            <span className='module-item-title placeholder col-3'></span>
                        </div>
                    )}
                </Col>
                <Col>
                    <div className='event-section-title'>Event Start</div>
                    {props.eventStartDate && !props.loading && (
                        <span className='event-eta'>
                            {new Intl.DateTimeFormat('default', { dateStyle: 'long' }).format(new Date(props.eventStartDate))}
                            <br />
                            {new Intl.DateTimeFormat('default', { timeStyle: 'long' }).format(new Date(props.eventStartDate))}
                        </span>
                    )}
                    {props.loading && (
                        <div className='placeholder-glow'>
                            <span className='module-item-title placeholder col-3'></span>
                            <span className='module-item-title placeholder col-3'></span>
                        </div>
                    )}
                </Col>
                <Col>
                    <div className='event-section-title'>Event Duration</div>
                    {props.duration && props.duration.length > 0 && !props.loading && <span className='event-eta'>{FormatShortTimeString(props.duration)}</span>}
                    {props.loading && (
                        <div className='placeholder-glow'>
                            <span className='module-item-title placeholder col-3'></span>
                        </div>
                    )}
                </Col>
                {((props.streamer && props.streamer.length > 0) || props.loading) && (
                    <Col>
                        <div className='event-section-title'>Stream</div>
                        {props.streamer && props.streamer.length > 0 && !props.loading && <p>{props.streamer}</p>}
                        {props.loading && (
                            <div className='placeholder-glow'>
                                <span className='module-item-title placeholder col-3'></span>
                            </div>
                        )}
                    </Col>
                )}
                <Col>
                    <div className='event-section-title'>Region</div>
                    {props.region && !props.loading && <p style={{ fontSize: 42 }}>{getFlagForEventRegion(props.region)}</p>}
                    {props.loading && (
                        <div className='placeholder-glow'>
                            <span className='module-item-title placeholder col-3'></span>
                        </div>
                    )}
                </Col>
            </Row>
        </React.Fragment>
    )
}
