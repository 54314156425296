import { Columns } from '@/components/columns/Columns'
import RelativeTime from '@yaireo/relative-time'
import { useContext, useEffect, useState } from 'react'
import { useAuth } from 'react-oidc-context'

import { CommunityNewsCard } from '../../components/community-news-card/CommunityNewsCard'
import { EventsCard } from '../../components/events-card/EventsCard'
import HelmetPages from '../../components/helmet/HelmetPages'
import { LoadingIndicator } from '../../components/loading-indicator/LoadingIndicator'
import { Promoted } from '../../components/promoted/Promoted'
import { RecentThreadsCard } from '../../components/recent-threads-card/RecentThreadsCard'
import { EventItemDto, ForumGroupDto, ForumsClient, HomeClient, NewsItemsDto, PromotedItemDto, RecentThreadDto } from '../../lib/DoomCentral'
import { ErrorContext } from '../../state'

type HomePageData = {
    forumGroups: ForumGroupDto[]
    eventItems: EventItemDto[]
    newsItems: NewsItemsDto[]
    promotedItems: PromotedItemDto[]
    recentThreads: RecentThreadDto[]
}

const relativeTime = new RelativeTime()

const forumsClient = new ForumsClient()

export const Home = () => {
    const error = useContext(ErrorContext)
    const auth = useAuth()

    const [isLoading, setIsLoading] = useState(false)
    const [pageData, setPageData] = useState<HomePageData>()

    const getHomePageData = async () => {
        const { forumGroups } = await forumsClient.getForumListing()
        const { eventItems, newsItems, promotedItems, recentThreads } = await new HomeClient().getHomePage()

        const data = {} as HomePageData
        data.forumGroups = forumGroups
        data.eventItems = eventItems
        data.newsItems = newsItems
        data.promotedItems = promotedItems
        data.recentThreads = recentThreads

        return data
    }

    useEffect(() => {
        ;(async () => {
            setIsLoading(true)
            try {
                const homePageData = await getHomePageData()
                setPageData(homePageData)
            } catch (e) {
                console.error(e)
                error.setError(e)
            } finally {
                setIsLoading(false)
            }
        })()
    }, [])

    return (
        <>
            <HelmetPages title='Doom Central - Home' />
            <Columns
                rightColumn={
                    <div>
                        <CommunityNewsCard className='mb-3' />
                        <EventsCard className='mb-3' />
                        <RecentThreadsCard className='mb-3' />
                    </div>
                }
            >
                {isLoading ? (
                    <LoadingIndicator />
                ) : pageData ? (
                    <>
                        <Promoted loading={isLoading} promotedItems={pageData?.promotedItems} />
                    </>
                ) : (
                    <div>No page data</div>
                )}
            </Columns>
        </>
    )
}
