import { Columns } from '@/components/columns/Columns'
import { CommunityNewsCard } from '@/components/community-news-card/CommunityNewsCard'
import { EventsCard } from '@/components/events-card/EventsCard'
import HelmetPages from '@/components/helmet/HelmetPages'
import { LoadingIndicator } from '@/components/loading-indicator/LoadingIndicator'
import { PostList } from '@/components/postlist/PostList'
import { ThreadHeader } from '@/components/thread-header/ThreadHeader'
import { useCapabilities } from '@/hooks/useCapabilities'
import { usePaginatedThread } from '@/hooks/usePaginatedThread'
import { PostItemDto } from '@/lib/DoomCentral'
import { ThreadModTools } from '@/pages/thread/ThreadModTools'
import { cn } from '@/utils/utils'
import { RefMDEditor } from '@uiw/react-md-editor'
import { ForwardedRef, LegacyRef, useRef, useState } from 'react'
import Pager from 'react-paginate'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { Input, InputGroup, Label } from 'reactstrap'

import { ReplyEditor } from './ReplyEditor'
import { ReadPostsContextProvider } from '@/contexts/ReadPostsContext'
import { useScrollToHash } from '@/hooks/useScrollToHash'

export const ThreadPage = () => {

    const editorRef = useRef<ForwardedRef<LegacyRef<RefMDEditor>>>(null)

    const { id } = useParams<{ id: string }>()

    const { search, hash } = useLocation()
    const searchParams = new URLSearchParams(search)

    const history = useHistory()

    // pager data
    const [page, setPage] = useState(+searchParams.get('page') || 1)
    const [limit, setLimit] = useState(+searchParams.get('pageSize') || 5)

    const query = usePaginatedThread({ id, page, limit })

    const { isGlobalAdmin, isGlobalForumAdmin } = useCapabilities()

    const [quotedPost, setQuotedPost] = useState(null as PostItemDto)

    useScrollToHash(hash)

    if (query.isLoading) {
        return <LoadingIndicator />
    }

    if (!query.isSuccess) {
        return <div>Error getting data</div>
    }

    const onQuotePostClick = (post: PostItemDto) => {
        if (editorRef.current) {
            console.log(editorRef.current)
            // @ts-ignore
            editorRef.current.textarea.scrollIntoView({ behavior: 'smooth' })
            // @ts-ignore
            editorRef.current.textarea.focus()
        }
        setQuotedPost(post)
    }

    const showPager = query.data.totalPages > 1

    return (
        <>
            <HelmetPages title={`Doom Central - ${query?.data?.meta?.title ?? 'Loading...'}`} />

            <Columns
                rightColumn={
                    <div>
                        {(isGlobalAdmin || isGlobalForumAdmin) && (
                            <div className='mb-3'>
                                <ThreadModTools id={+id} />
                            </div>
                        )}
                        <div className='mb-3'>
                            <CommunityNewsCard />
                        </div>
                        <div className='mb-3'>
                            <EventsCard />
                        </div>
                    </div>
                }
            >
                <ThreadHeader id={+id} />
                <div>
                    {showPager && (
                        <div className='d-flex my-3'>
                            <InputGroup className='d-flex align-items-center me-3' style={{ maxWidth: 160 }}>
                                <Label className='m-0 me-1'>Page Size</Label>
                                <Input
                                    className='rounded-0'
                                    name='select'
                                    type='select'
                                    value={limit}
                                    onChange={(e) => {
                                        const params = new URLSearchParams(searchParams)
                                        params.set('pageSize', e.target.value)
                                        history.replace({ pathname: location.pathname, search: params.toString() })
                                        setLimit(+e.target.value)
                                    }}
                                >
                                    {[5, 10, 15, 20, 25].map((n) => (
                                        <option key={n}>{n.toString()}</option>
                                    ))}
                                </Input>
                            </InputGroup>

                            <Pager
                                previousLabel={'←'}
                                nextLabel={'→'}
                                pageCount={query?.data?.totalPages}
                                onPageChange={(p) => {
                                    const params = new URLSearchParams(searchParams)
                                    params.set('page', `${p.selected + 1}`)
                                    history.replace({ pathname: location.pathname, search: params.toString() })
                                    setPage(p.selected + 1)
                                }}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={3}
                                renderOnZeroPageCount={null}
                                containerClassName={cn('pagination', 'm-0', 'w-100')}
                                previousLinkClassName={'page-link'}
                                nextLinkClassName={'page-link'}
                                disabledClassName={'page-item disabled'}
                                activeClassName={'page-item active'}
                                activeLinkClassName={'page-link'}
                                nextClassName={'page-item'}
                                previousClassName={'page-item'}
                                breakClassName={'page-item'}
                                breakLinkClassName={'page-link'}
                                pageClassName={'page-item'}
                                pageLinkClassName={'page-link'}
                            />
                        </div>
                    )}
                    <ReadPostsContextProvider threadId={id}>
                        <PostList meta={query?.data?.meta} posts={query?.data?.posts} onQuotePostClick={onQuotePostClick} />
                    </ReadPostsContextProvider>
                </div>

                <ReplyEditor ref={editorRef} meta={query.data.meta} quotedPost={quotedPost} />
            </Columns>
        </>
    )
}
