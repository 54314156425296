import * as React from 'react'
import { Col, Row } from 'reactstrap'

import MarkdownPost from '../markdown-post/MarkdownPost'

interface EventDescriptionProps {
    postContent: string
}

export default (props: EventDescriptionProps) => {
    return (
        <React.Fragment>
            <Row>
                <Col>
                    <div className='event-section-title'>About</div>
                    <MarkdownPost content={props.postContent} />
                </Col>
            </Row>
        </React.Fragment>
    )
}
