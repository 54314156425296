import { formatBytes } from '@/utils/helpers'
import * as React from 'react'

interface DataCounterProps {
    maxFileSize: number
}

export default (props: DataCounterProps) => {
    return (
        <React.Fragment>
            <p className='text-muted'>{formatBytes(props.maxFileSize)}</p>
        </React.Fragment>
    )
}
