// bootstrap the app with bootstrap (and bootstrap accessories)
import 'bootstrap-icons/font/bootstrap-icons.css'
// create and mount the react app
import { createRoot } from 'react-dom/client'

import App from './App'
import registerServiceWorker from './registerServiceWorker'
import reportWebVitals from './reportWebVitals'
import './theme/custom.scss'

const container = document.getElementById('root')
const root = createRoot(container!)

root.render(<App />)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
registerServiceWorker()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
