import * as React from 'react'
import { useContext } from 'react'
import { Button, Row, Table } from 'reactstrap'

import { IErrorResponse } from '../../ErrorResponse'
import { DeleteSubscriptionCommand, IPromotedItemDto, IThreadSubscriptionDto, SubscriptionClient } from '../../lib/DoomCentral'
import { ErrorContext } from '../../state'

interface SubscriptionListProps {
    subscriptions: IThreadSubscriptionDto[]
    setModSub: React.Dispatch<React.SetStateAction<number>>
}

export default (props: SubscriptionListProps) => {
    const error = useContext(ErrorContext)

    const submitDeleteSubscription = async (id: number) => {
        try {
            let client = new SubscriptionClient()
            let command = new DeleteSubscriptionCommand()
            command.subscriptionId = id
            const response = await client.deleteSubscriptions(command).then((response) => response)
            props.setModSub(response)
        } catch (e) {
            error.setError(e.response as IErrorResponse)
        }
    }

    return (
        <React.Fragment>
            <Table>
                <thead>
                    <tr>
                        <th>Title</th>
                        <th>Author</th>
                        <th>Created</th>
                        <th>Last Post Date</th>
                        <th>Manage</th>
                    </tr>
                </thead>
                <tbody>
                    {props.subscriptions &&
                        props.subscriptions.map((s, idx) => (
                            <tr key={s.id}>
                                <td>{s.threadName}</td>
                                <td>{s.authorName}</td>
                                <td>{new Intl.DateTimeFormat('default', { dateStyle: 'full', timeStyle: 'long' }).format(new Date(s.created))}</td>
                                <td>{new Intl.DateTimeFormat('default', { dateStyle: 'full', timeStyle: 'long' }).format(new Date(s.lastUpdate))}</td>
                                <td>
                                    <Button color='danger' className='ms-auto' onClick={(e) => submitDeleteSubscription(s.id)}>
                                        <i className='bi bi-trash'></i>
                                    </Button>
                                </td>
                            </tr>
                        ))}
                </tbody>
            </Table>
        </React.Fragment>
    )
}
