import { containsDuplicate } from '@/utils/helpers'
import RelativeTime from '@yaireo/relative-time'
import { useContext, useEffect, useState } from 'react'
import { useAuth } from 'react-oidc-context'
import { NavLink } from 'react-router-dom'
import { Card, CardBody, CardDeck, CardSubtitle, CardTitle, Col, List, ListGroup, ListGroupItem, Row } from 'reactstrap'

import { IErrorResponse } from '../../ErrorResponse'
import { ForumGroupDto, ForumsClient } from '../../lib/DoomCentral'
import DoomCentralEventRoles from '../../lib/DoomCentralEventRoles'
import DoomCentralRoles from '../../lib/DoomCentralRoles'
import ForumRoles from '../../lib/ForumRoles'
import NewsPostRoles from '../../lib/NewsPostRoles'
import { ErrorContext } from '../../state'
import { ActivityIcon } from '../icons/Icons'
import { LoadingIndicator } from '../loading-indicator/LoadingIndicator'

const relativeTime = new RelativeTime()

export const ForumList = () => {
    const auth = useAuth()
    const adminMenuRoles = [
        DoomCentralRoles.GlobalAdministrator,
        DoomCentralRoles.PromotionAdministrator,
        ForumRoles.GlobalForumAdministrator,
        DoomCentralEventRoles.GlobalEventAdministrator,
        NewsPostRoles.GlobalNewsAdministrator,
    ]

    const canModForums =
        auth.user &&
        (auth.user?.profile.role as string[]) &&
        containsDuplicate([DoomCentralRoles.GlobalAdministrator, ForumRoles.GlobalForumAdministrator], auth.user?.profile.role as string[])

    const error = useContext(ErrorContext)
    const [loading, setLoading] = useState<boolean>(false)

    const [pageData, setPageData] = useState<ForumGroupDto[]>([])

    useEffect(() => {
        ;(async () => {
            setLoading(true)
            try {
                const client = new ForumsClient()
                const { forumGroups } = await client.getForumListing()
                setPageData(forumGroups)
            } catch (e) {
                error.setError(e.response as IErrorResponse)
            } finally {
                setLoading(false)
            }
        })()
    }, [])

    return (
        <>
            {loading && <LoadingIndicator />}

            <Card>
                <CardBody>
                    <h1 className='mb-3'>Doom Central Forums</h1>

                    <ListGroup flush>
                        {pageData.map(({ forums, groupDescription, groupName }) => {
                            return (
                                <ListGroup key={groupName} flush>
                                    <ListGroupItem>
                                        <h2>{groupName}</h2>
                                        {groupDescription && <h5>{groupDescription}</h5>}
                                        {forums.length > 0 ? (
                                            <ListGroup flush>
                                                {forums.map(({ id, description, name, threadCount, postCount, hidden, locked, moderated, lastActive, unread, highestPostId }) => {
                                                    if (hidden && !canModForums) {
                                                        return <></>
                                                    }

                                                    return (
                                                        <ListGroupItem key={id} className='border-0'>
                                                            <Row>
                                                                <Col xs='12' sm='12'>
                                                                    <NavLink to={`/forum/${id}`}>{name}</NavLink>
                                                                    {description && <p>{description}</p>}
                                                                    {lastActive && (
                                                                        <p>
                                                                            <ActivityIcon className='me-1' style={{ width: '1rem', height: '1rem' }} />
                                                                            <span className='visually-hidden'>Latest activity: </span> {lastActive.postAuthorDisplayName} posted{' '}
                                                                            <NavLink to={`/thread/${lastActive.threadId}`}>{relativeTime.from(lastActive.created)}</NavLink>
                                                                        </p>
                                                                    )}
                                                                </Col>
                                                                {/* <Col xs='auto' className='d-flex align-items-start'>
                                                        {threadCount} Threads
                                                    </Col>
                                                    <Col xs='auto' className='d-flex align-items-start'>
                                                        {postCount} Posts
                                                    </Col> */}
                                                            </Row>
                                                        </ListGroupItem>
                                                    )
                                                })}
                                            </ListGroup>
                                        ) : (
                                            <div>No forums available.</div>
                                        )}
                                    </ListGroupItem>
                                </ListGroup>
                            )
                        })}
                    </ListGroup>
                </CardBody>
            </Card>
        </>
    )
}
