import { HomeClient } from '@/lib/DoomCentral'
import { useQuery } from '@tanstack/react-query'

export const useCommunityNews = () => {
    return useQuery({
        queryKey: ['community-news'],
        queryFn: async () => {
            const client = new HomeClient()
            const data = await client.getHomePage()
            return data.newsItems
        },
    })
}
