import * as React from 'react'
import { useContext, useState } from 'react'
import { Button } from 'reactstrap'

import { DeleteForumCommand, ForumsClient, IForumDto } from '../../../../lib/DoomCentral'
import { ErrorContext } from '../../../../state'

interface DeleteForumProps {
    setDeletedForum: React.Dispatch<React.SetStateAction<number>>
    selectedForum: IForumDto
    loading: boolean
}

export default (props: DeleteForumProps) => {
    const error = useContext(ErrorContext)

    const [loading, setLoading] = useState<boolean>(false)

    const submitForumGroup = async () => {
        setLoading(true)
        try {
            let client = new ForumsClient()
            let command = new DeleteForumCommand()
            command.id = props.selectedForum.id

            const response = await client.delete(props.selectedForum.id, command).then((response) => response)
            props.setDeletedForum(response)
        } catch (e) {
            error.setError(JSON.parse(e.response))
        }
        setLoading(false)
    }

    return (
        <React.Fragment>
            <div className='d-flex'>
                <div className='ms-auto'>
                    <Button onClick={submitForumGroup} disabled={!props.selectedForum || loading} color='danger'>
                        Delete Forum
                    </Button>
                </div>
            </div>
        </React.Fragment>
    )
}
