import { containsDuplicate } from '@/utils/helpers'
import * as React from 'react'
import { useContext, useEffect, useState } from 'react'
import { useAuth } from 'react-oidc-context'
import { Card, CardBody, CardTitle } from 'reactstrap'

import { IErrorResponse } from '../../ErrorResponse'
import { DoomCentralEventsClient, IEventsPageVm } from '../../lib/DoomCentral'
import DoomCentralEventRoles from '../../lib/DoomCentralEventRoles'
import DoomCentralRoles from '../../lib/DoomCentralRoles'
import { ErrorContext } from '../../state'
import HelmetPages from '../helmet/HelmetPages'
import Sidebars from '../sidebars/Sidebars'
import EventAdmin from './EventAdmin'
import EventList from './EventList'
import EventOptions from './EventOptions'
import EventPagination from './EventPagination'

export enum EventsPageMode {
    AllEvents = 0,
    MyEvents = 1,
}

export default () => {
    const error = useContext(ErrorContext)
    const auth = useAuth()
    const [loading, setLoading] = useState<boolean>(false)
    const [isAdmin, setIsAdmin] = useState<boolean>(false)
    const [upcomingEventsPageNumber, setUpcomingEventsPageNumber] = useState<number>(1)
    const [upcomingEventsPageSize, setUpcomingEventsPageSize] = useState<number>(10)
    const [pastEventsPageNumber, setPastEventPageNumber] = useState<number>(1)
    const [pastEventsPageNumberPageSize, setPastEventsPageNumberPageSize] = useState<number>(10)
    const [mode, setMode] = useState<EventsPageMode>(EventsPageMode.AllEvents)

    const [eventListingData, setEventListingData] = useState<IEventsPageVm>()

    useEffect(() => {
        if (
            auth.user &&
            (auth.user?.profile.role as string[]) &&
            containsDuplicate(
                [DoomCentralEventRoles.GlobalEventAdministrator, DoomCentralEventRoles.EventOrganizer, DoomCentralRoles.GlobalAdministrator],
                auth.user?.profile.role as string[],
            )
        ) {
            setIsAdmin(true)
        } else {
            setIsAdmin(false)
        }
    }, [auth])

    useEffect(() => {
        if (mode === EventsPageMode.AllEvents) {
            const fetchData = async () => {
                setLoading(true)
                try {
                    let client = new DoomCentralEventsClient()
                    const response = await client
                        .getEvents(upcomingEventsPageNumber, upcomingEventsPageSize, pastEventsPageNumber, pastEventsPageNumberPageSize)
                        .then((response) => response.toJSON() as Promise<IEventsPageVm>)
                    setEventListingData(response)
                } catch (e) {
                    error.setError(e.response as IErrorResponse)
                }
                setLoading(false)
            }

            fetchData()
        } else if (mode === EventsPageMode.MyEvents) {
            const fetchData = async () => {
                setLoading(true)
                try {
                    let client = new DoomCentralEventsClient()
                    const response = await client
                        .getEvents(upcomingEventsPageNumber, upcomingEventsPageSize, pastEventsPageNumber, pastEventsPageNumberPageSize)
                        .then((response) => response.toJSON() as Promise<IEventsPageVm>)
                    setEventListingData(response)
                } catch (e) {
                    error.setError(e.response as IErrorResponse)
                }
                setLoading(false)
            }

            fetchData()
        }
    }, [upcomingEventsPageNumber, upcomingEventsPageSize, pastEventsPageNumber, pastEventsPageNumberPageSize, mode])

    return (
        <>
            <HelmetPages title='Doom Central - Events' />
            <div>
                {isAdmin && <EventAdmin mode={mode} setMode={setMode} />}
                {eventListingData?.liveEventItems?.eventList.length > 0 && (
                    <>
                        <h2>Events Live Now!</h2>
                        <EventList events={eventListingData?.liveEventItems?.eventList} auth={auth} />
                    </>
                )}
                <Card className='mb-3'>
                    <CardBody>
                        <div className='d-flex flex-row justify-content-between mb-3'>
                            <div className='flex-grow'>
                                <CardTitle tag='h5'>Upcoming Events</CardTitle>
                            </div>
                            <div className='d-flex flex-row justify-content-end align-items-center'>
                                <EventOptions pageSize={upcomingEventsPageSize} setPageSize={setUpcomingEventsPageSize} />
                                <EventPagination pageCount={eventListingData?.upcomingEventItems?.totalPages ?? 0} setPageChange={setUpcomingEventsPageNumber} />
                            </div>
                        </div>
                        <EventList events={eventListingData?.upcomingEventItems?.eventList} auth={auth} />
                    </CardBody>
                </Card>
                <Card>
                    <CardBody>
                        <div className='d-flex flex-row justify-content-between mb-3'>
                            <div className='flex-row'>
                                <CardTitle tag='h5'>Past Events</CardTitle>
                            </div>
                            <div className='d-flex flex-row justify-content-end align-items-center'>
                                <EventOptions pageSize={pastEventsPageNumberPageSize} setPageSize={setPastEventsPageNumberPageSize} />
                                <EventPagination pageCount={eventListingData?.pastEventItems?.totalPages ?? 0} setPageChange={setPastEventPageNumber} />
                            </div>
                        </div>
                        <EventList events={eventListingData?.pastEventItems?.eventList} auth={auth} />
                    </CardBody>
                </Card>
            </div>
        </>
    )
}
