import { navigateToReturnUrl } from '@/utils/helpers'
import { useEffect } from 'react'

import { getReturnUrl } from './AuthHelpers'

const LogoutCallback = () => {
    useEffect(() => {
        ;(async () => {
            localStorage.clear()
            await navigateToReturnUrl(getReturnUrl(null))
        })()
    }, [])

    return <div>Processing logout callback</div>
}

export default LogoutCallback
