import { UserCanPostInNewsThread, UserIsNewsAdmin } from '@/utils/helpers'
import * as React from 'react'
import { useContext, useEffect, useState } from 'react'
import { useAuth } from 'react-oidc-context'
import { RouteComponentProps } from 'react-router'

import { INewsPostVm, NewsClient } from '../../lib/DoomCentral'
import { ErrorContext } from '../../state'
import HelmetPages from '../helmet/HelmetPages'
import Reactions from '../reactions/Reactions'
import Sidebars from '../sidebars/Sidebars'
import SingleMediaContainer from '../singlemediacontainer/SingleMediaContainer'
import NewsAuthorInfo from './NewsAuthorInfo'
import NewsContent from './NewsContent'
import NewsHeader from './NewsHeader'

interface NewsPageRouterProps {
    id: string
}

interface NewsPageProps extends RouteComponentProps<NewsPageRouterProps> {
    // Add your regular properties here
}

export default (props: NewsPageProps) => {
    const auth = useAuth()

    const error = useContext(ErrorContext)

    const [loading, setLoading] = useState<boolean>(false)
    const [canEdit, setCanEdit] = useState<boolean>(false)

    const [newsPageData, setNewsPageData] = useState<INewsPostVm>()

    // Initial load for the thread page.
    useEffect(() => {
        const fetchData = async () => {
            setLoading(true)
            try {
                let client = new NewsClient()
                const response = await client.getNewsById(parseInt(props.match.params.id)).then((response) => response.toJSON() as Promise<INewsPostVm>)
                setNewsPageData(response)
            } catch (e) {
                error.setError(e.response)
            }
            setLoading(false)
        }

        fetchData()
    }, [])

    useEffect(() => {
        if (UserIsNewsAdmin(auth.user, newsPageData?.newsDetails)) {
            setCanEdit(true)
        } else {
            setCanEdit(false)
        }
    }, [auth, newsPageData])

    return (
        <React.Fragment>
            <HelmetPages title={`Doom Central - News - ${newsPageData?.newsDetails?.title ?? 'Loading...'}`} />
            <Sidebars loading={loading} newsItems={newsPageData?.newsItems} recentThreads={newsPageData?.recentThreads}>
                <div className='maincontent-container p-4'>
                    <SingleMediaContainer
                        loading={loading}
                        id={newsPageData?.newsDetails?.threadId ?? 0}
                        authorized={UserCanPostInNewsThread(auth.user, newsPageData?.newsDetails)}
                        isAdmin={canEdit}
                        maxFilesInPost={newsPageData?.newsDetails?.maxFilesPerPost ?? 0}
                        maxFileSizeBytes={newsPageData?.newsDetails?.maxFileSize ?? 0}
                        allowedFileExtensions={newsPageData?.newsDetails?.allowedFileExtensions ?? ''}
                    >
                        <NewsHeader title={newsPageData?.newsDetails?.title} />
                        <NewsAuthorInfo
                            newsDate={newsPageData?.newsDetails?.newsDate}
                            newsAuthor={newsPageData?.newsDetails?.newsPublisherUsername}
                            newsAuthorDisplayName={newsPageData?.newsDetails?.newsPublisherDisplayName}
                        />
                        <NewsContent content={newsPageData?.newsDetails?.content} />
                        <Reactions />
                    </SingleMediaContainer>
                </div>
            </Sidebars>
        </React.Fragment>
    )
}
