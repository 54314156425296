import { LiveIcon } from '@/components/icons/Icons'
import { LoadingIndicator } from '@/components/loading-indicator/LoadingIndicator'
import { useEvents } from '@/hooks/useEvents'
import { getFlagForEventRegion } from '@/utils/EventHelpers'
import { Card, CardBody, CardHeader, CardTitle, ListGroup, ListGroupItem } from 'reactstrap'

export const EventsCard = ({ ...rest }) => {
    const { isLoading, isError, data } = useEvents()

    if (isLoading) {
        return (
            <Card>
                <CardBody style={{ minHeight: 64 }}>
                    <LoadingIndicator />
                </CardBody>
            </Card>
        )
    }

    if (isError) {
        ; <Card>
            <CardBody style={{ minHeight: 64 }}>
                <div>Could not get events.</div>
            </CardBody>
        </Card>
    }

    const { liveEventItems: liveEvents, pastEventItems: pastEvents, upcomingEventItems: upcomingEvents } = data

    return (
        <Card {...rest}>
            <CardBody style={{ minHeight: 64 }}>
                <CardTitle tag='h2' className='mb-3'>
                    Events
                </CardTitle>
                <>
                    {liveEvents.eventList.length === 0
                        ? <p>No live events</p>
                        : (
                            <div className="mb-3">
                                <h5>Live Events</h5>
                                <ListGroup>
                                    {liveEvents.eventList.map(({ eventId, title, eventStartDate, eventEndDate, eventRegion, organizerId }) => (
                                        <ListGroupItem key={eventId}>
                                            <LiveIcon className='me-1' />
                                            {title}
                                        </ListGroupItem>
                                    ))}
                                </ListGroup>
                            </div>
                        )}

                    {upcomingEvents.eventList.length === 0
                        ? <p>No upcoming events</p>
                        : (
                            <div className="mb-3">
                                <h5>Upcoming Events</h5>
                                <ListGroup>
                                    {upcomingEvents.eventList.map(({ eventId, title, eventStartDate, eventEndDate, eventRegion, organizerId }) => (
                                        <ListGroupItem key={eventId} className="d-flex flex-row justify-content-between" action tag="a" href={`/event/${eventId}`}>
                                            <div>
                                                <span className="me-2">{getFlagForEventRegion(eventRegion)}</span>
                                                <span>
                                                    {new Date(eventStartDate).toLocaleDateString()}
                                                </span>
                                            </div>
                                            <h6 className="mb-0">
                                                {title}
                                            </h6>
                                        </ListGroupItem>
                                    ))}
                                </ListGroup>
                            </div>
                        )}

                    {pastEvents.eventList.length === 0
                        ? <p>No past events.</p>
                        : (
                            <div className="mb-3">
                                <h5>Past Events</h5>
                                <ListGroup>

                                    {pastEvents.eventList.map(({ eventId, title, eventStartDate, eventEndDate, eventRegion, organizerId }) => (
                                        <ListGroupItem key={eventId} className="d-flex flex-row justify-content-between" action tag="a" href={`/event/${eventId}`}>
                                            <div>
                                                <span className="me-2">{getFlagForEventRegion(eventRegion)}</span>
                                                <span>
                                                    {new Date(eventStartDate).toLocaleDateString()}
                                                </span>
                                            </div>
                                            <h6 className="mb-0">
                                                {title}
                                            </h6>
                                        </ListGroupItem>
                                    ))}
                                </ListGroup>
                            </div>
                        )}

                </>
            </CardBody>
        </Card>
    )
}
