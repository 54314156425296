import * as React from 'react'
import { NavLink } from 'react-router-dom'
import { Button, Col, Input, InputGroup, Row } from 'reactstrap'

import { countries } from '../../countries'

const Reactions = () => {
    return (
        <React.Fragment>
            <div className='reactions'>
                <div className='click-to-react'>React to this</div>
            </div>
        </React.Fragment>
    )
}

export default Reactions
