import { LoadingIndicator } from '@/components/loading-indicator/LoadingIndicator'
import { useQuotedPost } from '@/hooks/useQuotedPost'
import { useState } from 'react'
import { Accordion, AccordionBody, AccordionHeader, AccordionItem, Card, CardBody } from 'reactstrap'

import MarkdownPost from '../../components/markdown-post/MarkdownPost'

export type QuotedPost = {
    postId: number
    reply?: boolean
}

export const QuotedPost = ({ postId, reply = false }: QuotedPost) => {
    const { isLoading, error, post } = useQuotedPost({ id: postId })

    if (isLoading) {
        return <LoadingIndicator />
    }

    if (error) {
        console.error(error)
        return <div>Could not load post.</div>
    }

    const targetId = reply ? 'reply-to-post-' + post?.id : 'quote-post' + post?.id

    const [open, setOpen] = useState(reply ? targetId : '1')

    const toggle = (id: string) => (open === id ? setOpen('') : setOpen(id))

    return (
        <Accordion toggle={toggle} open={open}>
            <AccordionItem>
                <AccordionHeader targetId={targetId}>{reply ? `Replying to ${post?.postAuthorDisplayName}` : `${post?.postAuthorDisplayName} sayz`}</AccordionHeader>
                <AccordionBody accordionId={targetId}>
                    <Card>
                        <CardBody>
                            <MarkdownPost content={post?.postContent} />
                        </CardBody>
                    </Card>
                </AccordionBody>
            </AccordionItem>
        </Accordion>
    )
}
