import * as React from 'react'
import { useContext, useEffect, useState } from 'react'

import { EmotesClient, IEmoteListVm, IEmotesDto } from '../../../lib/DoomCentral'
import { ErrorContext } from '../../../state'
import HelmetPages from '../../helmet/HelmetPages'
import AdminPanel from '../AdminPanel'
import CreateNewReaction from './CreateNewReaction'
import DeleteReaction from './DeleteReaction'

export default () => {
    const [modReaction, setModReaction] = useState<number>(0)

    return (
        <React.Fragment>
            <HelmetPages title={'Doom Central - Manage Reactions'} />
            <AdminPanel>
                <h1>Manage Reactions</h1>
                <CreateNewReaction setModReaction={setModReaction} />
                <hr />
                <DeleteReaction modReaction={modReaction} setModReaction={setModReaction} />
            </AdminPanel>
        </React.Fragment>
    )
}
