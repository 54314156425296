import * as React from 'react'
import { useContext, useEffect, useState } from 'react'

import { DoomCentralEventsClient, IEventPaginatedListVm, IEventsPageVm } from '../../../lib/DoomCentral'
import { ErrorContext } from '../../../state'
import HelmetPages from '../../helmet/HelmetPages'
import AdminPanel from '../AdminPanel'
import EventOptions from './EventOptions'
import EventPagination from './EventPagination'
import EventTable from './EventTable'

export default () => {
    const error = useContext(ErrorContext)

    const [modEvent, setModEvent] = useState<number>(0)
    const [events, setEvents] = useState<IEventsPageVm>(null)
    const [loading, setLoading] = useState<boolean>(false)
    const [upcomingEventsPageNumber, setUpcomingEventsPageNumber] = useState<number>(1)
    const [upcomingEventsPageSize, setUpcomingEventsPageSize] = useState<number>(10)
    const [pastEventsPageNumber, setPastEventsPageNumber] = useState<number>(1)
    const [pastEventsPageSize, setPastEventsPageSize] = useState<number>(10)

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true)
            try {
                let client = new DoomCentralEventsClient()
                const response = await client
                    .getEvents(upcomingEventsPageNumber, upcomingEventsPageSize, pastEventsPageNumber, pastEventsPageSize)
                    .then((response) => response.toJSON() as Promise<IEventsPageVm>)
                setEvents(response)
            } catch (e) {
                error.setError(e.response)
            }
            setLoading(false)
        }

        fetchData()
    }, [modEvent])

    return (
        <React.Fragment>
            <HelmetPages title={'Doom Central - Manage Events'} />
            <AdminPanel>
                {!loading && events?.liveEventItems?.eventList?.length > 0 && (
                    <>
                        <h2>Live Events</h2>
                        <EventTable events={events?.liveEventItems?.eventList} setModEvent={setModEvent} loading={loading} />
                    </>
                )}
                <h2>Upcoming Events</h2>
                <EventPagination pageCount={events?.upcomingEventItems?.totalCount ?? 0} setPageChange={setUpcomingEventsPageNumber} />
                <EventOptions pageSize={upcomingEventsPageSize} setPageSize={setUpcomingEventsPageSize} />
                <EventTable events={events?.upcomingEventItems?.eventList} setModEvent={setModEvent} loading={loading} />
                <h2>Past Events</h2>
                <EventPagination pageCount={events?.pastEventItems?.totalCount ?? 0} setPageChange={setPastEventsPageNumber} />
                <EventOptions pageSize={pastEventsPageSize} setPageSize={setPastEventsPageSize} />
                <EventTable events={events?.pastEventItems?.eventList} setModEvent={setModEvent} loading={loading} />
            </AdminPanel>
        </React.Fragment>
    )
}
