import * as React from 'react'
import { Input, Label } from 'reactstrap'

interface EventOptionsProps {
    pageSize: number
    setPageSize: React.Dispatch<React.SetStateAction<number>>
}

export default (props: EventOptionsProps) => {
    return (
        <div className='d-flex align-items-center me-3'>
            <Label for='pageSizeSelectField' className='me-1' style={{ textWrap: 'nowrap' }}>
                Page size
            </Label>
            <Input id='pageSizeSelectField' type='select' value={props.pageSize} onChange={(e) => props.setPageSize(parseInt(e.target.value, 10))}>
                <option value={10}>10</option>
                <option value={25}>25</option>
                <option value={50}>50</option>
                <option value={100}>100</option>
            </Input>
        </div>
    )
}
