import * as React from 'react'
import { useContext, useEffect, useState } from 'react'

import { IErrorResponse } from '../../../ErrorResponse'
import { CountriesClient, ICountry, ICountryListVm } from '../../../lib/DoomCentral'
import { ErrorContext } from '../../../state'
import HelmetPages from '../../helmet/HelmetPages'
import AdminPanel from '../AdminPanel'
import CreateNewStreamer from './CreateNewStreamer'
import DeleteStreamer from './DeleteStreamer'

export default () => {
    const error = useContext(ErrorContext)

    const [modStreamer, setModStreamer] = useState<number>(0)
    const [countries, setCountries] = useState<ICountry[]>([])

    useEffect(() => {
        const fetchData = async () => {
            try {
                let client = new CountriesClient()
                const response = await client.get().then((response) => response.toJSON() as Promise<ICountryListVm>)
                setCountries(response.countries)
            } catch (e) {
                error.setError(e.response as IErrorResponse)
            }
        }

        fetchData()
    }, [])

    return (
        <React.Fragment>
            <HelmetPages title={'Doom Central - Manage Streamer'} />
            <AdminPanel>
                <h1>Manage Streamer</h1>
                <CreateNewStreamer countries={countries} setModStreamer={setModStreamer} />
                <hr />
                <DeleteStreamer modStreamer={modStreamer} setModStreamer={setModStreamer} />
            </AdminPanel>
        </React.Fragment>
    )
}
