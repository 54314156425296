import { ApprovedIcon, DeleteIcon, LockedIcon, ModeratedIcon, StickiedIcon, UnlockedIcon, UnmoderatedIcon, UnstickiedIcon } from '@/components/icons/Icons'
import { LoadingIndicator } from '@/components/loading-indicator/LoadingIndicator'
import { useThreadMeta } from '@/hooks/useThreadMeta'
import { useThreadModTools } from '@/hooks/useThreadModTools'
import { cn } from '@/utils/utils'
import { SquarePlusIcon } from 'lucide-react'
import { useHistory } from 'react-router'
import { Button, ButtonGroup, Card, CardBody } from 'reactstrap'

interface ThreadModToolsProps {
    id: number
}

export const ThreadModTools = ({ id }: ThreadModToolsProps) => {
    const { data, isLoading, isError } = useThreadMeta({ id })

    const {
        approveThreadMutation,
        deleteThreadMutation,
        lockThreadMutation,
        moderateThreadMutation,
        stickyThreadMutation,
        unlockThreadMutation,
        unmoderateThreadMutation,
        unstickyThreadMutation,
    } = useThreadModTools({ id })

    const history = useHistory()

    if (isLoading) {
        return <LoadingIndicator />
    }

    if (isError) {
        return <div>Error loading thread metadata</div>
    }

    const { approved, moderated, locked, stickied } = data

    return (
        <Card className='rounded-0'>
            <CardBody>
                <h2>Thread Mod Tools</h2>
                <ButtonGroup vertical className='rounded-0'>
                    <BaseThreadModToolButton color='primary' onClick={() => history.push('/newthread')} icon={<SquarePlusIcon />} label='New Thread' />
                    {!approved && <BaseThreadModToolButton onClick={() => approveThreadMutation.mutate()} icon={<ApprovedIcon />} label='Approve Thread' />}
                    {!locked && <BaseThreadModToolButton onClick={() => lockThreadMutation.mutate()} icon={<LockedIcon />} label='Lock Thread' />}
                    {locked && <BaseThreadModToolButton onClick={() => unlockThreadMutation.mutate()} icon={<UnlockedIcon />} label='Unlock Thread' />}
                    {!stickied && <BaseThreadModToolButton onClick={() => stickyThreadMutation.mutate()} icon={<StickiedIcon />} label='Sticky Thread' />}
                    {stickied && <BaseThreadModToolButton onClick={() => unstickyThreadMutation.mutate()} icon={<UnstickiedIcon />} label='Unsticky Thread' />}
                    {!moderated && <BaseThreadModToolButton onClick={() => moderateThreadMutation.mutate()} icon={<UnmoderatedIcon className='me-1' />} label='Moderate Thread' />}
                    {moderated && <BaseThreadModToolButton onClick={() => unmoderateThreadMutation.mutate()} icon={<ModeratedIcon />} label='Unmoderate Thread' />}
                    <BaseThreadModToolButton
                        color='danger'
                        onClick={() => deleteThreadMutation.mutate()}
                        icon={deleteThreadMutation.isPending ? <LoadingIndicator /> : <DeleteIcon />}
                        label={'Delete Thread'}
                    />
                </ButtonGroup>
            </CardBody>
        </Card>
    )
}

const BaseThreadModToolButton = ({ icon, label, ...rest }) => (
    <Button variant='secondary' className={cn('d-flex', 'flex-row', 'rounded-0')} outline style={{ border: 0 }} {...rest}>
        <span className='me-3'>{icon}</span>
        <span className='flex-1'>{label}</span>
    </Button>
)
