import { containsDuplicate } from '@/utils/helpers'
import * as React from 'react'
import { useContext, useEffect, useState } from 'react'
import { useAuth } from 'react-oidc-context'
import { Card, CardBody, CardTitle } from 'reactstrap'

import { IErrorResponse } from '../../ErrorResponse'
import { INewsListingPageVm, NewsClient } from '../../lib/DoomCentral'
import DoomCentralRoles from '../../lib/DoomCentralRoles'
import NewsPostRoles from '../../lib/NewsPostRoles'
import { ErrorContext } from '../../state'
import HelmetPages from '../helmet/HelmetPages'
import Sidebars from '../sidebars/Sidebars'
import NewsAdmin from './NewsAdmin'
import NewsList from './NewsList'
import NewsPagination from './NewsPagination'

export enum NewsPageMode {
    AllNews = 0,
    MyNews = 1,
}

export default () => {
    const error = useContext(ErrorContext)
    const auth = useAuth()

    const [loading, setLoading] = useState<boolean>(false)
    const [isAdmin, setIsAdmin] = useState<boolean>(false)
    const [pageNumber, setPageNumber] = useState<number>(1)
    const [pageSize, setPageSize] = useState<number>(20)
    const [mode, setMode] = useState<NewsPageMode>(NewsPageMode.AllNews)

    const [newsListing, setNewsListingData] = useState<INewsListingPageVm>()

    useEffect(() => {
        if (
            auth.user &&
            (auth.user?.profile.role as string[]) &&
            containsDuplicate([NewsPostRoles.GlobalNewsAdministrator, NewsPostRoles.NewsAuthor, DoomCentralRoles.GlobalAdministrator], auth.user?.profile.role as string[])
        ) {
            setIsAdmin(true)
        } else {
            setIsAdmin(false)
        }
    }, [auth])

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true)
            try {
                let client = new NewsClient()
                const response = await client.getNewsListingPage(pageNumber, pageSize).then((response) => response.toJSON() as Promise<INewsListingPageVm>)
                setNewsListingData(response)
            } catch (e) {
                error.setError(e.response as IErrorResponse)
            }
            setLoading(false)
        }

        fetchData()
    }, [pageNumber, pageSize, mode])

    return (
        <>
            <HelmetPages title='Doom Central - News Listing' />
            <Card className='mb-3'>
                <CardBody>
                    <div className='d-flex flex-row justify-content-between mb-3'>
                        <div className='flex-grow'>
                            <CardTitle tag='h5'>News</CardTitle>
                        </div>
                        <div className='d-flex flex-row justify-content-end align-items-center'>
                            <NewsPagination pageCount={newsListing?.newsListing?.totalPages ?? 0} setPageChange={setPageNumber} />
                        </div>
                    </div>
                    {isAdmin && <NewsAdmin mode={mode} setMode={setMode} />}
                    <NewsList newsItems={newsListing?.newsListing?.newsList} auth={auth} />
                </CardBody>
            </Card>
        </>
    )
}
