import RelativeTime from '@yaireo/relative-time'
import * as React from 'react'
import { useContext, useEffect, useRef, useState } from 'react'
import { useAuth } from 'react-oidc-context'
import { NavLink, useLocation } from 'react-router-dom'
import { HashLink } from 'react-router-hash-link'
import { Card, UncontrolledTooltip } from 'reactstrap'

import { PostItemDto } from '../../lib/DoomCentral'
import MarkdownPost from '../markdown-post/MarkdownPost'
import EditPostStation from './EditPostStation'
import PostFileDisplayer from './PostFileDisplayer'
import ForumModeratorTools from './PostModeratorTools'
import QuoteBox from './QuoteBox'

interface ThreadPostProps {
    postItem: PostItemDto
    editMode: boolean
    isAdmin: boolean
    authorized: boolean
    postToEdit: number
    pageNumber: number
    postToQuote: number
    editedPost: string
    editReason: string
    setPostToQuote: React.Dispatch<React.SetStateAction<number>>
    setModPost: React.Dispatch<React.SetStateAction<number>>
    setQuotedPostId: React.Dispatch<React.SetStateAction<number>>
    editPost(postId: number, quotedPostId: number, postContent: string): void
    clearEditMode: VoidFunction
}

export default (props: ThreadPostProps) => {
    const auth = useAuth()
    const relativeTime = new RelativeTime()

    return (
        <React.Fragment>
            <div id={`postid-${props.postItem.id}`}>
                <Card className='p-2 mb-2'>
                    {(!props.editMode || (props.editMode && props.postToEdit != props.postItem.id)) && (
                        <>
                            <div className='post-header'>
                                {props.postItem.unread && <span className='forum-unread-posts'>� </span>}
                                <div className='post-author'>
                                    <span>{props.postItem.postAuthorDisplayName}</span>
                                </div>
                                {props.postItem.postAuthorBadgeUrl.length > 0 && (
                                    <div className='post-author-badge'>
                                        <span id='userBadge'>
                                            <img src={props.postItem.postAuthorBadgeUrl} />
                                        </span>
                                        <UncontrolledTooltip flip target='userBadge'>
                                            {props.postItem.postAuthorBadgeName}
                                        </UncontrolledTooltip>
                                    </div>
                                )}
                                <div className='post-num'>
                                    <HashLink to={`?page=${props.pageNumber}#postid-${props.postItem.id}`}>#{props.postItem.postNumber}</HashLink>
                                </div>
                            </div>
                            {props.postItem.quotedPostId > 0 && <QuoteBox quotedPostId={props.postItem.quotedPostId} setQuotedPostId={null} editMode={false} />}
                            {props.isAdmin && (
                                <ForumModeratorTools postId={props.postItem.id} setModPost={props.setModPost} locked={props.postItem.locked} approved={props.postItem.approved} />
                            )}
                            <figure>
                                <MarkdownPost content={props.postItem.postContent} />
                                <figcaption className='blockquote-footer'>
                                    Posted{' '}
                                    <span>
                                        <span id={'dateCreated' + props.postItem.id}>{relativeTime.from(new Date(props.postItem.created))}</span>
                                        <UncontrolledTooltip placement='bottom' target={'dateCreated' + props.postItem.id}>
                                            {new Intl.DateTimeFormat('default', { dateStyle: 'full', timeStyle: 'long' }).format(new Date(props.postItem.created))}
                                        </UncontrolledTooltip>
                                    </span>{' '}
                                    by{' '}
                                    {props.postItem.postAuthorUserName && (
                                        <NavLink to={`/user/${props.postItem.postAuthorUserName}`}>{props.postItem.postAuthorDisplayName}</NavLink>
                                    )}
                                    {!props.postItem.postAuthorUserName && props.postItem.postAuthorDisplayName}
                                </figcaption>
                            </figure>
                            <div className='post-footer'>
                                <div className='post-actions'>
                                    {/* <div className='post-num'>
                                                <HashLink to={`#${s.id}`}>
                                                    <small>hash: #{s.postNumber}</small>
                                                </HashLink>
                                            </div> */}
                                    {auth.user && auth.isAuthenticated && props.authorized && (
                                        <small>
                                            <a onClick={(e) => props.setQuotedPostId(props.postItem.id)} className='post-action'>
                                                quote
                                            </a>
                                            <i className='bi bi-dot post-action-div'></i>
                                        </small>
                                    )}

                                    <p className='text-end'>
                                        <HashLink to={`?page=${props.pageNumber}#postid-${props.postItem.id}`} className='post-action text-right'>
                                            <small>Link to this comment</small>
                                        </HashLink>
                                    </p>

                                    {auth.user && auth.isAuthenticated && (auth.user.profile.sub === props.postItem.postAuthorId || props.isAdmin) && (
                                        <small>
                                            <i className='bi bi-dot post-action-div'></i>
                                            <a onClick={(e) => props.editPost(props.postItem.id, props.postItem.quotedPostId, props.postItem.postContent)} className='post-action'>
                                                edit
                                            </a>
                                        </small>
                                    )}
                                </div>
                            </div>
                        </>
                    )}
                    {auth.user && auth.isAuthenticated && props.editMode && props.postToEdit === props.postItem.id && (
                        <EditPostStation
                            clearEditMode={props.clearEditMode}
                            editedPost={props.editedPost}
                            editReason={props.editReason}
                            id={props.postItem.id}
                            postToQuote={props.postToQuote}
                            setPostToQuote={props.setPostToQuote}
                        />
                    )}
                    <PostFileDisplayer postFiles={props.postItem.postFiles} postId={props.postItem.id} />
                </Card>
            </div>
        </React.Fragment>
    )
}
