import { markPostAsRead } from "@/api/markPostAsRead";
import { createContext, useEffect, useState } from "react";

export const ReadPostsContext = createContext({ postsToRead: [], setPostsToRead: (ids: number[]) => {}})

const UPDATE_READ_STATUS_DEBOUNCE = 300

export const ReadPostsContextProvider = ({ children, threadId }) => {
    const [postsToRead, setPostsToRead] = useState<number[]>([])

    useEffect(() => {

        const timerId = setTimeout(async () => {

            if (postsToRead.length > 0) {
                // console.log(`Setting highest read ${Math.max(...postsToRead)} on thread ${threadId}`)
                await markPostAsRead({ highestReadPostId: Math.max(...postsToRead), threadId })
            }

        }, UPDATE_READ_STATUS_DEBOUNCE)

        return () => clearTimeout(timerId)

    }, [postsToRead])

    return <ReadPostsContext.Provider 
        value={{ 
            postsToRead, 
            setPostsToRead 
        }}>{children}</ReadPostsContext.Provider>
}