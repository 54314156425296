import { ImageIcon } from 'lucide-react'
import { useEffect, useRef, useState } from 'react'
import { Tooltip } from 'reactstrap'

export type ImagePreviewPopoverProps = {
    file: File
    label: string
}

// use ref instead of id for tooltip trigger
// see: https://github.com/reactstrap/reactstrap/issues/773
export const ImagePreviewTooltip = ({ file, label, ...rest }: ImagePreviewPopoverProps) => {

    const previewRef = useRef<HTMLImageElement>()
    const linkRef = useRef()

    const [isOpen, setIsOpen] = useState(false)
    const [isReady, setIsReady] = useState(false)

    if (file.type.match('image.*')) {
        const reader = new FileReader()
        reader.onload = (e) => {
            if (!previewRef.current) return
            previewRef.current.src = e.target.result as string
            previewRef.current.style.display = 'block'
        }
        reader.readAsDataURL(file)
    } else {
        console.warn('Provided file is not an image.')
        if (previewRef.current) previewRef.current.style.display = 'none'
    }

    const toggle = () => setIsOpen(!isOpen)


    useEffect(() => {
        if (linkRef.current) {
            setIsReady(true)
        }

    }, [linkRef.current])

    return (
        <div {...rest}>
            <a type='button' ref={linkRef} rel="noopener noreferrer">
                <ImageIcon className='me-1' style={{ width: '1rem' }} />
                {label}
            </a>
            {isReady && <Tooltip isOpen={isOpen} target={linkRef.current} toggle={toggle} style={{background: 'transparent'}}>
                <img ref={previewRef} style={{ display: 'none', minWidth: '420px', maxWidth: '100%', height: 'auto' }} />
            </Tooltip>}
        </div>
    )
}
