import { PostFileUploadItem, PostFilesClient, PostItemDto, ThreadDetailDto, ThreadPostsClient } from '@/lib/DoomCentral'
import { ReactNode, createContext, useEffect, useState } from 'react'
import { FileRejection } from 'react-dropzone'
import { useHistory } from 'react-router'
import { toast } from 'sonner'

type PostStation2ContextType = {
    quotedPost: PostItemDto | null
    postContent: string
    meta: ThreadDetailDto
    uploadedFiles: File[]
    fileRejects: FileRejection[]
    descriptions: PostFileUploadItem[]
    actions: {
        setPostContent: (content: string) => void
        removeFile: (fileName: string) => void
        updateDescription: (filename: string, description: string) => void
        setUploadedFiles: (files: File[]) => void
        setFileRejects: (rejects: FileRejection[]) => void
        setDescriptions: (descriptions: PostFileUploadItem[]) => void
        submitPost: {
            isLoading: boolean
            error: string | undefined
            exec: () => Promise<void>
        }
    }
}

export const PostStation2Context = createContext<PostStation2ContextType>({
    quotedPost: null,
    postContent: '',
    meta: null,
    uploadedFiles: [],
    fileRejects: [],
    descriptions: [],
    actions: {
        setPostContent: () => {},
        removeFile: () => {},
        updateDescription: () => {},
        setUploadedFiles: () => {},
        setFileRejects: () => {},
        setDescriptions: () => {},
        submitPost: {
            isLoading: false,
            error: undefined,
            exec: () => Promise.resolve(),
        },
    },
})

export const PostStation2Provider = ({ children, meta, quotedPost }: { children: ReactNode; meta: ThreadDetailDto; quotedPost?: PostItemDto }) => {
    const [postContent, setPostContent] = useState<string>('')

    const [uploadedFiles, setUploadedFiles] = useState<File[]>([])
    const [fileRejects, setFileRejects] = useState<FileRejection[]>([])
    const [descriptions, setDescriptions] = useState<PostFileUploadItem[]>([])

    const [isSubmitting, setIsSubmitting] = useState(false)
    const [submitError, setSubmitError] = useState<string | undefined>()

    const history = useHistory()

    const removeFile = (fileName: string) => {
        const fileExists = uploadedFiles.some((s) => s.name === fileName)

        if (!fileExists) {
            return
        }

        const newFiles = uploadedFiles.filter((f) => f.name !== fileName)

        setUploadedFiles(newFiles)
    }

    const updateDescription = (fileName: string, description: string): void => {
        const updatedDescriptions = descriptions.map((obj) => (obj.fileName === fileName ? new PostFileUploadItem({ fileName, description }) : obj))

        const fileExists = descriptions.some((s) => s.fileName === fileName)

        if (!fileExists) {
            updatedDescriptions.push(new PostFileUploadItem({ fileName, description }))
        }

        setDescriptions(updatedDescriptions)
    }

    const submitPost = async () => {
        try {
            setIsSubmitting(true)
            const files = uploadedFiles.map((file) => ({
                fileName: file.name,
                data: file,
            }))
            await new ThreadPostsClient().create(meta.id, postContent, quotedPost?.id || null, files, descriptions)
            toast.success('Post created')
        } catch (e) {
            console.error(e)
            setSubmitError(e.message)
            toast.error(e.message)
        } finally {
            setIsSubmitting(false)
            history.go(0)
        }
    }

    return (
        <PostStation2Context.Provider
            value={{
                quotedPost,
                postContent,
                meta,
                uploadedFiles,
                fileRejects,
                descriptions,
                actions: {
                    setPostContent,
                    removeFile,
                    updateDescription,
                    setDescriptions,
                    setFileRejects,
                    setUploadedFiles,
                    submitPost: {
                        isLoading: isSubmitting,
                        error: submitError,
                        exec: submitPost,
                    },
                },
            }}
        >
            {children}
        </PostStation2Context.Provider>
    )
}
