import * as React from 'react'
import { useState } from 'react'
import { Button } from 'reactstrap'

import { IErrorResponse } from '../../ErrorResponse'
import { FileParameter, PostFileUploadItem, ThreadPostsClient } from '../../lib/DoomCentral'
import { ErrorContext } from '../../state'

interface SubmitPostButtonProps {
    threadId: number
    quotedPostId: number
    post: string
    setPost: React.Dispatch<React.SetStateAction<string>>
    setNewPostNum: React.Dispatch<React.SetStateAction<number>>
    setQuotedPostId: React.Dispatch<React.SetStateAction<number>>
    setMode: React.Dispatch<React.SetStateAction<string>>
    uploadedFiles: File[]
    setuploadedFiles: React.Dispatch<React.SetStateAction<File[]>>
    descriptions: PostFileUploadItem[]
}

export default (props: SubmitPostButtonProps) => {
    const error = React.useContext(ErrorContext)

    const [loading, setLoading] = useState<boolean>(false)

    const submitPost = async () => {
        setLoading(true)
        try {
            let client = new ThreadPostsClient()
            var quoteId = null
            var files: FileParameter[] = []

            if (props.quotedPostId > 0) {
                quoteId = props.quotedPostId
            }

            props.uploadedFiles.map((s, idx) => {
                var f: FileParameter = {
                    fileName: s.name,
                    data: s,
                }
                files = [...files, f]
            })

            const response = await client.create(props.threadId, props.post, quoteId, files, props.descriptions).then((response) => response)
            props.setPost('')
            props.setQuotedPostId(0)
            props.setNewPostNum(response)
            props.setuploadedFiles([])
            props.setMode(null)
        } catch (e) {
            error.setError(e.response as IErrorResponse)
        }
        setLoading(false)
    }

    return (
        <React.Fragment>
            <div className='d-flex'>
                <div className='ms-auto mt-2'>
                    <Button disabled={!props.post || props.threadId <= 0} onClick={submitPost}>
                        Submit Post
                    </Button>
                </div>
            </div>
        </React.Fragment>
    )
}
