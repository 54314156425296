import * as React from 'react'
import { useState } from 'react'
import { Input } from 'reactstrap'

import { IForumDto, IForumGroupsDto } from '../../../../lib/DoomCentral'

interface ForumGroupSelectProps {
    setGroupSelect: React.Dispatch<React.SetStateAction<IForumGroupsDto>>
    selectedForum: IForumDto
    forumGroups: IForumGroupsDto[]
    loading: boolean
}

export default (props: ForumGroupSelectProps) => {
    const [selectedGroupId, setSelectedGroupId] = useState<number>(0)

    React.useEffect(() => {
        if (props.selectedForum) {
            selectGroup(props.selectedForum.forumGroupId)
        }
    }, [props.selectedForum])

    React.useEffect(() => {
        if (props.forumGroups && props.forumGroups.length > 0) {
            selectGroup(props.forumGroups[0].id)
        }
    }, [props.forumGroups])

    const selectGroup = (groupId: number) => {
        if (props.forumGroups && props.forumGroups.length > 0) {
            setSelectedGroupId(groupId)
            props.setGroupSelect(props.forumGroups.find((f) => f.id === groupId))
        }
    }

    return (
        <React.Fragment>
            <label>
                ForumGroup:
                <Input type='select' value={selectedGroupId} onChange={(e) => selectGroup(parseInt(e.target.value, 10))}>
                    {props.forumGroups &&
                        props.forumGroups.map((s, idx) => (
                            <option value={s.id} key={s.id}>
                                {s.name}
                            </option>
                        ))}
                </Input>
            </label>
        </React.Fragment>
    )
}
