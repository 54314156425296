import * as React from 'react'
import { useContext, useEffect, useState } from 'react'
import { Alert, Fade, Row } from 'reactstrap'

import { IErrorResponse } from '../../../ErrorResponse'
import { ForumsClient, IAllForumGroupDto, IAllForumListingVm } from '../../../lib/DoomCentral'
import { ErrorContext } from '../../../state'
import HelmetPages from '../../helmet/HelmetPages'
import AdminPanel from '../AdminPanel'
import CreateNewForumPermissionGroup from './CreateNewForumPermissionGroup'
import { ForumPermission } from './ForumPermission'
import { ForumTreeObject, IForumTreeObject } from './ForumPermissionTreeObject'

export default () => {
    const error = useContext(ErrorContext)

    const [modGroup, setModGroup] = useState<number>(0)
    const [forums, setForums] = useState<IForumTreeObject[]>([])
    const [loading, setLoading] = useState<boolean>(false)
    const [hidden, setHidden] = useState<boolean>(true)

    useEffect(() => {
        if (modGroup > 0) {
            setHidden(false)
            const timer = setTimeout(() => {
                setHidden(true)
            }, 5000)
            return () => {
                clearTimeout(timer)
            }
        }
    }, [modGroup])

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true)
            try {
                let client = new ForumsClient()
                const response = await client.getAllForumsAndGroups().then((response) => response.toJSON() as Promise<IAllForumListingVm>)
                setForums(createForumTreeObjects(response.forumGroups))
            } catch (e) {
                error.setError(e.response as IErrorResponse)
            }
            setLoading(false)
        }

        fetchData()
    }, [])

    const createForumTreeObjects = (groups: IAllForumGroupDto[]) => {
        var treeObjects: IForumTreeObject[] = []
        var index = 1

        groups.map((s, idx) => {
            let obj = new ForumTreeObject()

            obj.id = index
            obj.entityId = s.id
            obj.description = s.groupDescription
            obj.hidden = false
            obj.locked = false
            obj.moderated = false
            obj.name = s.groupName
            obj.permission = ForumPermission.Inherit
            obj.children = []

            index++

            s.forums.map((_, _idx) => {
                let child = new ForumTreeObject()

                child.id = index
                child.entityId = _.id
                child.description = _.description
                child.hidden = _.hidden
                child.locked = _.locked
                child.moderated = _.moderated
                child.name = _.name
                child.permission = ForumPermission.Inherit
                child.children = []

                index++

                obj.children = [...obj.children, child]
            })

            treeObjects = [...treeObjects, obj]
        })

        return treeObjects
    }

    return (
        <React.Fragment>
            <HelmetPages title={'Doom Central - Manage Forum Permission Groups'} />
            <AdminPanel>
                <h1>Manage Forum Permission Groups</h1>
                <CreateNewForumPermissionGroup forumTree={forums} setForumTree={setForums} setNewGroup={setModGroup} />
                <Row className='my-2'>
                    <Fade in={!hidden}>
                        <Alert color='success' hidden={hidden}>
                            Success!
                            <br />
                            {`The new mod group id ${modGroup} has been created!`}
                            <br />
                        </Alert>
                    </Fade>
                </Row>
            </AdminPanel>
        </React.Fragment>
    )
}
