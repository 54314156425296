import MDEditor, { ContextStore, RefMDEditor } from '@uiw/react-md-editor'
import { ForwardedRef, LegacyRef, forwardRef } from 'react'
import rehypeRaw from 'rehype-raw'
import rehypeSanitize, { defaultSchema } from 'rehype-sanitize'
import remarkBreaks from 'remark-breaks'
import remarkDirective from 'remark-directive'
import remarkGfm from 'remark-gfm'
import remarkImages from 'remark-images'
import remarkUnwrapImages from 'remark-unwrap-images'
import remarkColorDirectivePlugin from '../../remarkColorDirectivePlugin'

export type MarkdownEditorProps = {
    value?: string
    onChange: (value?: string, event?: React.ChangeEvent<HTMLTextAreaElement>, state?: ContextStore) => void
}

export const MarkdownEditor = forwardRef<ForwardedRef<LegacyRef<RefMDEditor>>, MarkdownEditorProps>(({ value, onChange }, ref) => {
    return (
        <MDEditor
            ref={ref}
            value={value}
            onChange={onChange}
            previewOptions={{
                remarkPlugins: [
                    [remarkGfm, { singleTilde: false }],
                    remarkBreaks,
                    remarkUnwrapImages,
                    remarkImages,
                    remarkDirective,
                    remarkColorDirectivePlugin
                ],
                rehypePlugins: [
                    [
                        rehypeSanitize,
                    ],
                ],
            }}
        />
    )
})
