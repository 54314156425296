import { ThreadsClient } from '@/lib/DoomCentral'

export type getThreadMetaArgs = {
    id: number
}

export const getThreadMeta = async ({ id }: getThreadMetaArgs) => {
    const client = new ThreadsClient()
    const { threadDetail } = await client.getThreadPageById(+id)
    return threadDetail
}
