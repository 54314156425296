import * as React from 'react'
import { useContext, useEffect, useState } from 'react'
import { Button, Col, FormGroup, FormText, Input, Label } from 'reactstrap'

import { IErrorResponse } from '../../../ErrorResponse'
import { countries } from '../../../countries'
import { Country, EmotesClient, FileParameter, ICountry, StreamPlatform, StreamerClient } from '../../../lib/DoomCentral'
import { ErrorContext } from '../../../state'

interface CreateNewStreamerProps {
    setModStreamer: React.Dispatch<React.SetStateAction<number>>
    countries: ICountry[]
}

export default (props: CreateNewStreamerProps) => {
    const error = useContext(ErrorContext)

    const [streamerId, setStreamerId] = useState<string>('')
    const [streamerName, setStreamerName] = useState<string>('')
    const [countryCode, setCountryCode] = useState<string>('')
    const [platform, setPlatform] = useState<StreamPlatform>(StreamPlatform.Twitch)
    const [streamerProfileFile, setStreamerProfileFile] = useState<FileParameter>()
    const [loading, setLoading] = useState<boolean>(false)

    const submitEmote = async () => {
        setLoading(true)
        try {
            let client = new StreamerClient()
            const response = (await client.create(streamerId, streamerName, countryCode, platform, streamerProfileFile).then((response) => response)) as number
            props.setModStreamer(response)
            setStreamerId('')
            setStreamerName('')
            setCountryCode(props.countries[0]?.countryCode ?? '')
            setPlatform(StreamPlatform.Twitch)
            setStreamerProfileFile(null)
        } catch (e) {
            error.setError(e.response as IErrorResponse)
        }
        setLoading(false)
    }

    const handleSetFile = async (evt: React.ChangeEvent<HTMLInputElement>) => {
        const upload: FileParameter = {
            data: evt.target.files[0],
            fileName: evt.target.files[0].name,
        }
        setStreamerProfileFile(upload)
    }

    useEffect(() => {
        if (props.countries && props.countries.length > 0) {
            setCountryCode(props.countries[0].countryCode)
        }
    }, [props.countries])

    return (
        <React.Fragment>
            <h2>Create Streamer</h2>
            <FormGroup row>
                <Label for='streamerName' sm={2}>
                    Streamer Name
                </Label>
                <Col sm={10}>
                    <Input id='streamerName' name='streamerName' value={streamerName} onChange={(e) => setStreamerName(e.target.value)} />
                </Col>
            </FormGroup>
            <FormGroup row>
                <Label for='streamerId' sm={2}>
                    Streamer Id
                </Label>
                <Col sm={10}>
                    <Input id='streamerId' name='streamerId' value={streamerId} onChange={(e) => setStreamerId(e.target.value)} />
                </Col>
            </FormGroup>
            <FormGroup row>
                <Label for='streamPlatform' sm={2}>
                    Stream Platform
                </Label>
                <Col sm={10}>
                    <Input type='select' name='region' id='region' value={StreamPlatform[platform]} onChange={(e) => setPlatform(StreamPlatform[e.target.value])}>
                        {(Object.keys(StreamPlatform).filter((v) => !isNaN(Number(v))) as (keyof typeof StreamPlatform)[]).map((key, index) => (
                            <option key={key} value={StreamPlatform[key]}>
                                {StreamPlatform[key]}
                            </option>
                        ))}
                    </Input>
                </Col>
            </FormGroup>
            <FormGroup row>
                <Label for='countries' sm={2}>
                    Streamer Nationality
                </Label>
                <Col sm={10}>
                    <Input type='select' name='countries' id='countries' value={countryCode} onChange={(e) => setCountryCode(e.target.value)}>
                        {props.countries &&
                            props.countries.length > 0 &&
                            props.countries.map((key, index) => (
                                <option key={key.countryCode} value={key.countryCode}>
                                    {key.countryCode + ' | ' + new Intl.DisplayNames(['en'], { type: 'region' }).of(key.countryCode)}
                                </option>
                            ))}
                    </Input>
                </Col>
            </FormGroup>
            <FormGroup row>
                <Col>
                    {countryCode && (
                        <img width='200px' height='150px' className='img-thumbnail' src={'https://purecatamphetamine.github.io/country-flag-icons/3x2/' + countryCode + '.svg'} />
                    )}
                </Col>
            </FormGroup>
            <FormGroup row>
                <Label for='streamerPic' sm={2}>
                    Streamer Profile Pic
                </Label>
                <Col sm={10}>
                    <input type='file' id='streamerPic' name='streamerPic' accept='image/png, image/jpeg' onChange={handleSetFile} />
                </Col>
            </FormGroup>
            <FormGroup row>
                <Col>
                    <Button disabled={!streamerId || !streamerName || !countryCode || loading} onClick={submitEmote}>
                        Submit Streamer
                    </Button>
                </Col>
            </FormGroup>
        </React.Fragment>
    )
}
